import React, { Component } from 'react';

class ConfirmationPageServices extends Component {
	render() {
		let { servicesOffered } = this.props;
		let data = servicesOffered.reduce((r, e) => {
			// get first letter of name of current element
			if (e && 
				e.serviceName != null) {
				let title = e.serviceName[0];
			// if there is no property in accumulator with this letter create it
			if (!r[title]) r[title] = { title, data: [e.serviceName] }
			// if there is push current element to children array for that letter
			else r[title].data.push(e.serviceName);
			// return accumulator
			return r;
			}
		  }, {})
		  // since data at this point is an object, to get array of values
		  // we use Object.values method
		  let result = Object.values(data).sort((a, b) => (a.title > b.title) ? 1 : -1)

		return (
				<div
					style={{
						borderBottomStyle: 'solid',
						borderBottomWidth: 0.5,
						borderColor: 'gray',
						width: '100%',
						padding: 30,
						color: 'rgb(86, 86, 86)',
						flexDirection: 'column'
					}}
				>
					<div
						style={{
							flex: 1,
							width: '100%',
							justifyContent: 'center',
							marginBottom: 15
						}}
					>
						<div
							className="neighborHeaderTextBig"
							style={{
								textAlign: 'left',
								alignSelf: 'flex-start',
								width: '100%',
								fontFamily: 'Montserrat SemiBold'
							}}
						>
							Services Offered
						</div>
					</div>
					<div
						style={{
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center',
							fontFamily: 'Montserrat regular'
						}}
					>
						<div
							style={{
								flex: 1,
								paddingLeft: 15,
								paddingRight: 15,
								flexDirection: 'column',
								justifyContent: 'center'
							}}
						>
							{result.map((so, k) => {
								return (
									<div style={{flex:1, flexDirection: "column"}}>
									<div
										className="neighborHeaderTextSmall"
										key={k}
										style={{
											backgroundColor: "#dcdcdc",
											padding:"2px 2px 2px 12px",
											marginVertical: 5,
											fontFamily: "Montserrat bold"
										}}
									>
										{so.title}

									</div>
									{so.data.map((value, i) => {
										return (<div key={i} style={{padding: "5px 12px 5px 12px"}} className="neighborHeaderTextSmall">
											{value}
										</div>)
									})}
									</div>
								);
							})}
						</div>
					</div>
				</div>
		);
	}
}

export default ConfirmationPageServices;
