import React, { Component } from "react";
import { connect } from "react-redux";
import { EventStatuses } from "../system";
import LoggedInContainerHeader from "../Components/LoggedInContainer/LoggedInContainerHeader";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Col, Row,Button } from "reactstrap";
import SquareButton from "../Components/SquareButton";
import { Redirect } from "react-router-dom";
import InputMask from 'react-input-mask';
import { Input, CustomInput, Container } from 'reactstrap';
import {
  setCustomerInfo,
  getNearbySingularFranchise,
  selectCompanyForAppointment,
  acknowledgeEvent,
  clearNearbySingularFranchise,
  statesBasedOnZipcode
} from "../actions/index";
import Alert from "../Components/Alert";
import Loader from "react-loader-spinner";

class CustomerInfo extends Component {
  constructor(props) {
    super(props);
    this.redirectEndpoint = null;
    this.state = {
      redirect: null,
      zipCode:null,
    };
  }

  componentDidMount() {
    let formattedPhone;
    if (this.props.customerInfoTemp && this.props.customerInfoTemp.phone) {
      let match = this.props.customerInfoTemp.phone.match(/^(\d{3})(\d{3})(\d{4})$/);
      formattedPhone = '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    this.setState({
      phoneNumber: this.props.customerInfoTemp ? formattedPhone : null
    });
  }

  componentDidUpdate() {
    if (this.props.eventStatuses["setCustomerInfo"] === EventStatuses.COMPLETED &&
      this.props.customerInfoTemp &&
      this.redirectEndpoint &&
      (!this.props.eventStatuses["selectCompanyForAppointment"])
    ) {
      this.props.acknowledgeEvent("setCustomerInfo");
      this.props.history.push(this.redirectEndpoint);
    }
    if (this.props.eventStatuses["getNearbySingularFranchise"] === EventStatuses.COMPLETED && this.props.singularNearbyFranchises) {
      this.props.acknowledgeEvent("getNearbySingularFranchise");
      this.determineAppropriateAddressAction();
    }
    if (this.props.eventStatuses["getNearbySingularFranchise"] === EventStatuses.FAILED) {
      this.props.acknowledgeEvent("getNearbySingularFranchise");
      this.addressVerificationErrorModal.showWithMessage("ERROR", "Unfortunately, Not able to find this address");
    }
    if (this.props.eventStatuses["selectCompanyForAppointment"] === EventStatuses.COMPLETED) {
      this.props.acknowledgeEvent("selectCompanyForAppointment");
    }
  }

  getRoundRobinFranchiseWhenOriginalFranchiseIsInvalid = () => {
    let { customerInfoObj } = this.state;
    let { singularNearbyFranchises } = this.props;
    let singularNearbyFranchisesOrderedByNPSScore = singularNearbyFranchises.sort((a, b) => (a.npsScore > b.npsScore) ? 1 : -1);
    singularNearbyFranchisesOrderedByNPSScore = singularNearbyFranchisesOrderedByNPSScore.reverse();

    this.props.selectCompanyForAppointment(singularNearbyFranchisesOrderedByNPSScore[0]);
    this.commitCustomerInfo(customerInfoObj);
  }

  determineAppropriateAddressAction = () => {
    let { customerInfoObj } = this.state;
    let franchiseIds = this.props.singularNearbyFranchises.map(snf => snf.franchiseId);
    if (!this.props.singularNearbyFranchises.length) {
      this.addressVerificationErrorModal.showWithMessage("ERROR", "Unfortunately, the selected brand does not cover this location.");
    } else if (franchiseIds.includes(this.props.selectedCompany.franchiseId)) {
      let newSelectedCompany = this.props.singularNearbyFranchises.find(snf => snf.franchiseId == this.props.selectedCompany.franchiseId);
      this.props.selectCompanyForAppointment(newSelectedCompany);
      this.commitCustomerInfo(customerInfoObj);
    } else {
      this.addressVerificationResultModal.showWithMessage("WARNING", "The originally selected location does not service this address, but we have automatically switched to a location that does.");
    }
  }


  saveCustomerInfo = (event, values) => {
    let {
      firstName,
      lastName,
      phone,
      email,
      contactPreference,
      address1,
      address2,
      city,
      state,
      zipCode
    } = values;

    var numberPattern = /\d+/g;

    let phoneNumberGroup = phone.match(numberPattern);

    let customerInfoObj = {
      FirstName: firstName,
      LastName: lastName,
      Phone: phoneNumberGroup.join(""),
      Email: email,
      contactMethod: contactPreference,
      Street: address1,
      Street2: address2,
      City: city,
      State: state,
      Zip: zipCode
    };

    if (this.props.locationMethod == "manual" && this.props.selectedAddress.zipCode == customerInfoObj.Zip && !this.props.selectedCompany.isPartial) {
      this.commitCustomerInfo(customerInfoObj);
    } else {
      let fullAddress = `${customerInfoObj.Street} ${(customerInfoObj.Street2 || "")} ${customerInfoObj.City}, ${customerInfoObj.State} ${customerInfoObj.Zip}`;
      let { conceptId } = this.props.selectedCompany;
      let { conceptCode } = this.props.companiesHashMap[conceptId];
      this.setState({ customerInfoObj });
      this.props.getNearbySingularFranchise(fullAddress, conceptCode);
    }
  };

  commitCustomerInfo = customerInfoObj => {
    this.redirectEndpoint = "/servicedescription";
    this.props.setCustomerInfo(customerInfoObj);
  }

  render() {
    return (
      <div style={{ width: "100%", flexDirection: "column" }}>
        {
          !this.props.selectedCompany &&
          <Redirect to="/landing" />
        }
        <Alert
          ref={r => { this.addressVerificationErrorModal = r; }}
        />
        <Alert
          ref={r => { this.addressVerificationResultModal = r; }}
          onConfirm={this.getRoundRobinFranchiseWhenOriginalFranchiseIsInvalid}
        />
        {this.props.customerInfoTemp && this.props.customerInfoTemp.masterCustomerId &&
          <LoggedInContainerHeader
          history={this.props.history}
        />}
        {
          this.props.eventStatuses["getNearbySingularFranchise"] === EventStatuses.PENDING &&
          <div style={{ position: "absolute", width: "100%", height: "100%", background: "transparent", alignItems: "center", justifyContent: "center", zIndex: 2 }}>
            <Loader
              type="Oval"
              color="#a5a9af"
              height="50"
              width="50"
            />
          </div>
        }
        <div style={{ width: "100%", display: "block" }}>
          <div style={{ width: "100%", paddingRight: 20, paddingLeft: 20, alignItems: "center", justifyContent: "center" }}>
            <AvForm onValidSubmit={this.saveCustomerInfo} style={{ width: "100%", maxWidth: 800, marginBottom: 50 }}>
              <Row>
                <div style={{ width: "100%", marginTop: this.props.customerInfoTemp && this.props.customerInfoTemp.masterCustomerId ? 68 :12, marginBottom: '1rem', fontSize: 25, paddingLeft: 15,color:'rgb(38, 125, 191)',fontWeight:600 }}>
                  Contact Information
                </div>
              </Row>
              <Row>
                <Col xs="12" sm="6">
                  <AvGroup style={{ width: "100%" }}>
                    <AvField
                      name="firstName"
                      placeholder="First Name"
                      required
                      value={this.props.customerInfoTemp ? this.props.customerInfoTemp.firstName : null}
                    />
                  </AvGroup>
                </Col>
                <Col xs="12" sm="6">
                  <AvGroup style={{ width: "100%" }}>
                    <AvField
                      name="lastName"
                      placeholder="Last Name"
                      required
                      value={this.props.customerInfoTemp ? this.props.customerInfoTemp.lastName : null}
                    />
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="6">
                  <AvGroup style={{ width: "100%" }}>
               {/*      <AvField
                      label="Phone Number"
                      type="tel"
                      mask="(999) 999-9999"
                      maskChar="-"
                      name="phone"
                      tag={[Input, InputMask]}
                      required
                      helpMessage="Valid NANP phone number cannot start with a 1"
                    /> */}
                    <AvField
                     type="tel"
                     tag={[Input, InputMask]}
                      className="form-control"
                      name="phone"
                      placeholder="Phone Number"
                      minLength={14}
                      mask="(999) 999-9999"
                      required
                   /*    helpMessage="Valid NANP phone number cannot start with a 1" */
                      onChange={e => {
                        this.setState({
                          phoneNumber: e.target.value
                        });
                      }}
                      value={this.state.phoneNumber}
                    />
                  </AvGroup>
                </Col>
                <Col xs="12" sm="6">
                  <AvGroup style={{ width: "100%" }}>
                    <AvField
                      name="email"
                      placeholder="Email Address"
                      required
                      value={this.props.customerInfoTemp ? (this.props.customerInfoTemp.email || this.props.customerInfoTemp.contactDetailCleaned) : null}
                      validate={{ email: true }}
                    />
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="6">
                  <AvGroup style={{ width: "100%" }}>
                    <AvField
                      name="contactPreference"
                      placeholder="Preferred Method of Contact"
                      required
                      value="phone"
                      type="select"
                    >
                      <option key={0} value="">--</option>
                      <option key={1} value="email">Email Preferred</option>
                      <option key={2} value="phone">Phone Preferred</option>
                      <option key={3} value="text">Text Preferred (standard fees apply)</option>
                    </AvField>
                  </AvGroup>
                </Col>
                <Col xs="12" sm="6" />
              </Row>
              <Row>
                <div style={{ width: "100%", marginTop: -12, marginBottom: '1rem', fontSize: 25, paddingLeft: 15,color:'rgb(38, 125, 191)',fontWeight:600 }}>
                  Address Information
                </div>
              </Row>
              <Row>
                <Col xs="12" sm="6">
                  <AvGroup style={{ width: "100%" }}>
                    <AvField
                      name="address1"
                      placeholder="Address Line 1"
                      required
                      value={this.props.customerInfoTemp ? this.props.customerInfoTemp.street : null}
                    />
                  </AvGroup>
                </Col>
                <Col xs="12" sm="6">
                  <AvGroup style={{ width: "100%" }}>
                    <AvField
                      name="address2"
                      placeholder="Address Line 2"
                      value={this.props.customerInfoTemp ? this.props.customerInfoTemp.street2 : null}
                    />
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="6">
                  <AvGroup style={{ width: "100%" }}>
                    <AvField
                      name="city"
                      placeholder="City"
                      required
                      value={this.props.customerInfoTemp ? this.props.customerInfoTemp.city : null}
                    />
                  </AvGroup>
                </Col>
                <Col xs="12" sm="6">
                  <AvGroup style={{ width: "100%" }}>
                  <AvField
                      name="state"
                      placeholder="State"
                      required
                      value={this.props.customerInfoTemp ? this.props.customerInfoTemp.state : null}
                      type="select"
                    >
                     
                     <option key={"Alabama"} value="AL">Alabama</option>
                      <option key={"Alaska"} value="AK">Alaska</option>
                      <option key={"Arizona"} value="AZ">Arizona</option>
                      <option key={"Arkansas"} value="AR">Arkansas</option>
                      <option key={"California"} value="CA">California</option>
                      <option key={"Colorado"} value="CO">Colorado</option>
                      <option key={"Connecticut"} value="CT">Connecticut</option>
                      <option key={"Delaware"} value="DE">Delaware</option>
                      <option key={"District of Columbia"} value="DC">District of Columbia</option>
                      <option key={"Florida"} value="FL">Florida</option>
                      <option key={"Georgia"} value="GA">Georgia</option>
                      <option key={"Hawaii"} value="HI">Hawaii</option>
                      <option key={"Idaho"} value="ID">Idaho</option>
                      <option key={"Illinois"} value="IL">Illinois</option>
                      <option key={"Indiana"} value="IN">Indiana</option>
                      <option key={"Iowa"} value="IA">Iowa</option>
                      <option key={"Kansas"} value="KS">Kansas</option>
                      <option key={"Kentucky"} value="KY">Kentucky</option>
                      <option key={"Louisiana"} value="LA">Louisiana</option>
                      <option key={"Maine"} value="ME">Maine</option>
                      <option key={"Maryland"} value="MD">Maryland</option>
                      <option key={"Massachusetts"} value="MA">Massachusetts</option>
                      <option key={"Michigan"} value="MI">Michigan</option>
                      <option key={"Minnesota"} value="MN">Minnesota</option>
                      <option key={"Mississippi"} value="MS">Mississippi</option>
                      <option key={"Missouri"} value="MO">Missouri</option>
                      <option key={"Montana"} value="MT">Montana</option>
                      <option key={"Nebraska"} value="NE">Nebraska</option>
                      <option key={"Nevada"} value="NV">Nevada</option>
                      <option key={"New Hampshire"} value="NH">New Hampshire</option>
                      <option key={"New Jersey"} value="NJ">New Jersey</option>
                      <option key={"New Mexico"} value="NM">New Mexico</option>
                      <option key={"New York"} value="NY">New York</option>
                      <option key={"North Carolina"} value="NC">North Carolina</option>
                      <option key={"North Dakota"} value="ND">North Dakota</option>
                      <option key={"Ohio"} value="OH">Ohio</option>
                      <option key={"Oklahoma"} value="OK">Oklahoma</option>
                      <option key={"Oregon"} value="OR">Oregon</option>
                      <option key={"Pennsylvania"} value="PA">Pennsylvania</option>
                      <option key={"Puerto Rico"} value="PR">Puerto Rico</option>
                      <option key={"Rhode Island"} value="RI">Rhode Island</option>
                      <option key={"South Carolina"} value="SC">South Carolina</option>
                      <option key={"South Dakota"} value="SD">South Dakota</option>
                      <option key={"Tennessee"} value="TN">Tennessee</option>
                      <option key={"Texas"} value="TX">Texas</option>
                      <option key={"U.S. Virgin Islands"} value="VI">U.S. Virgin Islands</option>
                      <option key={"Utah"} value="UT">Utah</option>
                      <option key={"Vermont"} value="VT">Vermont</option>
                      <option key={"Virginia"} value="VA">Virginia</option>
                      <option key={"Washington"} value="WA">Washington</option>
                      <option key={"West Virginia"} value="WV">West Virginia</option>
                      <option key={"Wisconsin"} value="WI">Wisconsin</option>
                      <option key={"Wyoming"} value="WY">Wyoming</option>
                
                    </AvField>
                
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="6">
                  <AvGroup style={{ width: "100%" }}>
                    <AvField
                      name="zipCode"
                      placeholder="ZIP Code"
                      required
                      maxLength={5}
                      minLength={5}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={this.props.customerInfoTemp ? this.props.customerInfoTemp.zip : null}
                    />
                  </AvGroup>
                </Col>
                <Col xs="12" sm="6" />
              </Row>
              <Row style={{justifyContent:'center'}}>
                <SquareButton
                  className="boxShadow contactCreation"
                  text={this.props.customerInfoTemp ? "NEXT" : "CREATE CONTACT"}
                  textstyle={{ fontFamily: global.themeFontSemiBold, color: "white", fontSize: 16 }}
                />
              </Row>
            </AvForm>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    selectedCompany,
    customerInfoTemp,
    companiesHashMap,
    singularNearbyFranchises,
    locationMethod,
    selectedAddress,
    statesBasedOnLocation
  } = state.schedulingReducer;
  const { eventStatuses } = state.systemReducer;
  return {
    ...state,
    selectedCompany,
    customerInfoTemp,
    companiesHashMap,
    eventStatuses,
    singularNearbyFranchises,
    locationMethod,
    selectedAddress,
    statesBasedOnLocation
  };
}

export default connect(mapStateToProps, {
  setCustomerInfo,
  getNearbySingularFranchise,
  selectCompanyForAppointment,
  acknowledgeEvent,
  clearNearbySingularFranchise,
  statesBasedOnZipcode
})(CustomerInfo);