import React, {Component} from "react";
import {connect} from "react-redux";
import LoggedInContainerHeader from "../Components/LoggedInContainer/LoggedInContainerHeader";
import {
  getCustomersBasedOnLocation,
  setCustomerInfo
} from "../actions/index";
import CustomerListTab from "../Components/CustomerList/CustomerListTab";
import NearbyJobSearchTab from "../Components/CustomerList/NearbyJobSearchTab";
import MyJobSearchTab from "../Components/CustomerList/MyJobSearchTab";
import CustomerInfo from "./CustomerInfo";

class CustomerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabIndex: 0
    };
  }

  componentDidMount() {
  }

  onCustomerSelect = customer => {
    this.props.setCustomerInfo(customer);
    this.props.history.push("/servicedescription");
  }

  onCreateNewCustomer = ()=> {
    this.props.history.push("/customerinfo");
  }

  renderTopTab = () => {
    return (
      <div style={{flexDirection: "column", backgroundColor: "white", position: "relative", top: 20,left:40,paddingLeft:"20px",borderLeft:"5px solid",borderLeftColor:"#267dbf"}}>
        <div
          style={this.state.selectedTabIndex === 0 ? styles.selectedStyle : styles.unSelectedStyle}
          onClick={()=>{this.setState({selectedTabIndex: 0});}}
          className="selectedStyleAlignment"
        >
          <div
            style={{fontSize: 18,fontWeight:500}}
          >
            JOBS NEAR ME
          </div>
        </div>
        <div
          style={this.state.selectedTabIndex === 1 ? styles.selectedStyle : styles.unSelectedStyle}
          onClick={()=>{this.setState({selectedTabIndex: 1});}}
        >
          <div
            style={{fontSize: 18,fontWeight:500}}
          >
            JOBS
          </div>
        </div> 
        <div
          style={this.state.selectedTabIndex === 2 ? styles.selectedStyle : styles.unSelectedStyle}
          onClick={()=>{this.setState({selectedTabIndex: 2});}}
        >
          <div
            style={{fontSize: 18,fontWeight:500}}
          >
            ADD CUSTOMER
          </div>
        </div>
        <div
          style={this.state.selectedTabIndex === 3 ? styles.selectedStyle : styles.unSelectedStyle}
          onClick={()=>{this.setState({selectedTabIndex: 3});}}
        >
          <div
            style={{fontSize: 18,fontWeight:500}}
          >
            CUSTOMER SEARCH
          </div>
        </div>     
      </div>
    );
  }

  render() {
    return (
      <div style={{width: "100%", display: "block"}}>
        <LoggedInContainerHeader
          history={this.props.history}
        />
        <div style={{padding:'105px'}}>
        <div style={{width:'30%',flexDirection:'column',position:'fixed'}}>
        {
          this.renderTopTab()
        }
        </div>
        <div style={{ width: "70%",position:"absolute",right:35}}>
          {
            this.state.selectedTabIndex === 0 &&
            <NearbyJobSearchTab
              history={this.props.history}
            />
          }
          {
            this.state.selectedTabIndex === 1 &&
            <MyJobSearchTab
              history={this.props.history}
            />
          }
          {
            this.state.selectedTabIndex === 2 &&
            <CustomerInfo
              history={this.props.history}
            />
          }
          {
            this.state.selectedTabIndex === 3 &&            
            <CustomerListTab
              history={this.props.history}
            />
          }                  
        </div>  
        </div>      
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    customers,
    activePostalCode
  } = state.schedulingReducer;
  return {
    ...state,
    customers,
    activePostalCode
  };
}

export default connect(mapStateToProps, {
  getCustomersBasedOnLocation,
  setCustomerInfo
})(CustomerList);

const styles = {
  unSelectedStyle: {
    cursor: "pointer",
    width: "100%",
    alignItems: "start",
    justifyContent: "start",
    flexDirection: "column",
    color: "darkgrey",
    padding: "10px 0px 0px 0px",
    fontSize: 24
  }, selectedStyle: {
    cursor: "pointer",
    width: "100%",
    alignItems: "start",
    justifyContent: "start",
    flexDirection: "column",
    color: "rgb(38, 125, 191)",
    fontSize: 24,
    padding: "10px 0px 0px 0px"
  },
}