import React, {Component} from "react";

class SubmitPageContactInfo extends Component {
  render() {
    let {customerContactInfo} = this.props;
    return (
      <div
        style={{
          width: "100%",
          padding: 20,
          marginTop: 20,
          borderBottomWidth: 1,
          borderBottomColor: "darkgrey",
          borderBottomStyle:"solid",
          display: "block"
        }}
      >
        <div className={`neighborHeaderTextBig`} style={{width: "100%", fontWeight: "bold"}}>
          Contact Information
        </div>
        {/*Row*/}
        <div style={{width: "100%", flexDirection: "row", marginTop: 10}}>
          <div style={{flexDirection: "column", flex: 1, alignItems: "flex-start", color: "#565656"}}>
            <div className={`neighborHeaderTextLarge`} style={{ marginTop: 2, marginBottom: 2, fontWeight: "bold"}}>Name</div>
            <div className={`neighborHeaderTextLarge`} style={{ marginTop: 2, marginBottom: 2}}>
              {
                customerContactInfo &&
                (customerContactInfo.Name || `${customerContactInfo.FirstName} ${customerContactInfo.LastName}`)
              }
            </div>
          </div>
          <div style={{flexDirection: "column", flex: 1, alignItems: "flex-start", color: "#565656"}}>
            <div className={`neighborHeaderTextLarge`} style={{ marginTop: 2, marginBottom: 2, fontWeight: "bold"}}>Address</div>
            <div className={`neighborHeaderTextLarge`} style={{ marginTop: 2, marginBottom: 2}}>
              {
                customerContactInfo &&
                `${customerContactInfo.addressLine1 || customerContactInfo.Street} ${(customerContactInfo.addressLine2 || customerContactInfo.Street2 || "" )}`
              }
            </div>
            <div className={`neighborHeaderTextLarge`} style={{ marginTop: 2, marginBottom: 2}}>
              {
                customerContactInfo &&
                (`${customerContactInfo.City || customerContactInfo.city}, ${customerContactInfo.State ||customerContactInfo.state} ${customerContactInfo.Zip || customerContactInfo.zipCode}`)
              }
            </div>
          </div>
        </div>
        {/*Row*/}
        <div style={{width: "100%", flexDirection: "row", marginTop: 10}}>
          <div style={{flexDirection: "column", flex: 1, alignItems: "flex-start", color: "#565656"}}>
            <div className={`neighborHeaderTextLarge`} style={{ marginTop: 2, marginBottom: 2, fontWeight: "bold"}}>Phone</div>
            <div className={`neighborHeaderTextLarge`} style={{ marginTop: 2, marginBottom: 2}}>
              {
                customerContactInfo &&
                (customerContactInfo.phone || customerContactInfo.Phone)
              }
            </div>
          </div>
          <div style={{flexDirection: "column", flex: 1, alignItems: "flex-start", color: "#565656"}}>
            <div className={`neighborHeaderTextLarge`} style={{ marginTop: 2, marginBottom: 2, fontWeight: "bold"}}>Email</div>
            <div className={`neighborHeaderTextLarge`} style={{ marginTop: 2, marginBottom: 2}}>
              {
                customerContactInfo &&
                (customerContactInfo.email || customerContactInfo.Email)
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SubmitPageContactInfo;