import React, {Component} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class LocationScreenPreviousLocationRow extends Component {
  render() {
    let {idx} = this.props;
    return (
      <div
        style={{
          width: "100%",
          height: 50,
          flexDirection: "row",
          justifyContent: "center",
          borderColor: "lightgrey",
          borderWidth: 0,
          borderTopWidth: !idx ? 1 : 0,
          borderBottomWidth: 1,
          borderStyle: "solid",
          cursor: "pointer",
          alignItems: "center"
        }}
        onClick={()=>{this.props.selectFn(this.props.address, false, "manual");}}
      >
        <FontAwesomeIcon style={{marginRight: 3, color: "lightgrey", flex: 1}} icon="history"/>
        <div
        className={`neighborHeaderTextSmall`}
          style={{
            flex: 9,
            justifyContent: "center",
            color: "grey",
            marginLeft: 20,
            marginRight: 20,
            height: "100%",
            alignItems: "center"
          }}
        >
          {this.props.address.zipCode}
        </div>
      </div>
    );
  }
}

export default LocationScreenPreviousLocationRow;