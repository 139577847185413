import LoggedInContainer from "./Pages/LoggedInContainer";
import Landing from "./Pages/Landing";
import Confirmation from "./Pages/Confirmation";
import CustomerList from "./Pages/CustomerList";
import CustomerInfo from "./Pages/CustomerInfo";
import ServiceDescription from "./Pages/ServiceDescription";
import BrandInformation from "./Pages/BrandInformation";
import Calendar from "./Pages/Calendar";
import AdditionalInfo from "./Pages/AdditionalInfo";
import Submit from "./Pages/Submit";
import Location from "./Pages/Location";
import HasScheduleSelect from "./Pages/HasScheduleSelect";
import FeePage from "./Pages/FeePage";
import ResetPassword from "./Pages/ResetPassword";

const routes = [
  {path: "/", exact: true, name: "LoggedInContainer", component: LoggedInContainer},
  {path: "/landing", exact: true, name: "Landing", component: Landing},
  {path: "/details", exact: true, name: "Details", component: Confirmation},
  {path: "/customerlist", exact: true, name: "Customer List", component: CustomerList},
  {path: "/customerinfo", exact: true, name: "Customer Info", component: CustomerInfo},
  {path: "/servicedescription", exact: true, name: "Service Description", component: ServiceDescription},
  {path: "/brandinformation", exact: true, name: "Brand Information", component: BrandInformation},
  {path: "/calendar", exact: true, name: "Calendar", component: Calendar},  
  {path: "/additionalinfo", exact: true, name: "Additional Info", component: AdditionalInfo},
  {path: "/confirmation", exact: true, name: "Confirmation", component: Submit},
  {path: "/location", exact: true, name: "Location", component: ''},
  {path: "/hasscheduleselect", exact: true, name: "Schedule Select", component: HasScheduleSelect},
  {path: "/fees", exact: true, name: "Franchise Fees", component: FeePage},
  {path: "/forgotPassword", name: "Reset Password", component: ResetPassword},
];

export default routes;