import React, { Component } from 'react';
import './App.css';
import configureStore from './store'
import { Provider } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Login from './Pages/Login';
import LoggedInContainer from './Pages/LoggedInContainer';
import { library } from '@fortawesome/fontawesome-svg-core';
import { PersistGate } from 'redux-persist/integration/react'
import {
	faChevronLeft,
	faChevronRight,
	faChevronDown,
	faTimes,
	faExclamationTriangle,
	faPlus,
	faSearch,
	faBriefcase,
	faUsers,
	faMapMarker,
	faHistory,
	 faAngleLeft,
	faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga'
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

const {store,persistor} = configureStore()

history.listen(location => {
	let getLocationHash = location.hash;
	const trimGetLocationSlash = getLocationHash.substring(1,getLocationHash.length);
    ReactGA.set({ page: trimGetLocationSlash })
    ReactGA.pageview(trimGetLocationSlash)
})

global.authBaseUrl = process.env.REACT_APP_AUTHBASEURL;
global.coreBaseUrl = process.env.REACT_APP_COREBASEURL;
global.uspBaseUrl = process.env.REACT_APP_USPBASEURL;
global.blobBaseUrl = process.env.REACT_APP_BLOBBASEURL;
global.franchiseGroupUrl = process.env.REACT_APP_FRANCHISEGROUPURL;
global.servicesGroupUrl = process.env.REACT_APP_SERVICESGROUPURL;
global.serviceOrchUrl = process.env.REACT_APP_SERVICEORCHURL; 
global.syncGroupUrl = process.env.REACT_APP_SYNCGROUPURL; 
global.gaCode = process.env.REACT_APP_GOOGLEANALYTICS;
global.statesAPIappKey = process.env.REACT_APP_RAN_STATES_API_KEY; 
global.zipcodeBasedState = process.env.REACT_APP_GET_STATEURL;
global.unifiedSyncPlatform = process.env.REACT_APP_UNIFIED_SYNC_PLATFORM;

class App extends Component {
	constructor(props) {
		super(props);
		library.add(faChevronLeft);
		library.add(faChevronRight);
		library.add(faChevronDown);
		library.add(faTimes);
		library.add(faExclamationTriangle);
		library.add(faAngleLeft);
		library.add(faAngleRight);
		library.add(faPlus);
		library.add(faSearch);
		library.add(faBriefcase);
		library.add(faUsers);
		library.add(faMapMarker);
		library.add(faHistory);
	};

	componentDidMount() {
		const gaCodeVal = global.gaCode;
		ReactGA.initialize(gaCodeVal.toString());
        ReactGA.pageview(window.location.pathname);
		// let tagManagerArgs = {
		// 	gtmId: 'GTM-M68K4MG'
		// }
		// TagManager.initialize(tagManagerArgs);
    }
	
	render() {
		return (
			<Provider store={store}>
				 <PersistGate loading={null} persistor={persistor}>
				 <HashRouter history={history}>
					<Switch>
						<Route exact path="/login" name="Login Page" component={Login} />
						<Route path="/" name="Main" component={LoggedInContainer} />
					</Switch>
				</HashRouter>
				 </PersistGate>
			</Provider>
		);
	}
}
// Add this chunk of code:
if (process.env.NODE_ENV !== 'production' && module.hot) {
	// Note! Make sure this path matches your rootReducer import exactly
	// Does not play well with "NODE_PATH" aliasing.
	module.hot.accept('../src/reducers/root', () => {
		const newRootReducer = require('../src/reducers/root').default;
		store.replaceReducer(newRootReducer);
	});
}
export default App;
