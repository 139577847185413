import React, {Component} from "react";
import {connect} from "react-redux";
import {
  resetData,
  saveAdditionalInfo,
  bookLead,
  acknowledgeEvent
} from "../actions/index";
import { AvForm, AvField } from "availity-reactstrap-validation";
import SquareButton from "../Components/SquareButton";
import LoggedInContainerHeader from "../Components/LoggedInContainer/LoggedInContainerHeader";
import {Redirect} from "react-router-dom";
import {EventStatuses} from "../system";
import Loader from "react-loader-spinner";
import DetermineBookingService from "../helpers/booking/BookingServiceDecisioner";

class AdditionalInfo extends Component {
  constructor(props) {
    super(props);
    this.redirectEndpoint = null;
    this.state = {
      comments: null
    };
  }

  componentDidMount() {
    if (this.props.bookLeadSuccess) {
      this.props.history.push("/confirmation");
    }
  }

  componentDidUpdate() {
    let {eventStatuses} = this.props;
    let {COMPLETED} = EventStatuses;
    if (eventStatuses["bookLead"] === COMPLETED && this.redirectEndpoint) {
      this.props.acknowledgeEvent("bookLead");
      this.props.history.push(this.redirectEndpoint);
    }
  }

  submit = (event, values) => {
    let {additionalInfo} = values;
    this.redirectEndpoint = "/confirmation";
    this.props.saveAdditionalInfo(additionalInfo);
    this.buildBookingPayload();
  }

  buildBookingPayload = () => {
    let { conceptId } = this.props.selectedCompany;
    let { conceptCode } = this.props.companiesHashMap[conceptId];
    let bookingPayload = DetermineBookingService(
      conceptCode,
      this.props.selectedCompany,
      this.props.serviceQuestionAnswers,
      this.props.customerInfoTemp,
      this.props.scheduledDateTimeObj,
      this.state.comments,
      this.props.userId
    );
    this.props.bookLead(bookingPayload);
  }

  render() {
    return (      
      <div style={{width: "100%", flexDirection: "column", alignItems: "center"}}>
        {
          !this.props.selectedCompany &&
          <Redirect to="/landing"/>
        }
        <LoggedInContainerHeader
          history={this.props.history}
          resetData={this.props.resetData}
          showCancelButton={true}
        />
        {
          this.props.eventStatuses["bookLead"] === EventStatuses.PENDING ?
            <div style={{width: "100%", height: "100%", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
              <div style={{width: "100%", marginBottom: 10, textAlign: "center", alignItems: "center", justifyContent: "center"}}>Submitting Request. Please wait...</div>
              <div style={{width: "100%", alignItems: "center", justifyContent: "center"}}>
                <Loader
                  type="Oval"
                  color="#a5a9af"
                  height="50"
                  width="50"
                />
              </div>
            </div> :
            <div style={{width: "100%", height: "100%", flexDirection: "column", maxWidth: 800}}>
              <div style={{width: "100%", paddingLeft: 20, paddingRight: 20, marginTop: 75, marginBottom: 30, fontSize: 28, fontFamily: "Montserrat SemiBold"}}>
                Anything Else We Should Know?
              </div>
              <div style={{width: "100%", height: 300, maxHeight: 300, paddingLeft: 20, paddingRight: 20}}>
                <AvForm onValidSubmit={this.submit} id="submit-comments-form" style={{width: "100%", height: "100%"}}>
                  <AvField
                    onChange={e => {
                      this.setState({comments: e.target.value});
                    }}
                    type="textarea"
                    placeholder="Comments"
                    name="additionalInfo"
                    style={{height: "100%"}}
                  />
                </AvForm>
              </div>
              <div style={{width: "100%", alignItems: "center", justifyContent: "center", marginTop: 40, paddingLeft: 20, paddingRight: 20}}>
                <SquareButton
                  className="boxShadow" 
                  form="submit-comments-form"
                  text="SUBMIT"
                  buttonstyle={{height: 50, width: "100%", backgroundColor: "rgb(38, 125, 191)", marginTop: 10}}
                  textstyle={{color: "white", fontSize: 16, fontFamily: "Montserrat SemiBold"}}
                />
              </div>
            </div>
        }        
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    selectedCompany,
    serviceQuestionAnswers,
    scheduledDateTimeObj,
    companiesHashMap,
    customerInfoTemp,
    bookLeadSuccess
  } = state.schedulingReducer;
  const {eventStatuses} = state.systemReducer;
  const {userId} = state.loginReducer;
  return {
    ...state,
    selectedCompany,
    serviceQuestionAnswers,
    scheduledDateTimeObj,
    companiesHashMap,
    customerInfoTemp,
    eventStatuses,
    userId,
    bookLeadSuccess
  };
}

export default connect(mapStateToProps, {
  resetData,
  saveAdditionalInfo,
  bookLead,
  acknowledgeEvent
})(AdditionalInfo);