class CoreBookingValidation {
  constructor(selectedCompany, serviceQuestionAnswers, customerInfo, scheduledDateTime, additionalInfo, userId) {
    this.selectedCompany = selectedCompany;
    this.serviceQuestionAnswers = serviceQuestionAnswers;
    this.customerInfo = customerInfo;
    this.scheduledDateTime = scheduledDateTime;
    this.additionalInfo = additionalInfo;
    this.userId = userId;
  }

  buildBookingPayload = () => {
    let bookingPayload = {
      licenseNumber: this.selectedCompany.licenseNumber,
      webLocationId: this.selectedCompany.franchiseWebLocationId,
      firstName: this.customerInfo.FirstName,
      lastName: this.customerInfo.LastName,
      email: this.customerInfo.Email,
      phone: this.customerInfo.PhoneNumber || this.customerInfo.Phone,
      phoneType: "Mobile", //un-hardcode later
      //phone2: "5128675309", //add value for later
      //phoneType2: "Work", //un-hardcode later
      preferredCommunicationType: this.customerInfo.ContactMethod || "Email",
      emailOptOut: false, //un-hardcode later
      address: this.customerInfo.Street,
      city: this.customerInfo.City,
      state: this.customerInfo.State,
      postalCode: this.customerInfo.Zip,
      note: this.additionalInfo,
      leadSource: "Referral",
      leadOrigin: "Referral",
      campaignName: "Test Campaign", //un-hardcode later maybe?
      disposition: "Residential Appointment",
      callType: "Inbound",
      isTest: false, //set to false for production release
      scheduleId: this.scheduledDateTime? this.scheduledDateTime.scheduleId : null,
      isLeadOnly: !(this.scheduledDateTime ? this.scheduledDateTime.scheduleId : null),
      conceptCalledId: this.selectedCompany.conceptId,
      isEmergencyCall: false,
      RANLoginTypeID: "1",
      RANUserID: this.userId,
      ServiceSkuId:this.serviceQuestionAnswers.serviceSelection
    };
    return bookingPayload;
  }
}

export default CoreBookingValidation;