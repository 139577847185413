import React, {Component} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from "../Alert";
import {connect} from "react-redux";
import  {withRouter  } from 'react-router-dom'
import {
  acknowledgeMessageDisplayed,
  acknowledgeMessageConsumed,
  logout
} from "../../actions";
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

class LoggedInContainerHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  componentDidUpdate() {
    if (this.state.cancelButtonClicked && !this.cancelButtonClickAcknowledged) {
      this.cancelButtonClickAcknowledged = true;
      this.resetProgressModal.showWithMessage("Cancel Request?", "Your current progress will be lost and you will return to the beginning.", true, "START OVER");
    }

    if (this.props.messageQueue.length && (!this.props.messageQueue[0].displayed)) {
      
      let message = this.props.messageQueue[0];
      this.generalInfoModal.showWithMessage(message.title, message.body);
      this.props.acknowledgeMessageDisplayed();
    }
  }

  logoutMethod = () => {
    // setTimeout(() => {
      this.props.logout();
      localStorage.clear();
      this.props.history.push('/login');
    // },);
	}

  goBackHistory = () => {
    if(this.props.history.location.pathname === '/location'){ 
      let path = `/landing`; 
      this.props.history.push({
        pathname: path,
        state: ''
      })
    }else{
      return this.props.history.goBack();
    }
  }

  render() {
    return (
      <div
        style={{
          width: "100%",
          height: 50,
          backgroundColor: "rgb(38, 125, 191)",
          position: "fixed",
          top: 0,
          zIndex: 2
        }}
      >
        <Alert
          ref={r => {this.resetProgressModal = r;}}
          onConfirm={() => {
            this.props.resetData();
            this.props.history.replace("/landing");
          }}
        />
        <Alert
          ref={r => {this.generalInfoModal = r;}}
          onConfirm={() => {
            this.props.acknowledgeMessageConsumed();
          }}
        />
        <div style={{width: "100%", justifyContent: "space-between", flexDirection: "row"}}>
          <div style={{width: "30%"}}>
            {
              !this.props.noBackButton &&
              <div
                onClick={this.goBackHistory} 
                style={{
                  width: "100%",
                  height: "100%",
                  color: "white",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontSize: 20,
                  cursor: "pointer",
                  marginLeft: 5
                }}
              >
                <FontAwesomeIcon style={{marginRight: 3}} icon="chevron-left"/> Back
              </div>
            }
          </div>
          <div style={{width:"40%"}}>
            <div style={{width: "100%", height: "100%", alignItems: "center", justifyContent: "center"}}>
              <img
                alt=""
                style={{width: 140, objectFit: "contain"}}
                src={require("../../Assets/neighborlyBaseLogo.png")}
              />
            </div>
          </div>
          <div style={{width: "20%"}}>
          {
              this.props.showCancelButton &&
              <div
                onClick={()=>{
                  this.setState({cancelButtonClicked: true});
                  this.cancelButtonClickAcknowledged = false;
                }}
                style={{width: "100%", height: "100%", color: "white", justifyContent: "flex-end", alignItems: "center", fontSize: 20, cursor: "pointer"}}
              >
                Cancel
              </div>
            } 
            </div>
          <div style={{width: "10%"}}>
              <div
                onClick={this.logoutMethod}
                style={{width: "100%", height: "100%", color: "white", justifyContent: "flex-end",marginRight:"10px", alignItems: "center", fontSize: 20, cursor: "pointer"}}
              >Logout<FontAwesomeIcon style={{marginLeft: 10}} icon={faSignOutAlt}/>
              </div>        
          </div>
        </div>
      </div>     
    );
  }
}

function mapStateToProps(state) {
  const {messageQueue} = state.systemReducer;
  return {
    ...state,
    messageQueue
  };
}

export default connect(mapStateToProps, {
  acknowledgeMessageDisplayed,
  acknowledgeMessageConsumed,
  logout
})(withRouter(LoggedInContainerHeader));