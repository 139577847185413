import { combineReducers } from 'redux';
import loginReducer from "./login";
import schedulingReducer from "./scheduling";
import systemReducer from "./system";
import logoutReducer from './logout';

const appReducer = combineReducers({
  loginReducer,
  schedulingReducer,
  systemReducer,
  logoutReducer
});

const rootReducer = (state, action) => {
  if(action.type === 'USER_LOGOUT'){
      state = {};
  }   
  return appReducer(state, action);
};
 
export default rootReducer;