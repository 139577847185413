import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import SquareButton from '../SquareButton';
import { EventStatuses } from '../../system';
import Loader from 'react-loader-spinner';

class ConfirmationPageHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirect: null
		};
	}
	goToCustomerInfo = () => {
		this.props.history.push('/customerlist');
	};

	render() {
		return (
			<div
				style={{
					borderBottomWidth: 0.5,
					borderColor: 'gray',
					borderBottomStyle: 'solid',
					width: '100%',
					padding: 30,
					flexDirection: 'column'
				}}
			>
				<div
					style={{
						width: '100%',
						flexDirection: 'row'
					}}
				>
					{/* Company Logo */}
					<div style={{ flex: 1, justifyContent: 'center', alignItems: 'center', paddingRight: 10 }}>
						{this.props.selectedCompany && (
							<img
								alt=""
								style={{
									height: '100%',
									width: '100%',
									backgroundColor: 'transparent',
									maxHeight: 100,
									objectFit: 'contain'
								}}
								src={`${global.blobBaseUrl}/assets/${this.props.selectedCompany.conceptId}.png`}
							/>
						)}
					</div>
					<div style={{ flex: 1, justifyContent: "center", alignItems: "center"}}>
						<div
							style={{
								marginTop: 5,
								marginBottom: 5,
								flexDirection: 'column'
							}}
						>
							<div className={`neighborHeaderTextSmall`} style={{ color: 'rgb(86, 86, 86)', fontFamily: 'Montserrat Light' }}>
								{this.props.selectedCompany &&
									this.props.selectedCompany.franchiseDetails &&
									`${this.props.selectedCompany.franchiseDetails.address} ${this.props
										.selectedCompany.franchiseDetails.address2 || ''}`}
							</div>
							<div className={`neighborHeaderTextSmall`} style={{ color: 'rgb(86, 86, 86)', fontFamily: 'Montserrat Light' }}>
								{this.props.selectedCompany &&
									this.props.selectedCompany.franchiseDetails &&
									`${this.props.selectedCompany.franchiseDetails.city}, ${this.props
										.selectedCompany.franchiseDetails.state} ${this.props
											.selectedCompany.franchiseDetails.zip}`}
							</div>
							<div className={`neighborHeaderTextSmall`} style={{ color: 'rgb(86, 86, 86)', fontFamily: 'Montserrat Light' }}>
								<div style={{ fontFamily: 'Montserrat ExtraBold', marginRight: 7 }}>Phone: </div>
								{this.props.selectedCompany &&
									this.props.selectedCompany.franchiseDetails &&
									(() => {
										let phoneNumber = this.props.selectedCompany.franchiseDetails.contractBusinessPhone;
										if (!phoneNumber) {
											return 'None Listed.';
										}
										return ` ${phoneNumber}`;
									})()}
							</div>
						</div>

					</div>
				</div>
				{this.state.redirect}
				<div style={{ flex: 1, width: '100%', marginTop: 20 }}>
					<div
					className="neighborHeaderTextLarge"
						style={{
							color: 'rgb(86, 86, 86)',
							fontFamily: 'Montserrat Bold'
						}}
					>
						{this.props.selectedCompany ? this.props.selectedCompany.doingBusinessAs : ''} <br/>
						Opening Hours:
					</div>
				</div>
					<div
						style={{
							flex: 3,
							flexDirection: 'column'
						}}
					>
						{/*Hours*/}
						<div
							style={{
								width: '100%',
								marginTop: 5,
								marginBottom: 5,
								justifyContent: "center",
								alignItems: "center",
								color: 'rgb(39, 126, 191)',
								fontFamily: "Montserrat regular"
							}}
						>
							<div style={{ flexDirection: 'column', marginRight: 10 }}>
								{['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((h, k) => {
									return (
										<div className={`neighborHeaderTextSmall`} key={k}>
											{h}
										</div>
									);
								})}
							</div>
							{this.props.eventStatuses['getFranchiseDetailInfo'] === EventStatuses.PENDING ? (
								<div
									style={{
										flexDirection: 'column',
										alignItems: 'center',
										justifyContent: 'center',
										marginLeft: 10
									}}
								>
									<Loader type="Oval" color="#a5a9af" height="50" width="50" />
								</div>
							) : (
									<div style={{ flexDirection: 'column', marginLeft: 10 }}>
										{this.props.selectedCompany &&
											this.props.selectedCompany.franchiseDetails &&
											this.props.selectedCompany.franchiseDetails.hoursofOperation &&
											this.props.selectedCompany.franchiseDetails.hoursofOperation.map(
												(fpot, k) => {
													let operationTimeString = '';
													if (!fpot.isOpenForNormalHours) {
														operationTimeString = 'Closed';
													} else {
														let startTime = moment()
															.startOf('day')
															.add(fpot.startMinutes, 'minutes')
															.format('hh:mm A');
														let endTime = moment()
															.startOf('day')
															.add(fpot.endMinutes, 'minutes')
															.format('hh:mm A');
														operationTimeString = `${startTime} - ${endTime}`;
													}
													return (
														<div className={`neighborHeaderTextSmall`} key={k}>
															{operationTimeString}
														</div>
													);
												}
											)}
									</div>
								)}
						</div>
					</div>
				<div style={{ flex: 1, width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 30 }}>
					<SquareButton
						className={`neighborHeaderTextLarge boxShadow`}
						onClick={this.goToCustomerInfo}
						text="REQUEST APPOINTMENT"
						buttonstyle={{ height: 40, width: '100%', backgroundColor: 'rgb(39, 126, 191)', maxWidth: 300 }}
						textstyle={{ color: 'white', fontFamily: 'Montserrat SemiBold'}}
					/>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { selectedCompany, companiesHashMap } = state.schedulingReducer;
	const { eventStatuses } = state.systemReducer;
	return {
		...state,
		selectedCompany,
		companiesHashMap,
		eventStatuses
	};
}

export default connect(mapStateToProps, {})(ConfirmationPageHeader);
