import React, {Component} from "react";
import StarRatings from "react-star-ratings";

class LandingPageServiceRatingCircle extends Component {
  render() {
    return (
          <div style={{width: "100%", height: "100%", justifyContent: "flex-start", alignItems: "center", flexDirection: "row"}}>
            <div className="neighborHeaderTextLarge" style={{fontWeight: "bold", marginTop: 5, marginRight: 5,flexDirection: "column"}}>
              {
                this.props.rating ? parseFloat((this.props.rating + "")).toFixed(2) : "--"
              }
            </div>
            
            <div style={{position: "relative", width: "100%", flexDirection: "column"}}>
                <StarRatings
                  name="starRating"
                  numberOfStars={5}
                  rating={(this.props.rating ? parseFloat((this.props.rating + "")) : 0)}
                  starDimension={"12px"}
                  starSpacing={"1px"}
                  starRatedColor="rgb(255, 193, 7)"
                  starEmptyColor="rgba(0, 0, 0, 0.3)"
                />
              </div>
        </div>
    );
  }
}

export default LandingPageServiceRatingCircle;