import {
  submitAddressForAppointmentSuccess,
  getPreviousAddressesForAppointmentSuccess,
  getServiceCategoriesSuccessful,
  getCompaniesSuccessful,
  getCustomServiceQuestionsSuccessful,
  getbrandsSuccessful,
  startEvent,
  completeEvent,
  failEvent,
  getNearbyFranchisesSuccessful,
  getNearbyFranchisesFailed,
  getFranchiseDetailInfoSuccessful,
  getFranchiseServicesInfoSuccessful,
  getFranchiseRoundRobinInfoSuccessful,
  selectCompanyForAppointmentSuccess,
  setCustomerInfoSuccess,
  saveServiceQuestionsSuccess,
  saveBrandInformationSuccess,
  saveOptionsSelectedSuccess,
  saveScheduledDateAndTimeSuccess,
  saveAdditionalInfoSuccess,
  getServiceSearchTermResultsSuccessful,
  getCustomersBasedOnLocationSuccessful,
  getServiceTypeProblemsSuccessful,
  getFranchiseServicesInfoNewSuccessful,
  getTechAvailabilitySuccessful,
  queueMessage,
  getCustomersBySearchCriteriaSuccessful,
  getCustomersByBrandSuccessful,
  bookLeadSuccessful,
  getNearbySingularFranchiseSuccessful,
  getTechAvailabilityFailed,
  getFeesForFranchiseSuccess,
  getStatesSuccessful,
  logout
} from "../actions/index";
import {
  serviceCategories,
  customServiceQuestions,
  RANVendorKeyGuid
} from "../constants";
import BaseService from "./baseService";
import axios from "axios";
import moment from "moment";

const BRANDS_URL = `/protected/concepts?apikey={${RANVendorKeyGuid}}`;
const NEARBY_FRANCHISES_URL =
//  `/protected/franchiseweblocations/search?apikey={${RANVendorKeyGuid}}`;
 '/UnifiedSyncPlatform/FranchiseLookUpByServiceAddress?Address={zipCode}&searchType={searchType}&IsRoundRobin={IsRoundRobin}&RequireAddressOnPartialPostalCode=false&IsPostalCodeOnlyVendor={IsPostalCodeOnlyVendor}';
const NEARBY_FRANCHISES_WITH_SERVICE_ID_URL =
'/UnifiedSyncPlatform/FranchiseLookUpByServiceAddress?Address={zipCode}&IsRoundRobin=false&RequireAddressOnPartialPostalCode=false&IsPostalCodeOnlyVendor=false';
const NEARBY_FRANCHISES_BY_COORDINATES_URL =
  `/protected/franchiseweblocations/search?apikey={${RANVendorKeyGuid}}`
const NEARBY_FRANCHISES_WITH_SERVICE_ID_BY_COORDINATES_URL =
  `/protected/franchiseweblocations/search?apikey={${RANVendorKeyGuid}}`
const FRANCHISE_INFO_URL =
  `/public/fullattribute/franchiseWeblocations/search?franchiseWeblocationId={franchiseWebLocationId}&apikey={${RANVendorKeyGuid}}`;
const FRANCHISE_SERVICES_URL =
  `/public/franchise-web-locations/{webLocation}/service-aggregates/search?include_service_sku_id=true&apikey={${RANVendorKeyGuid}}`;
const FRANCHISE_SERVICES_NEW_URL =
  `/api/aggregates/service-web-categories/search?apikey={${RANVendorKeyGuid}}`;
const FRANCHISE_ROUND_ROBIN_URL =
  `/protected/concepts/{conceptCode}/franchiseweblocations/search?apikey={${RANVendorKeyGuid}}`
const SERVICE_SEARCH_TERM_URL = `/public/service-search-term-aggregates/search?search_term={searchTerm}&apikey={${RANVendorKeyGuid}}`;
const FIND_CUSTOMERS_BY_LOCATION_URL = "/api/mastercustomers/searchbycriteria?";
const SMARTWARE_MAKES_URL = `/public/concept-codes/{conceptCode}/makes?apikey={${RANVendorKeyGuid}}`;
const SMARTWARE_SERVICE_TYPE_PROBLEMS_URL =
  `/public/service-type-problem-aggregates/search?service_sku_id={serviceSkuId}&apikey={${RANVendorKeyGuid}}`;
const TECH_AVAILABILITY_URL =
`/public/UnifiedSyncPlatform/TechAvailability?apikey={${RANVendorKeyGuid}}&PostalCode={zipCode}&ServiceSkuId={serviceSkuId}&DateStart={dateStart}&NumberOfDays={NumberOfDays}&LicenseNumber={licenseNumber}&IsEstimate={isEstimate}&webLocationId={webLocationId}`;
const BOOK_LEAD_URL = `/public/UnifiedSyncPlatform/Booking?apikey={${RANVendorKeyGuid}}`;
const JOBS_BY_FRANCHISE_URL =
`/public/UnifiedSyncPlatform/CurrentJobsByFranchise?apikey={${RANVendorKeyGuid}}&FranchiseIds={franchiseIds}&StartDate={startDate}&EndDate={endDate}`
const JOBS_BY_FRANCHISE_LOC_URL =
`/public/UnifiedSyncPlatform/CurrentJobsByFranchise?apikey={${RANVendorKeyGuid}}&FranchiseIds={franchiseIds}&StartDate={startDate}&EndDate={endDate}&Latitude={lat}&Longitude={lng}&MaxDistance={maxDistance}`

const NEARBY_SINGULAR_FRANCHISE =
  `/protected/concepts/{conceptCode}/franchiseweblocations/search?apikey={${RANVendorKeyGuid}}`;
const FRANCHISE_FEES_URL =
`/protected/franchiseWebLocationProfileFees/franchiseWeblocations/{franchiseWebLocationId}?apikey={${RANVendorKeyGuid}}`;
const REGION_BASED_URL =
`/public/IdentityExperience/States/US?apikey=`;

class SchedulingService extends BaseService {
  async submitAddressForAppointment(address, shouldPreserve, method, dispatch) {
    const token = await localStorage.getItem("token");
    try {
      if (shouldPreserve) {
        let previousAddresses = await localStorage.getItem("addressList");
        let deserializedAddresses = JSON.parse(previousAddresses || "[]");
        deserializedAddresses.push(address);
        console.log("look....", deserializedAddresses);
        await localStorage.setItem(
          "addressList",
          JSON.stringify(deserializedAddresses)
        );
      }

      dispatch(submitAddressForAppointmentSuccess(address, method));
    } catch (error) {
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
      
    }
  }

  async getPreviousAddressesForAppointment(dispatch) {
    const token = await localStorage.getItem("token");
    try {
      let previousAddresses = await localStorage.getItem("addressList");
      let deserializedAddresses = JSON.parse(previousAddresses || "[]");

      dispatch(
        getPreviousAddressesForAppointmentSuccess(deserializedAddresses)
      );
    } catch (error) {
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
    }
  }

  async getCompanies(dispatch) {
    dispatch(startEvent("getCompanies"));
    const token = await localStorage.getItem("token");
    try {
      const options = this.axiosOptions()
        .asGET()
        .withJSONHeaders(token)
        .withURL(global.franchiseGroupUrl + BRANDS_URL)
        .build();

      const response = await axios(options);

      var responseData = response.data;

      dispatch(completeEvent("getCompanies"));
      dispatch(getCompaniesSuccessful(responseData));
    } catch (error) {
      console.log("error: ", error);
      dispatch(failEvent("getCompanies"));
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
      //to do
    }
  }

  async getNearbyFranchises(zipCode, dispatch) {
    dispatch(startEvent("getNearbyFranchises"));
    const token = await localStorage.getItem("token");
    try {

        let url = global.unifiedSyncPlatform + NEARBY_FRANCHISES_URL + `&apikey=${global.statesAPIappKey}`;
        url = url.replace("{zipCode}", zipCode);
        url = url.replace("{searchType}", 1);
        url = url.replace("{requireAddressOnPartialPostalCode}", false);
        url = url.replace("{IsRoundRobin}", false);
        url = url.replace("{IsPostalCodeOnlyVendor}", true);
  
        const options = this.axiosOptions()
          .asGET()
          .withJSONHeaders(token)
          .withURL(url)
          .build();

      const response = await axios(options);

      let responseData = response.data;

      responseData.forEach(function (element) {
        element.conceptId = element.conceptID;
      });

      dispatch(completeEvent("getNearbyFranchises"));
      dispatch(getNearbyFranchisesSuccessful(responseData));
    } catch (error) {
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
      console.log("getNearbyFranchisesError: ", error);
      dispatch(getNearbyFranchisesFailed())
      dispatch(failEvent("getNearbyFranchises"));
    }
  }

  async getNearbyFranchisesByCoordinates(latitude, longitude, dispatch) {
    dispatch(startEvent("getNearbyFranchises"));
    const token = await localStorage.getItem("token");
    try {

      let url = global.franchiseGroupUrl + NEARBY_FRANCHISES_BY_COORDINATES_URL;

      const data = {
        "searchType": 2,
        "latitude": latitude,
        "longitude": longitude,
        "isRoundRobin": false,
        "requireAddressOnPartialPostalCode": false,
        "isPostalCodeOnlyVendor": false }
      const options = this.axiosOptions()
        .asPOST()
        .withJSONHeaders(token)
        .withURL(url)
        .withData(data)
        .build();

      const response = await axios(options);

      var responseData = response.data;

      dispatch(completeEvent("getNearbyFranchises"));
      dispatch(getNearbyFranchisesSuccessful(responseData));
    } catch (error) {
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
      console.log("getNearbyFranchisesError: ", error);
      dispatch(getNearbyFranchisesFailed())
      dispatch(failEvent("getNearbyFranchises"));
    }
  }

  async getNearbyFranchisesFilteredByServiceId(zipCode, serviceWebCategoryId, dispatch) {
    dispatch(startEvent("getNearbyFranchises"));
    const token = await localStorage.getItem("token");
    try {
      let url = global.unifiedSyncPlatform + NEARBY_FRANCHISES_WITH_SERVICE_ID_URL + `&apikey=${global.statesAPIappKey}` + `&serviceWebCategoryId=${serviceWebCategoryId}`;
      url = url.replace("{zipCode}", zipCode);


       const options = this.axiosOptions()
       .asGET()
       .withJSONHeaders(token)
       .withURL(url)
       .build();

      const response = await axios(options);

      let responseData = response.data;

      responseData.forEach(function (element) {
        element.conceptId = element.conceptID;
      });

      dispatch(completeEvent("getNearbyFranchises"));
      dispatch(getNearbyFranchisesSuccessful(responseData));
    } catch (error) {
      console.log("getNearbyFranchisesError: ", error);
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
      dispatch(getNearbyFranchisesFailed())
      dispatch(failEvent("getNearbyFranchises"));
    }
  }

  async getNearbyFranchisesFilteredByServiceIdWithCoordinates(
    latitude,
    longitude,
    serviceWebCategoryId,
    dispatch
  ) {
    dispatch(startEvent("getNearbyFranchises"));
    const token = await localStorage.getItem("token");
    try {

      let url =
        global.franchiseGroupUrl +
        NEARBY_FRANCHISES_WITH_SERVICE_ID_BY_COORDINATES_URL;

      const data = {
        "searchType": 2,
        "latitude": latitude,
        "longitude": longitude,
        "serviceWebCategoryId": serviceWebCategoryId,
        "isRoundRobin": false,
        "requireAddressOnPartialPostalCode": false,
        "isPostalCodeOnlyVendor": false }
      const options = this.axiosOptions()
        .asPOST()
        .withJSONHeaders(token)
        .withURL(url)
        .withData(data)
        .build();

      const response = await axios(options);

      var responseData = response.data;
      dispatch(completeEvent("getNearbyFranchises"));
      dispatch(getNearbyFranchisesSuccessful(responseData));
    } catch (error) {
      console.log("getNearbyFranchisesError: ", error);
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
      dispatch(getNearbyFranchisesFailed())
      dispatch(failEvent("getNearbyFranchises"));
    }
  }

  async getServiceCategories(dispatch) {
    const token = await localStorage.getItem("token");
    try {
      dispatch(getServiceCategoriesSuccessful(serviceCategories));
    } catch (error) {
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
    }
  }

  async getCustomServiceQuestions(dispatch) {
    const token = await localStorage.getItem("token");
    try {
      dispatch(getCustomServiceQuestionsSuccessful(customServiceQuestions));
    } catch (error) {
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
    }
  }

  async getBrands(ConceptCode, dispatch) {
    const token = await localStorage.getItem("token");
    dispatch(startEvent("getBrands"));
    try {

      let url = global.serviceOrchUrl + SMARTWARE_MAKES_URL;
      url = url.replace("{conceptCode}", ConceptCode);

      const options = this.axiosOptions()
        .asGET()
        .withURL(url)
        .build();

      const response = await axios(options);

      var responseData = response.data;

      dispatch(completeEvent("getBrands"));

      dispatch(getbrandsSuccessful(responseData));
    } catch (error) {
      dispatch(failEvent("getBrands"));
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
    }
  }

  async getFranchiseDetailInfo(franchiseWebLocationId, dispatch) {
    dispatch(startEvent("getFranchiseDetailInfo"));
    const token = await localStorage.getItem("token");
    try {

      let url = global.franchiseGroupUrl + FRANCHISE_INFO_URL;
      url = url.replace("{franchiseWebLocationId}", franchiseWebLocationId);

      const options = this.axiosOptions()
        .asGET()
        .withJSONHeaders(token)
        .withURL(url)
        .build();

      const response = await axios(options);
      var responseData = response.data;

      dispatch(getFranchiseDetailInfoSuccessful(responseData));
      dispatch(completeEvent("getFranchiseDetailInfo"));
    } catch (error) {
      dispatch(failEvent("getFranchiseDetailInfo"));
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
    }
  }

  async getFranchiseServicesInfo(selectedCompany, dispatch) {
    dispatch(startEvent("getFranchiseServicesInfo"));
    const token = await localStorage.getItem("token");
    try {

      let url = global.serviceOrchUrl + FRANCHISE_SERVICES_URL;
      url = url.replace("{webLocation}", selectedCompany[0].franchiseWebLocationId);

      const options = this.axiosOptions()
      .asGET()
      .withJSONHeaders(token)
      .withURL(url)
      .build();

      const response = await axios(options);
      var responseData = response.data;
      let getServiceModifier = localStorage.getItem("serviceModifierValue");
      var franchiseResponse = responseData.filter(function (el){
        if(getServiceModifier == "Residential"){
          return el.serviceType == "Residential";
        }else if(getServiceModifier == "Commercial"){
          return el.serviceType == "Commercial";
        }else if(getServiceModifier == "auto"){
          return el.serviceType == "Auto";
        }else{
          return null;
        }
      });

      dispatch(getFranchiseServicesInfoSuccessful(franchiseResponse));
      dispatch(completeEvent("getFranchiseServicesInfo"));
    } catch (error) {
      dispatch(failEvent("getFranchiseServicesInfo"));
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
    }
  }

  async getFranchiseServicesInfoNew(dispatch) {
    dispatch(startEvent("getFranchiseServicesInfoNew"));
    const token = await localStorage.getItem("token");
    try {

      let url = global.servicesGroupUrl + FRANCHISE_SERVICES_NEW_URL;
      const data = {};
      const options = this.axiosOptions()
        .asPOST()
        .withJSONHeaders(token)
        .withData(data)
        .withURL(url)
        .build();

      const response = await axios(options);
      var responseData = response.data;
      //remove duplicate services
      responseData = responseData.filter((data, index, self) =>
        index === self.findIndex((t) => (
          t.serviceId === data.serviceId && t.conceptId === data.conceptId
        ))
      )
      dispatch(getFranchiseServicesInfoNewSuccessful(responseData));
      dispatch(completeEvent("getFranchiseServicesInfoNew"));
    } catch (error) {
      dispatch(failEvent("getFranchiseServicesInfoNew"));
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
    }
  }

  async getFranchiseRoundRobinInfo(conceptCode, zipCode, dispatch) {
    dispatch(
      startEvent(`getFranchiseRoundRobinInfo-${conceptCode}-${zipCode}`)
    );
    const token = await localStorage.getItem("token");
    try {

      let url = global.franchiseGroupUrl + FRANCHISE_ROUND_ROBIN_URL;
      url = url.replace("{conceptCode}", conceptCode);

      const data = {
        "searchType": 1,
        "searchText": zipCode,
        "isRoundRobin": true,
       }
      const options = this.axiosOptions()
        .asPOST()
        .withJSONHeaders(token)
        .withURL(url)
        .withData(data)
        .build();


      const response = await axios(options);
      var responseData = response.data;

      dispatch(getFranchiseRoundRobinInfoSuccessful(responseData));
      dispatch(
        completeEvent(`getFranchiseRoundRobinInfo-${conceptCode}-${zipCode}`)
      );
    } catch (error) {
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
      dispatch(
        failEvent(`getFranchiseRoundRobinInfo-${conceptCode}-${zipCode}`)
      );
    }
  }

  async selectedRegion(region, dispatch) {
    dispatch(startEvent("selectedRegion"));
    const token = await localStorage.getItem("token");
    try {
      let url;
      console.log('statesAPIappKey',global.statesAPIappKey)
      if(region === 'US'){
        url = global.zipcodeBasedState + REGION_BASED_URL + `${global.statesAPIappKey}`;
      }else{
        url = global.zipcodeBasedState + REGION_BASED_URL + `${global.statesAPIappKey}`;
        url = url.replace('US', region);
      }
      const data = {};
      const options = this.axiosOptions()
        .asGET()
        .withJSONHeaders(token)
        .withData(data)
        .withURL(url)
        .build();

      const response = await axios(options);
      var responseData = response.data;
      dispatch(getStatesSuccessful(responseData));
      dispatch(completeEvent("selectedRegion"));
    } catch (error) {
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
      dispatch(failEvent("selectedRegion"));
    } 
  }

  async selectCompanyForAppointment(company, dispatch) {
    dispatch(startEvent("selectCompanyForAppointment"));
    const token = await localStorage.getItem("token");
    try {
      dispatch(selectCompanyForAppointmentSuccess(company));
      dispatch(completeEvent("selectCompanyForAppointment"));
    } catch (error) {
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
      dispatch(failEvent("selectCompanyForAppointment"));
    }
  }

  async setCustomerInfo(customerInfo, dispatch) {
    dispatch(startEvent("setCustomerInfo"));
    const token = await localStorage.getItem("token");
    try {
      dispatch(setCustomerInfoSuccess(customerInfo));
      dispatch(completeEvent("setCustomerInfo"));
    } catch (error) {
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
      dispatch(failEvent("setCustomerInfo"));
    }
  }

  async saveServiceQuestions(serviceQuestionAnswers, dispatch) {
    dispatch(startEvent("saveServiceQuestions"));
    const token = await localStorage.getItem("token");
    try {
      dispatch(saveServiceQuestionsSuccess(serviceQuestionAnswers));
      dispatch(completeEvent("saveServiceQuestions"));
    } catch (error) {
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
      dispatch(failEvent("saveServiceQuestions"));
    }
  }

  async saveBrandInformation(brandId, dispatch) {
    dispatch(startEvent("saveBrandInformation"));
    const token = await localStorage.getItem("token");
    try {
      dispatch(saveBrandInformationSuccess(brandId));
      dispatch(completeEvent("saveBrandInformation"));
    } catch (error) {
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
      dispatch(failEvent("saveBrandInformation"));
    }
  }

  async saveOptionsSelected(options, dispatch) {
    dispatch(startEvent("saveOptionsSelected"));
    const token = await localStorage.getItem("token");
    try {
      dispatch(saveOptionsSelectedSuccess(options));
      dispatch(completeEvent("saveOptionsSelected"));
    } catch (error) {
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
      dispatch(failEvent("saveOptionsSelected"));
    }
  }

  async saveScheduledDateAndTime(scheduledDateTimeObj, dispatch) {
    dispatch(startEvent("saveScheduledDateAndTime"));
    const token = await localStorage.getItem("token");
    try {
      dispatch(saveScheduledDateAndTimeSuccess(scheduledDateTimeObj));
      dispatch(completeEvent("saveScheduledDateAndTime"));
    } catch (error) {
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
      dispatch(failEvent("saveScheduledDateAndTime"));
    }
  }

  async saveAdditionalInfo(additionalInfo, dispatch) {
    dispatch(startEvent("saveAdditionalInfo"));
    const token = await localStorage.getItem("token");
    try {
      dispatch(saveAdditionalInfoSuccess(additionalInfo));
      dispatch(completeEvent("saveAdditionalInfo"));
    } catch (error) {
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
      dispatch(failEvent("saveAdditionalInfo"));
    }
  }

  async getServiceSearchTermResults(searchTerm, zipCode,serviceModifierValue,dispatch) {
    dispatch(startEvent("getServiceSearchTermResults"));
    const token = await localStorage.getItem("token");
    try {
      const token = await localStorage.getItem("token");
      let url = global.serviceOrchUrl + SERVICE_SEARCH_TERM_URL;
      url = url.replace("{searchTerm}", searchTerm);
      if(zipCode){
        url = url + `&postal_code=${zipCode}`
      }
      url = url + `&service_modifier=${serviceModifierValue}`;
      const options = this.axiosOptions()
        .asGET()
        .withJSONHeaders(token)
        .withURL(url)
        .build();

      const response = await axios(options);

      var responseData = response.data;

      dispatch(completeEvent("getServiceSearchTermResults"));
      dispatch(getServiceSearchTermResultsSuccessful(responseData));
    } catch (error) {
      console.log("error: ", error);
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
      dispatch(failEvent("getServiceSearchTermResults"));
    }
  }

  async getCustomersBasedOnLocation(franchiseIds, lat, lng, dispatch) {
    dispatch(startEvent("getCustomersBasedOnLocation"));
    const token = await localStorage.getItem("token");
    try {
      const token = await localStorage.getItem("token");
      let franchiseCSV = franchiseIds.join(",");
      let startDate = moment()
        .subtract(3, "days")
        .format("MM/DD/YYYY");
      let endDate = moment(startDate)
        .add(10, "days")
        .format("MM/DD/YYYY");

      let url = JOBS_BY_FRANCHISE_LOC_URL.replace("{franchiseIds}", franchiseCSV);
      url = url.replace("{lat}", lat);
      url = url.replace("{lng}", lng);
      url = url.replace("{startDate}", startDate);
      url = url.replace("{endDate}", endDate);
      url = url.replace("{maxDistance}", 50);
      url = global.uspBaseUrl + url;

      const options = this.axiosOptions()
        .asGET()
        .withJSONHeaders(token)
        .withURL(url)
        .build();

      const response = await axios(options);

      var responseData = response.data;

      dispatch(getCustomersBasedOnLocationSuccessful(responseData));
      dispatch(completeEvent("getCustomersBasedOnLocation"));
    } catch (error) {
      console.log("error: ", error);
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
      dispatch(failEvent("getCustomersBasedOnLocation"));
    }
  }

  async getCustomersBySearchCriteria(
    FirstName,
    LastName,
    PhoneNumber,
    Email,
    Address,
    City,
    State,
    Zip,
    dispatch
  ) {
    dispatch(startEvent("getCustomersBySearchCriteria")); //same archetype as getCustomersBasedOnLocation
    const token = await localStorage.getItem("token");
    try {

      let searchObject = {
        FirstName,
        LastName,
        Phone: PhoneNumber,
        Email,
        Address,
        City,
        State,
        PostalCode: Zip
      };

      let url = global.syncGroupUrl + FIND_CUSTOMERS_BY_LOCATION_URL;
      let queryStringParams = [];

      Object.keys(searchObject).forEach(k => {
        if (searchObject[k] != undefined || searchObject[k] != null) {
          queryStringParams.push(`${k}=${searchObject[k]}`);
        }
      });

      url += queryStringParams.join("&");

      console.log("getCustomersBasedOnLocationUrl: " + url);

      const options = this.axiosOptions()
        .asGET()
        .withJSONHeaders(token)
        .withURL(url)
        .build();

      const response = await axios(options);

      var responseData = response.data;

      dispatch(getCustomersBySearchCriteriaSuccessful(responseData));
      dispatch(completeEvent("getCustomersBySearchCriteria"));
    } catch (error) {
      console.log("error: ", error);
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
      dispatch(failEvent("getCustomersBySearchCriteria"));
    }
  }

  async getCustomersByBrand(franchiseIds, lat, lng, dispatch) {
    dispatch(startEvent("getCustomersByBrand")); //same archetype as getCustomersBasedOnLocation
    const token = await localStorage.getItem("token");
    try {
      let franchiseCSV = franchiseIds.join(",");
      let startDate = moment().format("MM/DD/YYYY");
      let endDate = moment(startDate)
        .add(7, "days")
        .format("MM/DD/YYYY");

      let url = JOBS_BY_FRANCHISE_URL.replace("{franchiseIds}", franchiseCSV);
      //url = url.replace("{lat}", lat);
      //url = url.replace("{lng}", lng);
      url = url.replace("{startDate}", startDate);
      url = url.replace("{endDate}", endDate);
      //url = url.replace("{maxDistance}", 50000);
      url = global.uspBaseUrl + url;

      const options = this.axiosOptions()
        .asGET()
        .withJSONHeaders(token)
        .withURL(url)
        .build();

      const response = await axios(options);

      var responseData = response.data;

      dispatch(getCustomersByBrandSuccessful(responseData));
      dispatch(completeEvent("getCustomersByBrand"));
    } catch (error) {
      console.log("error: ", error);
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
      dispatch(failEvent("getCustomersByBrand"));
    }
  }

  async getServiceTypeProblems(serviceSkuId, dispatch) {
    dispatch(startEvent("getServiceTypeProblems"));
    const token = await localStorage.getItem("token");
    try {
      let url = global.serviceOrchUrl + SMARTWARE_SERVICE_TYPE_PROBLEMS_URL;
      url = url.replace("{serviceSkuId}", serviceSkuId);

      const options = this.axiosOptions()
        .asGET()
        .withJSONHeaders(token)
        .withURL(url)
        .build();

      const response = await axios(options);

      var responseData = response.data;

      dispatch(completeEvent("getServiceTypeProblems"));
      dispatch(getServiceTypeProblemsSuccessful(serviceSkuId, responseData));
    } catch (error) {
      console.log("error: ", error);
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
      dispatch(failEvent("getServiceTypeProblems"));
    }
  }

  async getTechAvailability(
    { zipCode, serviceSkuId, dateStart, licenseNumber, isEstimate,NumberOfDays, webLocationId },
    dispatch
  ) {
    const token = await localStorage.getItem("token");
    dispatch(startEvent("getTechAvailability"));
    try {
      let url = global.uspBaseUrl + TECH_AVAILABILITY_URL;
      url = url.replace("{zipCode}", zipCode);
      url = url.replace("{serviceSkuId}", serviceSkuId);
      url = url.replace("{dateStart}", dateStart);
      url = url.replace("{licenseNumber}", licenseNumber);
      url = url.replace("{NumberOfDays}", NumberOfDays);
      url = url.replace("{webLocationId}", webLocationId);
      url = url.replace("{isEstimate}", isEstimate ? "true" : "false");

      const options = this.axiosOptions()
        .asGET()
        .withJSONHeaders(token)
        .withURL(url)
        .build();

      const response = await axios(options);

      var responseData = response.data;

      dispatch(completeEvent("getTechAvailability"));
      dispatch(getTechAvailabilitySuccessful(responseData));
    } catch (error) {
      console.log("error: ", error);
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
      dispatch(failEvent("getTechAvailability"));
      dispatch(getTechAvailabilityFailed(error));
    }
  }

  async bookLead(leadBookObj, dispatch) {
    dispatch(startEvent("bookLead"));
    const token = await localStorage.getItem("token");
    try {

      const options = this.axiosOptions()
        .asPOST()
        .withJSONHeaders(token)
        .withURL(global.uspBaseUrl + BOOK_LEAD_URL)
        .withData(leadBookObj)
        .build();

      const response = await axios(options);

      /* var responseData = response.data; */

      dispatch(completeEvent("bookLead"));
      dispatch(bookLeadSuccessful());
    } catch (error) {
      console.log("bookLead error: ", error);
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
      dispatch(failEvent("bookLead"));
      dispatch(
        queueMessage(
          "Error",
          "A network error has occurred, unable to book the lead at this time."
        )
      );
    }
  }

  async getNearbySingularFranchise(fullAddress, conceptCode, dispatch) {
    dispatch(startEvent("getNearbySingularFranchise"));
    const token = await localStorage.getItem("token");
    try {

      let url = global.franchiseGroupUrl + NEARBY_SINGULAR_FRANCHISE;
      url = url.replace("{conceptCode}", conceptCode);
      const data = {
        "searchType": 1,
        "searchText": fullAddress,
        "isRoundRobin": false,
      }
      const options = this.axiosOptions()
        .asPOST()
        .withJSONHeaders(token)
        .withURL(url)
        .withData(data)
        .build();

      const response = await axios(options);

      var responseData = response.data;

      dispatch(completeEvent("getNearbySingularFranchise"));
      dispatch(getNearbySingularFranchiseSuccessful(responseData));
    } catch (error) {
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
      dispatch(failEvent("getNearbySingularFranchise"));
    }
  }

  async getFeesForFranchise(franchiseWebLocationId, dispatch) {
    dispatch(startEvent("getFeesForFranchise"));
    const token = await localStorage.getItem("token");
    try {
      let url = global.franchiseGroupUrl + FRANCHISE_FEES_URL;
      url = url.replace("{franchiseWebLocationId}", franchiseWebLocationId);

      const options = this.axiosOptions()
        .asGET()
        .withJSONHeaders(token)
        .withURL(url)
        .build();

      const response = await axios(options);

      var responseData = response.data;

      console.log("TCL: getFeesForFranchise -> options", options);
      console.log("TCL: getFeesForFranchise -> response.data", response.data);

      dispatch(completeEvent("getFeesForFranchise"));
      dispatch(getFeesForFranchiseSuccess(responseData));
    } catch (error) {
      console.log("error: ", error);
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
      dispatch(failEvent("getFeesForFranchise"));
    }
  }
}

export default new SchedulingService();
