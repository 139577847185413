import CLEOBookingValidation from "./CLEOBookingValidation";
import CoreBookingValidation from "./CoreBookingValidation";
import ServiceTitanBookingValidation from "./ServiceTitanBookingValidation";
import SmartwareBookingValidation from "./SmartwareBookingValidation";
import GenericBookingValidation from "./GenericBookingValidation";
import RPMBookingValidation from "./RPMBookingValidation";
import ISNBookingValidation from "./ISNBookingValidation";

const smartwareConceptCodes = ["MRA"];
const coreConceptCodes = ["FSP"];
const CLEOConceptCodes = ["MLY"];
const serviceTitanConceptCodes = ["ASV", "MHM", "MRE", "MRR"];
const realPropertyManagementCodes = ["RPM"];
const ISNConceptCodes = ["HMS"]

export default function DetermineBookingService(conceptCode, selectedCompany, serviceQuestionAnswers, customerInfo, scheduledDateTime, additionalInfo, userId) {
  let bookingValidator;
  if (smartwareConceptCodes.indexOf(conceptCode) > -1) {
    bookingValidator = new SmartwareBookingValidation(selectedCompany, serviceQuestionAnswers, customerInfo, scheduledDateTime, additionalInfo, userId);
  } else if (coreConceptCodes.indexOf(conceptCode) > -1) {
    bookingValidator = new CoreBookingValidation(selectedCompany, serviceQuestionAnswers, customerInfo, scheduledDateTime, additionalInfo, userId);
  } else if (CLEOConceptCodes.indexOf(conceptCode) > -1) {
    bookingValidator = new CLEOBookingValidation(selectedCompany, serviceQuestionAnswers, customerInfo, scheduledDateTime, additionalInfo, userId);
  } else if(serviceTitanConceptCodes.indexOf(conceptCode) > -1) {
    bookingValidator = new ServiceTitanBookingValidation(selectedCompany, serviceQuestionAnswers, customerInfo, scheduledDateTime, additionalInfo, userId);
  }else if(realPropertyManagementCodes.indexOf(conceptCode) > -1) {
    bookingValidator = new RPMBookingValidation(selectedCompany, serviceQuestionAnswers, customerInfo, scheduledDateTime, additionalInfo, userId);
  }else if (ISNConceptCodes.indexOf(conceptCode) > -1) {
  bookingValidator = new ISNBookingValidation(selectedCompany, serviceQuestionAnswers, customerInfo, scheduledDateTime, additionalInfo, userId);
} else {
    bookingValidator = new GenericBookingValidation(selectedCompany, serviceQuestionAnswers, customerInfo, scheduledDateTime, additionalInfo, userId);
  }
  return bookingValidator.buildBookingPayload();
}