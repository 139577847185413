import React, {Component} from "react";
import {connect} from "react-redux";
import {
  getFranchiseServicesInfo,
  saveServiceQuestions
} from "../../actions";
import {AvForm, AvField, AvRadioGroup, AvRadio} from "availity-reactstrap-validation";
import {FormGroup} from "reactstrap";
import {EventStatuses} from "../../system";
import Loader from "react-loader-spinner";

class ProTectPaintersServiceQuestions extends Component {  
  submitServiceQuestions = (event, values) => {
    let {serviceSelection, applianceUnderWarranty} = values;
    let data = {
      serviceSelection,
      applianceUnderWarranty
    };
    this.props.saveServiceQuestions(data);
  }

  render() {
    return (
      <div style={{width: "100%", flexDirection: "column"}}>
        <AvForm onValidSubmit={this.submitServiceQuestions} id="PTP-service-question-form">
          <div style={{width: "100%", marginTop: 30, marginBottom: 30, fontSize: 28, flexDirection: "row", fontFamily: "Montserrat SemiBold"}}>
            How Can We Help You?
            {
              this.props.eventStatuses["getFranchiseServicesInfo"] === EventStatuses.PENDING &&
              <div style={{marginLeft: 5, marginRight: 5, alignItems: "center", flexDirection: "center"}}>
                <Loader
                  type="Oval"
                  color="#a5a9af"
                  height="20"
                  width="20"
                />
              </div>
            }
          </div>
          <FormGroup row
            style={{marginLeft: 0, marginRight: 0}}
          >
            <AvField
              disabled={this.props.eventStatuses["getFranchiseServicesInfo"] === EventStatuses.PENDING}
              name="serviceSelection"
              type="select"
              placeholder="Select Your Service"
              required
            >
              <option value="">--</option>
              {
                this.props.franchiseServices &&
                this.props.franchiseServices.map((fs, k) => {
                  return <option value={fs.serviceSkuId} key={k}>{fs.serviceSkuName}</option>;
                })
              }
            </AvField>
          </FormGroup>
          {this.props.canDisplayWarranty && <>
          <div style={{width: "100%", marginTop: 30, marginBottom: 30, fontSize: 28, fontFamily: "Montserrat SemiBold"}}>
            Is This Warranty Work?
          </div>
          <FormGroup row
            style={{marginLeft: 0, marginRight: 0}}
          >
            <AvRadioGroup
              name="applianceUnderWarranty"
              type="select"
              required
            >
              <AvRadio label="Yes" value="yes"/>
              <AvRadio label="No" value="no"/>
            </AvRadioGroup>
          </FormGroup>
          </>}                    
        </AvForm>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    brands,
    selectedCompany,
    serviceTypeProblemsHashMap,
    franchiseServices
  } = state.schedulingReducer;
  const {eventStatuses} = state.systemReducer;
  return {
    ...state,
    brands,
    selectedCompany,
    serviceTypeProblemsHashMap,
    franchiseServices,
    eventStatuses
  };
}

export default connect(mapStateToProps, {
  getFranchiseServicesInfo,
  saveServiceQuestions
}, null, {forwardRef: true})(ProTectPaintersServiceQuestions);