import React, { Component } from 'react';
import { Input } from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
class LandingPageHeader extends Component {
	constructor(props) {
		super(props);

		this.state = {
			zipCode: this.props.zipCode,
			isValid:false
		};
		this.lastValidZipCode = null;
	}

	keyEnterHandler = (e) => {
		let { zipCode } = this.state;
		if(e.target.value.length > 3){
			  if(e.keyCode === 13){
				this.props.setActiveZipCode(zipCode);	 
		}
	}
}

	render() {
		return (
			<div
				onClick={() => {
					this.props.alterDropdownState(false);
				}}
				style={{ width: '100%', height: '100%' }}
			>
				<img
					alt=""
					style={{
						objectFit: 'cover',
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%'
					}}
					src={require('../../Assets/landingPageBg.png')}
				/>
				<div
					style={{
						backgroundImage: 'linear-gradient(rgb(38, 125, 191), rgba(38, 125, 191, 0.2))',
						width: '100%',
						height: '100%',
						maxHeight: 300,
						alignItems: 'center',
						flexDirection: 'column',
						zIndex: 2
					}}
				>
					<div style={{ flexDirection: 'row', width: '100%', height: 70 }}>
						<div style={{ flex: 1 }} />
						<div style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
							<img
								alt=""
								style={{ objectFit: 'contain', width: '100%', maxWidth: 150 }}
								src={require('../../Assets/neighborlyBaseLogo.png')}
							/>
						</div>
						<div style={{ flex: 0.85, justifyContent: 'flex-end', alignItems: 'center', color: 'white' }}>
							<div style={{ marginRight: 25, textAlign: "right" }}>{this.props.userName}</div>
						</div>
						<div style={{ justifyContent: 'flex-end', alignItems: 'center', color: 'white' }}>
							<div onClick={this.props.userLogout}  style={{ marginRight: 10, textAlign: "right",cursor:"pointer" }}>Logout</div>
						</div>
					</div>
					<div
						style={{
							width: '75%',
							alignItems: 'center',
							justifyContent: 'center',
							flex: 3,
							flexDirection: 'column'
						}}
					>
						<div
							className="neighborHeaderTextBig"
							style={{ color: 'white', fontFamily: 'Montserrat Bold' }}
						>
							Hi, {this.props.firstName}
						</div>
						<div
							className="neighborHeaderTextSmall"
							style={{ color: 'white', fontFamily: 'Montserrat SemiBold' }}
						>
							Find and hire trusted home service professionals
						</div>
						<div
							className="neighborHeaderTextSmall"
							style={{ color: 'white', fontFamily: 'Montserrat SemiBold' }}
						>
							with ease from the Neighborly family of trusted brands
						</div>

						<div
							style={{
								borderStyle: 'solid',
								borderWidth: 1,
								borderColor: 'rgb(73, 80, 87)',
								width: 250,
								height: 40,
								borderRadius: 14,
								marginTop: 25,
								marginBottom: 25,
								justifyContent: 'center',
								alignItems: 'center',
								color: 'white',
								backgroundColor:'#fff'
							}}
						>
							<Input
								// onFocus={this.props.goToLocationPage}
								className="neighborHeaderTextLarge zipcodeInput verticalLine"
								style={{
									backgroundColor: 'transparent',
									borderColor: 'transparent',
									borderWidth: 0,
									color: '#495057',
									fontWeight: 'bold',
									textAlign: 'center',
									paddingLeft: 10,
									paddingRight: 10
								}}
								placeholder={(this.props.locationMethod == 'gps' && !this.props.isZipcodeEntered) ? 'Current Location' : "Enter Zipcode / Address"}
								// maxLength={5}
								value={this.state.zipCode}
								onChange={(e) => {
									this.setState({ zipCode: e.target.value });
									this.props.handleZipcodeToggle(true)
								}}
								onKeyDown={this.keyEnterHandler}
							/>
							<Tooltip title="Current Location">
							<img
								alt=""
								// src={require('../../Assets/triangle.png')}
								src={require('../../Assets/noun_location.svg')}
								style={{
									width: 20,
									height: 20,
									marginLeft: 5,
									marginRight: 15,
									cursor: 'pointer',
									paddingLeft: '4px'
								}}
								onClick={() => {
									this.props.getLocation();
									this.setState({zipCode : ''})
								}}
							/>
      					</Tooltip>
							
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default LandingPageHeader;
