import React, {Component} from "react";

class RoundButton extends Component {
  render() {
    return (
      <button
        {...this.props}
        disabled={this.props.disabled}

        style={{
          ...this.props.buttonstyle,
          borderRadius: 50,
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{width: "100%", height: "100%", justifyContent: "center", alignItems: "center", ...this.props.textstyle}}>
        {
          this.props.text
        }
        </div>
      </button>
    );
  }
}

export default RoundButton;