import React, { Component } from "react";
import { connect } from "react-redux";
import { login, loginFailureAcknowledged, acknowledgeEvent, getLoggedInUserInformation, sendForgotPasswordEmail, clearSentForgotPasswordEmail } from "../actions/index";
import RoundButton from "../Components/RoundButton";
import { AvForm, AvField,AvInput,AvFeedback,AvGroup } from "availity-reactstrap-validation";
import { Col, FormGroup } from "reactstrap";
import { Redirect } from "react-router-dom";
import Alert from "../Components/Alert";
import { EventStatuses } from "../system";
import Loader from "react-loader-spinner";
import { Modal } from "reactstrap";
import SquareButton from "../Components/SquareButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import PasswordExpiry from './CheckPasswordExpiry'
import PasswordModal from '../Components/passwordModal'

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partialView: "default",
      modal: false,
      checkExpiry : false,
      email : ''
    };
    this.forgotPasswordMessageDisplayed = false;
  }
  componentDidMount() {
    window.addEventListener("resize", () => {
      this.forceUpdate();
    });
    // this.login(null, { email: "test@nbly.com", password: "Password1" }); //remove for release
  }

  componentWillUpdate() {
    if(this.props.eventStatuses['sendForgotPasswordEmail'] === EventStatuses.COMPLETED && !this.forgotPasswordMessageDisplayed && this.state.modal) {
      this.setState({ modal: false })
      this.forgotPasswordMessageDisplayed = true;
      setTimeout(() => {
        this.loginErrorModal.showWithMessage("SUCCESS", "Successfully sent forgot password email.")
      }, 500)
    }

    if(this.props.eventStatuses['sendForgotPasswordEmail'] === EventStatuses.FAILED && !this.forgotPasswordMessageDisplayed && this.state.modal) {
      this.setState({ modal: false })
      this.forgotPasswordMessageDisplayed = true;
      setTimeout(() => {
        this.loginErrorModal.showWithMessage("FAILED", "Unable to send password reset email. Please try again later or with a different account.")
      }, 500)
    }
  }

  login = (event, values) => {
    this.props.login(values.email, values.password);
    let {email} = values
    localStorage.setItem('email',email)
  };


  renderDefaultPartial = () => {
    return (
      <div
        style={{
          flex: 3,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          zIndex: 3
        }}
      >
        <RoundButton
          onClick={() => {
            this.setState({ partialView: "login" });
          }}
          buttonstyle={{
            marginBottom: 30,
            backgroundColor: "white",
            width: "45%",
            height: 40,
            borderWidth: 2,
            borderColor: "white",
            maxWidth: 400
          }}
          textstyle={{
            color: "rgb(38, 125, 191)",
            fontFamily: "Montserrat ExtraBold"
          }}
          text="SIGN IN"
          id="signInBtn"
          className={`neighborHeaderTextLarge boxShadow`}
        />
      </div>
    );
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  open = () => {
    this.props.clearSentForgotPasswordEmail();
    this.toggle();
  }

  renderLoginPartial = () => {
    let expiryObj = {
      expirationDay : null,
  }
    return (
      <div
      className="login-layout"
        style={{
          flex: 3,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          zIndex: 3
        }}
      >
        <AvForm onValidSubmit={this.login} id="auth-form" style={{ width: "100%" }}>
        {!this.props.userLoggedIn && this.props.loginFailureMessage === "Password has expired." &&
             <PasswordModal {...this.props} numOfExpirationDays={expiryObj}/>
          }
          {this.props.loginFailureMessage !==null && this.props.loginFailureMessage !== "Password has expired." &&
            <div>
              <p style={{color:"#dc3545"}}><FontAwesomeIcon icon={faExclamationCircle} style={{marginRight:"5px"}}/> Username and/or password are incorrect.If you have forgotten your password,the "Forgot Password" link can be used to restore access.</p>
            </div>
          }
          <AvGroup row style={{ height: 50, alignItems: "center", justifyContent: "center" }}>
            <AvInput name="email" type="email" placeholder="Username" required className={`boxShadow focus-input`}/>
            <AvFeedback><FontAwesomeIcon icon={faExclamationCircle} style={{marginRight:"5px"}}/>Please enter Username</AvFeedback>
          </AvGroup>
          <AvGroup row style={{ height: 50, alignItems: "center", justifyContent: "center" }}>
            <AvInput style={{ width: "100%" }} name="password" type="password" required placeholder="Password" className={`boxShadow focus-input`} />
            <AvFeedback><FontAwesomeIcon icon={faExclamationCircle} style={{marginRight:"5px"}}/>Please enter Password</AvFeedback>
          </AvGroup>
        </AvForm>
        <RoundButton
          form="auth-form"
          buttonstyle={{
            marginTop: 20,
            marginBottom: 18,
            backgroundColor: "#267dbf",
            width: "100%",
            height: 40,
            maxWidth: 400
          }}
          onClick={() => {
            this.setState({ checkExpiry : true });
          }}
          textstyle={{
            color: "white",
            fontFamily: "Montserrat ExtraBold"
          }}
          text="LOGIN"
          className={`neighborHeaderTextLarge boxShadow`}
        />
        <span className="neighborHeaderTextSmall" style={{ fontFamily: "Montserrat SemiBold", color: "#606060" }}>
          FORGOT YOUR PASSWORD:{" "}
          <span onClick={this.open.bind(this)} style={{ textDecorationLine: "underline", cursor: "pointer", color: "#267dbf" }}>
            RESET HERE
          </span>
        </span>
      </div>
    );
  };

  onSendPasswordResetEmail = (event, values) => {
    this.forgotPasswordMessageDisplayed = false;
    this.props.sendForgotPasswordEmail(values.email);
  };

  render() {
    let width = window.innerWidth > 1000 ? 1000 : window.innerWidth;
    let height = window.innerHeight;
    return (
      <div
        style={{
          flex: 1,
          backgroundImage: "linear-gradient(rgb(38, 125, 191), rgb(38, 125, 191), rgb(255, 255, 255))",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Modal isOpen={this.state.modal} toggle={this.toggle} centered style={{ borderRadius: 0 }}>
          <div style={{ width: "100%", flexDirection: "column" }}>
            <div style={{ width: "100%", flexDirection: "row" }}>
              <div
                style={{
                  width: "100%",
                  padding: 10,
                  fontWeight: "bold",
                  fontFamily: global.themeFontExtraBold
                }}
                className="neighborHeaderTextLarge"
              >
                FORGOT PASSWORD?
              </div>
              <div
                onClick={this.toggle}
                style={{ width: 50, alignItems: "center", justifyContent: "center", cursor: "pointer" }}
              >
                <FontAwesomeIcon icon="times" />
              </div>
            </div>
            <div style={{ width: "100%", marginTop: 10, marginBottom: 10, flexDirection: "row" }}>
              <Col md="12">
              <AvForm onValidSubmit={this.onSendPasswordResetEmail.bind(this)} id="forgot-password" style={{ width: "100%" }}>
                <FormGroup row style={{ height: 50,margin:0, alignItems: "center", justifyContent: "center" }}>
                  <AvField name="email" type="email" required placeholder="Enter Email address" className={`boxShadow focus-input`}/>
                </FormGroup>
            </AvForm>
              </Col>
            </div>
            <div style={{ width: "100%", flexDirection: "row" }}>
              <SquareButton
                className="neighborHeaderTextSmall"
                onClick={this.toggle}
                text="CANCEL"
                buttonstyle={{
                  height: 40,
                  flex: 2,
                  backgroundColor: "white",
                  margin: 10,
                  border: "none"
                }}
                textstyle={{ fontFamily: global.themeFontSemiBold, color: "rgb(38, 125, 191)"}}
              />
              <SquareButton
              className="neighborHeaderTextSmall"
                form="forgot-password"
                text={this.state.confirmText || "OK"}
                buttonstyle={{ height: 40, flex: 1,border: "none",borderRadius: 50,
                backgroundColor: "rgb(38, 125, 191)", margin: 10 }}
                textstyle={{ fontFamily: global.themeFontSemiBold, color: "white"}}
              />
            </div>
          </div>
        </Modal>
        <div
          style={{
            position: "relative",
            height: "100%",
            width: "100%",
            maxWidth: 1000,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <img
            alt=""
            style={{
              width: width * 0.198,
              height: height * 0.324,
              position: "absolute",
              zIndex: 3,
              right: 0,
              top: height * 0.439
            }}
            src={require("../Assets/service-tools.png")}
          />
          <img
            alt=""
            style={{
              maxWidth: 1000,
              width: width * 0.957,
              height: height * 0.284,
              position: "absolute",
              zIndex: 3,
              bottom: height * -0.019,
              right: 0
            }}
            src={require("../Assets/drill-screwdriver.png")}
          />
          {this.props.userLoggedIn && <PasswordExpiry/>}
          <Alert
            ref={r => {
              this.loginErrorModal = r;
            }}
          />
          <div
            style={{ flex: 3, width: "100%", alignItems: "center", justifyContent: "center", flexDirection: "column" }}
          >
            <img
              alt=""
              style={{
                width: "80%",
                resizeMode: "contain",
                maxWidth: 450
              }}
              src={require("../Assets/neighborlyBaseLogo_Large.png")}
            />
            <div
            className="neighborHeaderTextSmall"
              style={{
                marginTop: 15,
                color: "white",
                textAlign: "center",
                paddingLeft: "10%",
                paddingRight: "10%",
                maxWidth: 1000,
                fontFamily: "Montserrat SemiBold"
              }}
            >
              Find and hire locally-owned home services experts whose work is trusted by homeowners and business owners
              in your neighborhood.
            </div>
            <div
            className="neighborHeaderTextLarge"
              style={{
                color: "white",
                textAlign: "center",
                fontWeight: "bold",
                fontFamily: "Montserrat ExtraBold"
              }}
            >
              It's easy with Neighborly.
            </div>
          </div>
          {this.props.eventStatuses["login"] !== EventStatuses.PENDING &&
            this.state.partialView === "default" &&
            this.renderDefaultPartial()}
          {this.props.eventStatuses["login"] !== EventStatuses.PENDING &&
            this.state.partialView === "login" &&
            this.renderLoginPartial()}
          {this.props.eventStatuses["login"] === EventStatuses.PENDING && (
            <div
              style={{
                flex: 3,
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                maxWidth: 400,
                zIndex: 3
              }}
            >
              <Loader type="Oval" color="white" height="50" width="50" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { userLoggedIn, loginFailure, loginFailureMessage,passwordExpiryDate } = state.loginReducer;
  const { eventStatuses } = state.systemReducer;
  return {
    ...state,
    userLoggedIn,
    loginFailure,
    loginFailureMessage,
    eventStatuses,
    passwordExpiryDate
  };
}

export default connect(
  mapStateToProps,
  {
    login,
    loginFailureAcknowledged,
    acknowledgeEvent,
    getLoggedInUserInformation,
    sendForgotPasswordEmail,
    clearSentForgotPasswordEmail
  }
)(Login);
