import React, { Component } from "react";
import { resetData, getFeesForFranchise } from "../actions/index";
import { connect } from "react-redux";
import LoggedInContainerHeader from "../Components/LoggedInContainer/LoggedInContainerHeader";
import { EventStatuses } from "../system";
import Loader from "react-loader-spinner";
import SquareButton from "../Components/SquareButton";
import { franchiseFeesDescriptions } from "../constants";

class FeePage extends Component {
  componentDidMount() {
    if (this.props.selectedCompany && this.props.selectedCompany.franchiseWebLocationId) {
      this.props.getFeesForFranchise(this.props.selectedCompany.franchiseWebLocationId);
    }
  }

  proceedToNextScreen() {
    let { conceptId } = this.props.selectedCompany;
    let { conceptCode } = this.props.companiesHashMap[conceptId];

    let { applianceUnderWarranty } = this.props.serviceQuestionAnswers;

    let route = "";

    if (applianceUnderWarranty === "yes" && conceptCode === "MRA") {
      route = "/additionalinfo";
    } else if (applianceUnderWarranty === "no" && conceptCode === "MRA") {
      route = "/calendar";
    } else {
      route = "/hasscheduleselect";
    }

    this.props.history.push(route);
  }

  didPressNextButton() {
    this.proceedToNextScreen();
  }

  render() {
    if(this.props.selectedCompany){
      let { conceptId } = this.props.selectedCompany;
      var franchiseFeesDetails = franchiseFeesDescriptions.filter(x=> x.ConceptId === conceptId)[0];
      var defaultFranchiseFeesDetails = franchiseFeesDescriptions.filter(x=> x.ConceptId === 0)[0];      // get default fees description  
    }
    return (
      <div style={styles.pageContainer}>
        <LoggedInContainerHeader
          history={this.props.history}
          resetData={this.props.resetData}
          showCancelButton={true}
        />

        <div className={`feePageMargin`} style={styles.mainContentContainer}>
          <div className={`neighborHeaderTextBig`} style={styles.title}>Franchise Fees</div>

          <div className={`neighborHeaderTextSmall`} style={styles.boldContentText}>
            <p className="franchiseNameFormat">{this.props.customerInfoTemp && this.props.customerInfoTemp.FirstName || "Customer"}</p>, {franchiseFeesDetails && franchiseFeesDetails.FranchiseFeesDescription && franchiseFeesDetails.FranchiseFeesDescription.trim().length > 0 ? (franchiseFeesDetails && franchiseFeesDetails.FranchiseFeesDescription) : (defaultFranchiseFeesDetails && defaultFranchiseFeesDetails.FranchiseFeesDescription)}.
          </div>

          <div className={`neighborHeaderTextLarge`} style={styles.pricingObjection}>Pricing Objection Responses</div>

          <div className={`neighborHeaderTextSmall`} style={styles.subtext}>
            • {franchiseFeesDetails && franchiseFeesDetails.PricingObjectionResponse1 && franchiseFeesDetails.PricingObjectionResponse1.trim().length > 0 ? (franchiseFeesDetails && franchiseFeesDetails.PricingObjectionResponse1) : (defaultFranchiseFeesDetails && defaultFranchiseFeesDetails.PricingObjectionResponse1)}.
          </div>

          <div className={`neighborHeaderTextSmall`} style={styles.subtext}>
            • {franchiseFeesDetails && franchiseFeesDetails.PricingObjectionResponse2 && franchiseFeesDetails.PricingObjectionResponse2.trim().length > 0 ? (franchiseFeesDetails && franchiseFeesDetails.PricingObjectionResponse2) : (defaultFranchiseFeesDetails && defaultFranchiseFeesDetails.PricingObjectionResponse2)}.
          </div>

          <hr style={styles.hr} />

          <table style={styles.fullWidth}>
            <thead>
              <tr>
                <th className={`neighborHeaderTextLarge`} style={styles.tableHeader}>Fee Description</th>
                <th className={`neighborHeaderTextLarge`} style={styles.tableHeader}>Fee Cost</th>
              </tr>
            </thead>
            <tbody className={`neighborHeaderTextSmall`}>
              {this.props.currentSelectedFranchiseFees &&
                this.props.currentSelectedFranchiseFees.map((fee, index) => {
                  return (
                    <tr key={index}>
                      <td style={styles.tableBodyBold}>{fee.name}</td>
                      <td style={styles.tableBody}>${fee.defaultFee.toFixed(2)}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <div style={styles.loaderContentContainer}>
          {this.props.eventStatuses["getFeesForFranchise"] === EventStatuses.PENDING && (
            <div style={styles.loaderContainer}>
              <Loader type="Oval" color="#a5a9af" height="50" width="50" />
            </div>
          )}
          <SquareButton
            className="boxShadow" 
            onClick={this.didPressNextButton.bind(this)}
            text="NEXT"
            buttonstyle={styles.buttonstyle}
            textstyle={{ fontFamily: "Montserrat SemiBold", color: "white", fontSize: 16 }}
          />
        </div>
      </div>
    );
  }
}

const styles = {
  tableHeader: {
    fontFamily: "Montserrat SemiBold",
  },
  tableBody: {
    fontFamily: "Montserrat Regular"
  },
  tableBodyBold: {
    fontFamily: "Montserrat Regular",
    fontWeight: "bold"
  },
  hr: {
    minHeight: 1,
    width: "100%"
  },
  fullWidth: {
    width: "100%"
  },
  subtext: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 30,
    color: "gray",
    flexDirection: "row",
    fontFamily: "Montserrat SemiBold"
  },
  loaderContentContainer: {
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 800,
    width: "100%",
    display: "block",
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 75,
    marginBottom: 30
  },
  loaderContainer: {
    width: "100%",
    height: "100%",
    minHeight: 200,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  buttonstyle: {
    height: 50,
    width: "100%",
    backgroundColor: "rgb(38, 125, 191)",
    marginTop: 10
  },
  pricingObjection: {
    marginTop: 15,
    marginBottom: 15,
    flexDirection: "row",
    color: "green",
    fontFamily: "Montserrat SemiBold",
    textDecoration: "underline"
  },
  boldContentText: {
    marginTop: 10,
    marginBottom: 10,
    flexDirection: "row",
    fontFamily: "Montserrat SemiBold",
    display:"block"
  },
  title: {
    marginBottom: 20,
    flexDirection: "row",
    fontFamily: "Montserrat SemiBold"
  },
  mainContentContainer: {
    justifyContent: "center",
    marginTop: 75,
    flexDirection: "column",
    display: "flex",
    maxWidth: 800
  },
  pageContainer: { width: "100%", flexDirection: "column", alignItems: "center" }
};

function mapStateToProps(state) {
  const {
    selectedCompany,
    currentSelectedFranchiseFees,
    serviceQuestionAnswers,
    companiesHashMap,
    customerInfoTemp
  } = state.schedulingReducer;
  const { eventStatuses } = state.systemReducer;
  const { firstName } = state.loginReducer;

  return {
    ...state,
    selectedCompany,
    currentSelectedFranchiseFees,
    firstName,
    serviceQuestionAnswers,
    companiesHashMap,
    customerInfoTemp,
    eventStatuses
  };
}

export default connect(
  mapStateToProps,
  {
    resetData,
    getFeesForFranchise
  }
)(FeePage);
