import React, { Component } from 'react';
import TruncateMarkup from 'react-truncate-markup';
import SquareButton from '../SquareButton';
import LandingPageServiceRatingCircle from './LandingPageServiceRatingCircle';
import ReactTooltip from 'react-tooltip';

class LandingPageServiceRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rowExpansionToggled: false,
			didTruncate: false
		};
	}

	render() {
		let { franchiseInfo } = this.props;
		return (
			<div className="card-shadow card-responsive" style={{ flexDirection: 'column', margin: 15 }}>
				<div style={{flex:1, flexDirection: "column"}}>
					<div style={{ flex: 1, justifyContent: 'center', flexDirection: 'row', backgroundColor: "#267cbe" }}>
						<div
							style={{
								textAlign: 'center',
								color: "white",
								padding: "5px 12px 5px 12px",
								cursor: 'pointer',
								fontFamily: 'Montserrat Regular'
							}}

						>
							<TruncateMarkup lines={1}>
								<div>
									{franchiseInfo && franchiseInfo.doingBusinessAs}
								</div>
							</TruncateMarkup>
						</div>
					</div>
					<div style={{ padding: "5px 15px 0px 15px", flexDirection: "column", flex: 1 }}>
						<div
							className="landing-page-service-row"
						>
							<div
								style={{
									flex: 2,
									justifyContent: 'flex-start',
									alignItems: 'center',
									paddingRight: 20,
									flexDirection: 'column'
								}}
							>
								<div
									style={{
										cursor: 'pointer',
										justifyContent: 'center',
										alignItems: 'center',
										width: '100%',
										height: 96
									}}
									onClick={() => {
										this.props.onSelect(franchiseInfo);
									}}
								>
									{franchiseInfo && (
										<img
											alt=""
											style={{ height: '100%', width: '100%', objectFit: 'contain' }}
											src={`${global.blobBaseUrl}/assets/${franchiseInfo.conceptId}.png`}
										/>
									)}
								</div>
							</div>
							<div
								style={{
									flex: 3,
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'flex-start',
									marginHorizontal: 10
								}}
							>
								<div
									style={{
										color: 'rgb(38, 125, 191)',
										fontWeight: 'bold',
										fontSize: 16,
										width: '100%',
										flexDirection: 'column'
									}}
								>
								</div>
								<div
									className="neighborHeaderTextSmall"
									style={{
										color: 'gray',
										fontFamily: 'Montserrat Light',
										flexDirection: 'column'
									}}
								>
									{/*
              need to use this library instead of regular Truncate because 
              this one doesn't endlessly call its onTruncate callback
            */}
									{
										<TruncateMarkup
											lines={this.state.rowExpansionToggled ? 30 : 4}
											ellipsis={'...'}
											onTruncate={(didTruncate) => {
												this.setState({ didTruncate });
											}}
										>
											<div>
												{this.props.services &&
													(this.props.services
														.map((fs) => fs.serviceName)
														.join(', ') ||
														'Unable to load services for this brand')}
											</div>
										</TruncateMarkup>
									}
										<a style={{fontWeight: "900", color:"#007bff", cursor: "pointer"}} onClick={() => {
					this.props.onSelect(franchiseInfo);
				}}>Read More</a>
								</div>
							</div>
						</div>
						</div>
					</div>
					<div style={{ flex: 2,padding:"5px 15px 15px", justifyContent: 'center', flexDirection: 'row' }}>
							<div
								style={{
									justifyContent: 'center',
									flexDirection: 'column',
									flex: 1
								}}
							>
								{(this.props.franchiseInfo.netPromoterStarRating >= 3.5) ?
									(<LandingPageServiceRatingCircle
										size={60}
										rating={franchiseInfo ? franchiseInfo.netPromoterStarRating : 0}
									/>) :
									(<div
										style={{
											flex: 3,
											flexDirection: "column",
											justifyContent: "center"
										}}
									></div>)
								}
							</div>
							{franchiseInfo &&
								franchiseInfo.IsPartial && (
									<div style={{ alignItems: "center", flex: 1, marginRight: 5 }}>
										<div data-tip="This franchise location has partial coverage">
											<div
												style={{
													height: 18,
													width: 60,
													borderRadius: 5,
													borderWidth: 0,
													backgroundColor: '#f4c842',
													alignItems: 'center',
													justifyContent: 'center'
												}}
											>
												<div
													style={{
														backgroundColor: 'transparent',
														color: 'white',
														fontWeight: 'bold',
														paddingLeft: 5,
														paddingRight: 5,
														alignItems: 'center',
														justifyContent: 'center',
														textAlign: 'center',
														fontSize: 12
													}}
												>
													Partial
											</div>
											</div>
										</div>
										<ReactTooltip />
									</div>
								)}
							<div
								style={{
									justifyContent: 'center',
									alignItems: 'flex-end',
									flexDirection: 'column',
									flex: 1,
								}}
							>
								<SquareButton
									onClick={() => {
										this.props.onSelectAndGo(franchiseInfo);
									}}
									text="Book"
									className="boxShadow"
									buttonstyle={{
										height: 30,
										backgroundColor: 'rgb(38, 125, 191)',
										width: 90
									}}
									textstyle={{
										color: 'white',
										whiteSpace: 'nowrap',
										textAlign: 'center',
										fontWeight: 'bold',
										fontSize: 11,
										fontFamily: 'Montserrat SemiBold'
									}}
								/>
							</div>
						</div>
				</div>
		);
	}
}

export default LandingPageServiceRow;
