import React, { Component } from "react";
import { connect } from "react-redux";
import LoggedInContainerHeader from "../Components/LoggedInContainer/LoggedInContainerHeader";
import SquareButton from "../Components/SquareButton";
import { Redirect } from "react-router-dom";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import {
  resetData,
  getCustomServiceQuestions,
  saveServiceQuestions,
  acknowledgeEvent,
  getFranchiseServicesInfo,
} from "../actions/index";
import { EventStatuses } from "../system";
import Loader from "react-loader-spinner";

import AireServServiceQuestions from "../Components/ServiceDescription/AireServServiceQuestions";
import MollyMaidServiceQuestions from "../Components/ServiceDescription/MollyMaidServiceQuestions";
import MrHandymanServiceQuestions from "../Components/ServiceDescription/MrHandymanServiceQuestions";
import FiveStarPaintingServiceQuestions from "../Components/ServiceDescription/FiveStarPaintingServiceQuestions";
import GlassDoctorServiceQuestions from "../Components/ServiceDescription/GlassDoctorServiceQuestions";
import TheGroundsGuysServiceQuestions from "../Components/ServiceDescription/TheGroundsGuysServiceQuestions";
import MrApplianceServiceQuestions from "../Components/ServiceDescription/MrApplianceServiceQuestions";
import MrElectricServiceQuestions from "../Components/ServiceDescription/MrElectricServiceQuestions";
import MrRooterServiceQuestions from "../Components/ServiceDescription/MrRooterServiceQuestions";
import RainbowInternationalServiceQuestions from "../Components/ServiceDescription/RainbowInternationalServiceQuestions";
import PortlandGlassServiceQuestions from "../Components/ServiceDescription/PortlandGlassServiceQuestions";
import ProTectPaintersServiceQuestions from "../Components/ServiceDescription/ProTectPaintersServiceQuestions";
import WindowGenieServiceQuestions from "../Components/ServiceDescription/WindowGenieServiceQuestions";
import RealPropertyManagementServiceQuestions from "../Components/ServiceDescription/RealPropertyManagementServiceQuestions";
import MosquitoJoeServiceQuestions from "../Components/ServiceDescription/MosquitoJoeServiceQuestions";
import DryerVentWizardServiceQuestions from "../Components/ServiceDescription/DryerVentWizardServiceQuestions";
import ShelfGenieServiceQuestions from "../Components/ServiceDescription/ShelfGenieServiceQuestions";
import PrecisionDoorServiceQuestions from "../Components/ServiceDescription/PrecisionDoorServiceQuestions";
import HouseMasterServiceQuestions from "../Components/ServiceDescription/HouseMasterServiceQuestions";
import LawnPrideServiceQuestions from "../Components/ServiceDescription/LawnPrideServiceQuestions";
import JUKServiceQuestions from "../Components/ServiceDescription/JUKServiceQuestions";

class ServiceDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.brandSpecificQuestionComponents = {
      MLY: <MollyMaidServiceQuestions canDisplayWarranty={false} />,
      MHM: <MrHandymanServiceQuestions canDisplayWarranty={false} />,
      ASV: <AireServServiceQuestions canDisplayWarranty={false} />,
      FSP: <FiveStarPaintingServiceQuestions canDisplayWarranty={false} />,
      MDG: <GlassDoctorServiceQuestions canDisplayWarranty={false} />,
      GUY: <TheGroundsGuysServiceQuestions canDisplayWarranty={false} />,
      MRA: <MrApplianceServiceQuestions canDisplayWarranty={true} />,
      MRE: <MrElectricServiceQuestions canDisplayWarranty={false} />,
      MRR: <MrRooterServiceQuestions canDisplayWarranty={false} />,
      RBW: <RainbowInternationalServiceQuestions canDisplayWarranty={false} />,
      PLG: <PortlandGlassServiceQuestions canDisplayWarranty={false} />,
      PTP: <ProTectPaintersServiceQuestions canDisplayWarranty={false} />,
      WDG: <WindowGenieServiceQuestions canDisplayWarranty={false} />,
      RPM: (
        <RealPropertyManagementServiceQuestions canDisplayWarranty={false} />
      ),
      MOJ: <MosquitoJoeServiceQuestions canDisplayWarranty={false} />,
      DVW: <DryerVentWizardServiceQuestions canDisplayWarranty={false} />,
      SHG: <ShelfGenieServiceQuestions canDisplayWarranty={false} />,
      PDS: <PrecisionDoorServiceQuestions canDisplayWarranty={false} />,
      HMS: <HouseMasterServiceQuestions canDisplayWarranty={false} />,
      LAP: <LawnPrideServiceQuestions canDisplayWarranty={false} />,
      JUK: <JUKServiceQuestions canDisplayWarranty={false} />,
    };
  }

  componentDidMount() {
    if (this.props.selectedCompany) {
      localStorage.setItem("serviceModifierValue", "Residential");
      this.props.getFranchiseServicesInfo([this.props.selectedCompany]);
    }
  }

  componentDidUpdate() {
    if (
      this.props.eventStatuses["saveServiceQuestions"] ===
        EventStatuses.COMPLETED &&
      this.props.serviceQuestionAnswers
    ) {
      this.props.acknowledgeEvent("saveServiceQuestions");
      this.configureServiceDescriptionRouting();
    }
  }

  configureServiceDescriptionRouting = () => {
    let { conceptId } =
      this.props && this.props.selectedCompany
        ? this.props.selectedCompany
        : {};
    let { conceptCode } = conceptId
      ? this.props.companiesHashMap[conceptId]
      : {};
    if (conceptCode === "FSP" || conceptCode === "PTP") {
      this.props.history.push("/calendar");
    } else {
      this.props.history.push("/hasscheduleselect");
    }
  };

  handleServiceChange = (e) => {
    let { value } = e.target;
    localStorage.setItem("serviceModifierValue", value);
    if (this.props.selectedCompany) {
      this.props.getFranchiseServicesInfo([this.props.selectedCompany]);
    }
  };

  render() {
    let { conceptId } =
      this.props && this.props.selectedCompany
        ? this.props.selectedCompany
        : {};
    let { conceptCode } = conceptId
      ? this.props.companiesHashMap[conceptId]
      : {};
    const defaultValues = {
      serviceSelection: "Residential",
    };
    return (
      <div
        style={{ width: "100%", flexDirection: "column", alignItems: "center" }}
      >
        {!this.props.selectedCompany && <Redirect to="/landing" />}
        <LoggedInContainerHeader
          history={this.props.history}
          resetData={this.props.resetData}
          showCancelButton={true}
        />

        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            maxWidth: 800,
            width: "100%",
            display: "block",
            paddingLeft: 20,
            paddingRight: 20,
            marginTop: 75,
          }}
        >
          {this.props.eventStatuses["getFranchiseServicesInfo"] ===
            EventStatuses.PENDING && (
            <div
              style={{
                width: "100%",
                height: "100%",
                minHeight: 200,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loader type="Oval" color="#a5a9af" height="50" width="50" />
            </div>
          )}

          <div
            className="landing-search-box"
            style={{  marginTop: 15, width: "100%" }}
          >
            <h6 style={{ fontSize: "28px" ,marginBottom:"12px"}}>Select Service Type: *</h6>
            <AvForm model={defaultValues}>
              <AvRadioGroup inline name="serviceSelection" required>
                <AvRadio
                  label="Residential"
                  value="Residential"
                  onChange={this.handleServiceChange}
                />
                <AvRadio
                  label="Commercial"
                  value="Commercial"
                  onChange={this.handleServiceChange}
                />
                <AvRadio
                  label="Auto"
                  value="auto"
                  onChange={this.handleServiceChange}
                />
              </AvRadioGroup>
            </AvForm>
          </div>

          {this.props.eventStatuses["getFranchiseServicesInfo"] !==
            EventStatuses.PENDING &&
            conceptCode &&
            this.brandSpecificQuestionComponents[conceptCode]}
          {this.props.eventStatuses["getFranchiseServicesInfo"] !==
            EventStatuses.PENDING &&
            conceptCode && (
              <SquareButton
                className="boxShadow"
                form={`${conceptCode}-service-question-form`}
                text="NEXT"
                buttonstyle={{
                  height: 50,
                  width: "100%",
                  backgroundColor: "rgb(38, 125, 191)",
                  marginTop: 10,
                }}
                textstyle={{
                  fontFamily: "Montserrat SemiBold",
                  color: "white",
                  fontSize: 16,
                }}
              />
            )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    selectedCompany,
    customServiceQuestions,
    serviceQuestionAnswers,
    companiesHashMap,
  } = state.schedulingReducer;
  const { eventStatuses } = state.systemReducer;
  return {
    ...state,
    selectedCompany,
    customServiceQuestions,
    serviceQuestionAnswers,
    companiesHashMap,
    eventStatuses,
  };
}

export default connect(mapStateToProps, {
  resetData,
  getCustomServiceQuestions,
  saveServiceQuestions,
  acknowledgeEvent,
  getFranchiseServicesInfo,
})(ServiceDescription);
