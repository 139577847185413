class RPMBookingValidation {
    constructor(selectedCompany, serviceQuestionAnswers, customerInfo, scheduledDateTime, additionalInfo, userId) {
      this.selectedCompany = selectedCompany;
      this.serviceQuestionAnswers = serviceQuestionAnswers;
      this.customerInfo = customerInfo;
      this.scheduledDateTime = scheduledDateTime;
      this.additionalInfo = additionalInfo;
      this.userId = userId;
    }
  
    buildBookingPayload = () => {
      let bookingPayload = {
        licenseNumber: this.selectedCompany.licenseNumber,
        webLocationId: this.selectedCompany.franchiseWebLocationId,
        scheduleId: this.scheduledDateTime ? this.scheduledDateTime.scheduleId : null,
        isLeadOnly: !(this.scheduledDateTime ? this.scheduledDateTime.scheduleId : null),
        firstName: this.customerInfo.FirstName,
        lastName: this.customerInfo.LastName,
        email: this.customerInfo.Email,
        WebLocationId: this.selectedCompany.franchiseWebLocationId,
        DayTimePhoneNumber: this.customerInfo.PhoneNumber || this.customerInfo.Phone,
        Phone: this.customerInfo.PhoneNumber || this.customerInfo.Phone,
        zipCode: this.customerInfo.Zip,
        isLocalized: true,
        serviceText: this.serviceQuestionAnswers.serviceSelection,
        phoneType: "Mobile", //un-hardcode later
        //phone2: "5128675309", //add value for later
        //phoneType2: "Work", //un-hardcode later
        preferredCommunicationType: this.customerInfo.ContactMethod || "Email",
        emailOptOut: false, //un-hardcode later
        address: this.customerInfo.Street,
        city: this.customerInfo.City,
        state: this.customerInfo.State,
        postalCode: this.customerInfo.Zip,
        leadSource: "Referral",
        leadOrigin: "Referral",
        campaignName: "Test Campaign", //un-hardcode later?
        callType: "Inbound",
        isTest: false,
        note: this.additionalInfo,
        conceptCalledId: this.selectedCompany.conceptId,
        isEmergencyCall: false,
        RANLoginTypeID: "1",
        RANUserID: this.userId,
      };
      return bookingPayload;
    }
  }
  
  export default RPMBookingValidation;