import React, { Component } from 'react';
import { connect } from 'react-redux';
import { submitAddressForAppointment, getPreviousAddressesForAppointment } from '../actions/index';
import LoggedInContainerHeader from '../Components/LoggedInContainer/LoggedInContainerHeader';
import { Input } from 'reactstrap';
import LocationScreenPreviousLocationRow from '../Components/Location/LocationScreenPreviousLocationRow';

class Location extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchText: ''
		};
	}

	componentDidMount() {
		this.props.getPreviousAddressesForAppointment();
	}

	onLocationPress = () => {
		navigator.geolocation.getCurrentPosition(
			(locationData) => {
				let { latitude, longitude } = locationData.coords;
				let address = {
					latitude,
					longitude
				};
				this.onAddressSelect(address, false, 'gps');
			},
			(error) => {},
			{ enableHighAccuracy: true, timeout: 20000, maximumAge: 0 }
		);
	};

	onAddressSelect = (address, shouldPreserve, method) => {
		this.props.submitAddressForAppointment(address, shouldPreserve, method);
		this.props.history.goBack();
	};

	onKeyPress = (e) => {
		// if (e.key === 'Enter' && this.state.searchText.length === 5)
		if (e.key === 'Enter') {
			let submitObj = {
				zipCode: this.state.searchText
			};
			this.onAddressSelect(submitObj, true, 'manual');
		}
	};

	render() {
		return (
			<div
				onKeyPress={this.onKeyPress}
				style={{ flex: 1, flexDirection: 'column', maxHeight: '100%', alignItems: 'center' }}
			>
				<LoggedInContainerHeader
					history={this.props.history}
					resetData={this.props.resetData}
					showCancelButton={false}
				/>
				<div
					style={{
						width: '100%',
						position: 'fixed',
						top: 75,
						height: 200,
						backgroundColor: 'white',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<div style={{ width: '100%', maxWidth: 800, height: '100%', display: 'block' }}>
						<div
							style={{
								width: '100%',
								height: 50,
								borderWidth: 0,
								borderBottomWidth: 1,
								borderColor: 'transparent',
								borderBottomColor: 'lightgrey',
								borderStyle: 'solid',
								paddingLeft: 20,
								paddingRight: 20,
								alignItems: 'center'
							}}
						>
							<img
								alt=""
								style={{
									maxWidth: 22,
									maxHeight: 22,
									height: 'auto',
									marginLeft: 5,
									resizeMode: 'contain'
								}}
								src={require('../Assets/location_icon.png')}
							/>
							<Input
								className={`neighborHeaderTextLarge focus-input`}
								style={{
									backgroundColor: 'transparent',
									borderColor: 'transparent',
									borderWidth: 0,
								}}
								placeholder="Search by Address or ZIP Code"
								// maxLength={5}
								value={this.state.searchText}
								onChange={(e) => {
									let text = e.target.value;
									// let regex = new RegExp("[^0-9.]"); //only for now with zipcode only
									// text = text.replace(regex, "");

									this.setState({ searchText: text });
								}}
							/>
						</div>
						<div
						className={`neighborHeaderTextLarge`}
							style={{
								cursor: 'pointer',
								width: '100%',
								height: 50,
								borderWidth: 0,
								borderBottomWidth: 1,
								borderColor: 'transparent',
								borderBottomColor: 'lightgrey',
								borderStyle: 'solid',
								paddingLeft: 20,
								paddingRight: 20,
								alignItems: 'center',
								color: 'grey'
							}}
							onClick={() => {
								this.onLocationPress();
							}}
						>
							<img
								alt=""
								style={{
									maxWidth: 22,
									maxHeight: 22,
									height: 'auto',
									marginLeft: 2,
									marginRight: 10,
									resizeMode: 'contain'
								}}
								src={require('../Assets/target_icon.png')}
							/>
							Current Location
						</div>
						<div
						className={`neighborHeaderTextLarge`}
							style={{
								fontFamily: 'Montserrat SemiBold',
								width: '100%',
								height: 100,
								paddingLeft: 30,
								paddingRight: 20,
								alignItems: 'flex-end'
							}}
						>
							Previously Searched Addresses
						</div>
					</div>
				</div>
				<div style={{ width: '100%', display: 'block', marginTop: 280, maxWidth: 800 }}>
					{this.props.previousAddresses &&
						this.props.previousAddresses.map((pa, k) => {
							return (
								<LocationScreenPreviousLocationRow
									idx={k}
									address={pa}
									selectFn={this.onAddressSelect}
									key={k}
								/>
							);
						})}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { previousAddresses, selectedAddress } = state.schedulingReducer;
	return {
		...state,
		previousAddresses,
		selectedAddress
	};
}

export default connect(mapStateToProps, {
	submitAddressForAppointment,
	getPreviousAddressesForAppointment
})(Location);
