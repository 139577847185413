class ISNBookingValidation {
    constructor(selectedCompany, serviceQuestionAnswers, customerInfo, scheduledDateTime, additionalInfo, userId) {
        this.selectedCompany = selectedCompany;
        this.serviceQuestionAnswers = serviceQuestionAnswers;
        this.customerInfo = customerInfo;
        this.scheduledDateTime = scheduledDateTime;
        this.additionalInfo = additionalInfo;
        this.userId = userId;
    }

    buildBookingPayload = () => {
        let bookingPayload = {
            licenseNumber: this.selectedCompany.licenseNumber,
            webLocationId: this.selectedCompany.franchiseWebLocationId,
            firstName: this.customerInfo.FirstName,
            lastName: this.customerInfo.LastName,
            email: this.customerInfo.Email,
            phone: this.customerInfo.PhoneNumber || this.customerInfo.Phone,
            phoneType: "Mobile", //un-hardcode later      
            preferredCommunicationType: this.customerInfo.ContactMethod || "Email",
            emailOptOut: false, //un-hardcode later
            address: this.customerInfo.Street,
            city: this.customerInfo.City,
            state: this.customerInfo.State,
            postalCode: this.customerInfo.Zip,
            note: this.additionalInfo,
            warranty: this.serviceQuestionAnswers.applianceUnderWarranty ? this.serviceQuestionAnswers.applianceUnderWarranty.toLowerCase() == "yes" : false,
            leadSourceId: 16,
            leadSource: "Referral",
            leadOrigin: "Referral",
            campaignName: "Test Campaign",
            disposition: "Residential Appointment",
            callType: "Inbound",
            isTest: false,
            estimateSquareFeet: this.serviceQuestionAnswers.squareFootage,
            scheduleId: this.scheduledDateTime ? this.scheduledDateTime.scheduleId : null,
            conceptCalledId: this.selectedCompany.conceptId,
            isEmergencyCall: false,
            RANLoginTypeID: "1",
            RANUserID: this.userId,
            ServiceSkuId: this.serviceQuestionAnswers.serviceSelection
        };
        if (!bookingPayload.secondaryLeadSourceId) { delete bookingPayload.secondaryLeadSourceId; }
        return bookingPayload;
    }
}
export default ISNBookingValidation;