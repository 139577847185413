import React, {Component} from "react";
import {Input} from "reactstrap";
import LandingPageServiceSearchRow from "./LandingPageServiceSearchRow";
import Loader from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from 'react-redux';
import {
	getServiceSearchTermResultsSuccessful,
  selectedServiceFromSearch
} from '../../actions';
class LandingPageServiceSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      defaultValue:"Residential",
      active : 0,
    };
  }
  componentDidUpdate() {
    // let {serviceSearchText, categorySelected} = this.state;
    //  localStorage.setItem("serviceModifierValue",this.state.defaultValue);
    // if (serviceSearchText.length > 2 && serviceSearchText != this.lastSearchedText && serviceSearchText != categorySelected) {
    //   this.lastSearchedText = serviceSearchText;
    //   this.props.searchTermSearch(serviceSearchText,this.props.zipCode,this.state.defaultValue);
    // }
  }

  onCategorySelect = category => {
    this.alterDropdownState(false);
    this.props.selectedServiceFromSearch(category)
    this.setState({searchText: category.name, categorySelected: category.name, dropdownVisible: false});
    this.props.onServiceSelectFn(category);
  }

  alterDropdownState = dropdownState => {
    this.props.alterDropdownState(dropdownState);
  }

  keyDownHandler = (e) => {
    if (e.keyCode === 38 && this.state.active > 0) {
      this.setState({active : this.state.active - 1})
    } else if (e.keyCode === 40 && this.state.active < this.props.searchTermResults.length - 1) {
      this.setState({active : this.state.active + 1})
    }else if(e.keyCode === 13 && this.state.active >= 0 ){
      if(this.props.searchTermResults){
      let serviceList = this.props.searchTermResults.sort(function (a, b) {  return a.rank - b.rank;  });
        let selectedObj = serviceList.find((a,i) => i == this.state.active);
        this.onCategorySelect(selectedObj)
      }
    }
  }

  keyDownHandler = (e) => {
    if (e.keyCode === 38 && this.state.active > 0) {
      this.setState({active : this.state.active - 1})
    } else if (e.keyCode === 40 && this.state.active < this.props.searchTermResults.length - 1) {
      this.setState({active : this.state.active + 1})
    }else if(e.keyCode === 13 && this.state.active >= 0 ){
      if(this.props.searchTermResults){
      let serviceList = this.props.searchTermResults.sort(function (a, b) {  return a.rank - b.rank;  });
        let selectedObj = serviceList.find((a,i) => i == this.state.active);
        this.onCategorySelect(selectedObj)
      }
    }
  }

  render() {
    if(this.props.searchTermResults){
      var searchTermResultArr = this.props.searchTermResults;
      searchTermResultArr = searchTermResultArr.sort(function (a, b) {  return a.rank - b.rank;  });
    }

    return (
      <div style={{width: "100%", position: "absolute", bottom: -80, backgroundColor: "#fff", zIndex: 3, flexDirection: "column"}}>
        <div className="landing-search-box">
          <Input
            type="search"
            style={{height: "calc(1.7em + .75rem + 2px)",fontSize:16,padding:".375rem 1.2rem"}}
            value={this.state.searchText}
            onChange={e => {
              let {value} = e.target;
              this.setState({
                searchText: value
              });
              if (!value) {
                this.props.getNearbyFranchises(this.props.zipCode);
              }
              const searchResultResponse = this.props.searchTermResults;
              if(searchResultResponse && value.length > 2){
                this.alterDropdownState(true)
              }
              if(value.length <= 2){  
                this.props.getServiceSearchTermResultsSuccessful([])
                this.alterDropdownState(false)
              }else{
                this.props.searchTermSearch(value,this.props.zipCode,this.state.defaultValue);
                this.alterDropdownState(true)
                this.setState({
                  active : 0
                })
              }
            }}
            
            placeholder="Service you're looking for"
            className={`boxShadow neighborHeaderTextSmall searchFont`}
            onKeyDown={this.keyDownHandler}
            // onFocus={()=>{this.alterDropdownState(true);}}          
          />
          <FontAwesomeIcon
            style={{position: "absolute", right: 10, top: 5, width: 20, height: 26,color:"#497cc2"}}
            icon="search"
          />
          {
            this.props.dropdownVisible &&
            <div className="service-search-dropdown" style={{zIndex: 9999, position: "absolute", width: "100%", flexDirection: "column",boxShadow:'1px 0px 3px 0px #9e9e9e'}}>
              {
                this.props.searchIsLoading &&
                  <div style={{width: "100%", height: 20, alignItems: "center", justifyContent: "center", backgroundColor: "rgb(248, 248, 248)"}}>
                    <Loader
                      type="Oval"
                      color="#a5a9af"
                      height="15"
                      width="15"
                    />
                  </div>
            } 
            {
              searchTermResultArr && searchTermResultArr.length > 0 ? searchTermResultArr.map((str, k) => {
                return (
                  <LandingPageServiceSearchRow
                    key={k}
                    serviceCategory={str}
                    onClickFn={this.onCategorySelect}
                    serviceSearchText={this.state.searchText}
                    isActive={this.state.active === k}
                  />
                )
              })
              :
              <LandingPageServiceSearchRow
              serviceCategory={{}}
            />
        }
            </div>
          }

        </div>        
      </div>
    );
  }
}

function mapStateToProps(state) {
	const {
		searchTermResults,
	} = state.schedulingReducer;
	return {
		...state,
		searchTermResults,
	};
}

export default connect(mapStateToProps,{
  getServiceSearchTermResultsSuccessful,
  selectedServiceFromSearch
})(LandingPageServiceSearch);
