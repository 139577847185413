import React, {Component} from "react";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

class LandingPageServiceSearchRow extends Component {

  serviceCategoryHighlightText = (serviceName,serviceSearchText) => {
var parts = [];
var matches = [];
if(serviceSearchText){
  if(serviceSearchText.length > 2){
    matches = match(serviceName, serviceSearchText);
    parts = parse(serviceName, matches);
  }
}
   return parts.map((part, index) => (
    <span key={index} style={{ fontWeight: part.highlight ? 'bold' : 400,fontFamily:'Montserrat Regular', }} >
      {part.text}
    </span>
  ));
  }

  render() {
    let images = {
      applianceRepair: require("../../Assets/serviceCategoryIcons/washer_dryer.png"),
      autoGlassRepair: require("../../Assets/serviceCategoryIcons/auto_glass_repair.png"),
      carpetCleaning: require("../../Assets/serviceCategoryIcons/carpet_cleaning.png"),
      electrical: require("../../Assets/serviceCategoryIcons/electrical.png"),
      fireWaterDamage: require("../../Assets/serviceCategoryIcons/fire_water_damage.png"),
      glassRepairInstallation: require("../../Assets/serviceCategoryIcons/glass_repair_installation.png"),
      handymanServices: require("../../Assets/serviceCategoryIcons/handyman_services.png"),
      heatingAndAC: require("../../Assets/serviceCategoryIcons/heating_and_AC.png"),
    };

    return (
      <div style={{width: "100%", cursor: "pointer", minHeight: 35, height: 35}} onClick={()=>{this.props.onClickFn(this.props.serviceCategory);}}>
        <div className={this.props.isActive ? 'selectedActiveValue' : 'nonSelectedActiveValue'}
        >
          {this.props.serviceCategory.name ?
            <div className="serviceCategoryName" style={{color: "rgb(39, 126, 191)", marginLeft: 20, fontFamily: "Montserrat SemiBold",fontSize:"14px"}}>
                {this.serviceCategoryHighlightText(this.props.serviceCategory.name,this.props.serviceSearchText)}
            </div>
           : <div className="serviceCategoryName" style={{color: "rgb(39, 126, 191)", marginLeft: 20, fontFamily: "Montserrat SemiBold",fontSize:"14px"}}>
              {'Search Results Not Found'}
             </div>}             
        </div>
      </div>
    );
  }
}

export default LandingPageServiceSearchRow;