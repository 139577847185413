import React, {Component} from "react";
import {connect} from "react-redux";
import {
  getBrands,
  getFranchiseServicesInfo,
  saveServiceQuestions,
  getServiceTypeProblems
} from "../../actions";
import {AvForm, AvField, AvRadioGroup, AvRadio} from "availity-reactstrap-validation";
import {FormGroup} from "reactstrap";
import {EventStatuses} from "../../system";
import Loader from "react-loader-spinner";

class MrApplianceServiceQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeServiceTypeId: null,
      activeServiceSkuId: null
    };
  }  

  componentDidMount() {
    let { conceptId } = this.props.selectedCompany;
    let { conceptCode } = this.props.companiesHashMap[conceptId];
    this.props.getBrands(conceptCode);
  }

  submitServiceQuestions = (event, values) => {
    let {
      serviceSelection,
      applianceUnderWarranty,
      brandId,
      serviceTypeProblem
    } = values;


    let data = {
      serviceSelection,
      applianceUnderWarranty,
      brandId,
      serviceTypeProblem
    };
    this.props.saveServiceQuestions(data);
  }

  pullServiceProblems = serviceSkuId => {
    if ((!serviceSkuId) || this.props.serviceTypeProblemHashMap[serviceSkuId]) {
      return;
    }
    this.props.getServiceTypeProblems(serviceSkuId);
  }

  render() {
    return (
      <div style={{width: "100%", flexDirection: "column"}}>
        <AvForm onValidSubmit={this.submitServiceQuestions} id="MRA-service-question-form">
        <div style={{width: "100%", marginTop: 30, marginBottom: 30, fontSize: 28, flexDirection: "row", fontFamily: "Montserrat SemiBold"}}>
            How Can We Help You?
            {
              this.props.eventStatuses["getFranchiseServicesInfo"] === EventStatuses.PENDING &&
              <div style={{marginLeft: 5, marginRight: 5, alignItems: "center", flexDirection: "center"}}>
                <Loader
                  type="Oval"
                  color="#a5a9af"
                  height="20"
                  width="20"
                />
              </div>
            }
          </div>
          <FormGroup row
            style={{marginLeft: 0, marginRight: 0}}
          >
            <AvField
              disabled={this.props.eventStatuses["getFranchiseServicesInfo"] === EventStatuses.PENDING}
              name="serviceSelection"
              type="select"
              placeholder="Select Your Service"
              required
              onChange={e => {
                this.setState({
                  activeServiceSkuId: e.target.value
                });
                this.pullServiceProblems(e.target.value);
              }}
            >
              <option value="">--</option>
              {
                this.props.franchiseServices &&
                this.props.franchiseServices.map((fs, k) => {
                  return <option value={fs.serviceSkuId} key={k}>{fs.serviceSkuName}</option>;
                })
              }
            </AvField>
          </FormGroup>
          {this.props.canDisplayWarranty && <>
          <div style={{width: "100%", marginTop: 30, marginBottom: 30, fontSize: 28, fontFamily: "Montserrat SemiBold"}}>
            Is This Warranty Work?
          </div>
          <FormGroup row
            style={{marginLeft: 0, marginRight: 0}}
          >
            <AvRadioGroup
              name="applianceUnderWarranty"
              type="select"
              required
            >
              <AvRadio label="Yes" value="yes"/>
              <AvRadio label="No" value="no"/>
            </AvRadioGroup>
          </FormGroup>
          </>}          
          <div style={{width: "100%", marginTop: 30, marginBottom: 30, fontSize: 28, flexDirection: "row", fontFamily: "Montserrat SemiBold"}}>
            Brand Information
            {
              this.props.eventStatuses["getBrands"] === EventStatuses.PENDING &&
              <div style={{marginLeft: 5, marginRight: 5, alignItems: "center", flexDirection: "center"}}>
                <Loader
                  type="Oval"
                  color="#a5a9af"
                  height="20"
                  width="20"
                />
              </div>
            }
          </div>
          <FormGroup row
            style={{marginLeft: 0, marginRight: 0}}
          >
            <AvField
              disabled={this.props.eventStatuses["getBrands"] === EventStatuses.PENDING }
              name="brandId"
              type="select"
              placeholder="Select A Brand"
              required
            >
              <option value="">--</option>
              {
                this.props.brands &&
                this.props.brands.map((b, k) => {
                  return <option value={b.externalId} key={k}>{b.makeName}</option>;
                })
              }
            </AvField>
          </FormGroup>
          <div style={{width: "100%", marginTop: 30, marginBottom: 30, fontSize: 28, flexDirection: "row", fontFamily: "Montserrat SemiBold"}}>
            Appliance Issues
            {
              this.props.eventStatuses["getServiceTypeProblems"] === EventStatuses.PENDING &&
              <div style={{marginLeft: 5, marginRight: 5, alignItems: "center", flexDirection: "center"}}>
                <Loader
                  type="Oval"
                  color="#a5a9af"
                  height="20"
                  width="20"
                />
              </div>
            }
          </div>
          <FormGroup row
            style={{marginLeft: 0, marginRight: 0}}            
          >
            <AvField
              disabled={this.props.eventStatuses["getServiceTypeProblems"] === EventStatuses.PENDING}
              name="serviceTypeProblem"
              type="select"
              placeholder="Select An Issue"
              required              
            >
              <option value="">--</option>
              {
                this.props.serviceTypeProblemHashMap &&
                this.state.activeServiceSkuId &&
                this.props.serviceTypeProblemHashMap[this.state.activeServiceSkuId] &&
                this.props.serviceTypeProblemHashMap[this.state.activeServiceSkuId].map((p, k) => {
                  return <option value={p.serviceTypeProblemName} key={k}>{p.serviceTypeProblemName}</option>;
                })
              }
            </AvField>            
          </FormGroup>                    
        </AvForm>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    brands,
    selectedCompany,
    companiesHashMap,
    serviceTypeProblemHashMap,
    franchiseServices,
    smartwarePrimaryLeadSources,
    smartwareSecondaryLeadSources
  } = state.schedulingReducer;
  const {eventStatuses} = state.systemReducer;
  return {
    ...state,
    brands,
    selectedCompany,
    companiesHashMap,
    serviceTypeProblemHashMap,
    franchiseServices,
    smartwarePrimaryLeadSources,
    smartwareSecondaryLeadSources,
    eventStatuses
  };
}

export default connect(mapStateToProps, {
  getBrands,
  getFranchiseServicesInfo,
  saveServiceQuestions,
  getServiceTypeProblems
}, null, {forwardRef: true})(MrApplianceServiceQuestions);