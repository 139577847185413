class BaseService {

  axiosOptions() {
    this.opts = {};
    return this;
  }

  asGET() {
    this.opts["method"] = "GET";
    return this;
  }

  asPOST() {
    this.opts["method"] = "POST";
    return this;
  }

  withJSONHeaders(token) {
    this.opts["headers"] = {
      "content-type": "application/json"
    };

    if (token) {
      this.opts.headers["Authorization"] = ("bearer " + token);
    }
    return this;
  }
  withRanAPIKeyHeaders(token, apiKey) {
    this.opts["headers"] = {
      "content-type": "application/json"
    };

    if (token) {
      this.opts.headers["Authorization"] = ("bearer " + token);
    }
    if(apiKey){
      this.opts.headers["apikey"] = ("{"+apiKey+"}");
    }
    return this;
  }
  withFormHeaders(token) {
    this.opts["headers"] = {
      "content-type": "multipart/form-data"
    };

    if (token) {
      this.opts.headers["Authorization"] = ("bearer " + token);
    }
    return this;
  }

  withURLEncodedHeaders(token) {
    this.opts["headers"] = {
      "content-type": "application/x-www-form-urlencoded"
    };

    if (token) {
      this.opts.headers["Authorization"] = ("bearer " + token);
    }
    return this;
  }

  withCORSHeader(origin) {
    if (!this.opts["headers"]) {
      this.opts["headers"] = {};
    }

    if (origin) {
      this.opts.headers["Access-Control-Allow-Origin"] = origin;
    }
    return this;
  }

  withURL(url) {
    if (!(url.toLowerCase()).includes("http")) {
      url = (global.baseUrl + url);
    }
    this.opts["url"] = url;
    return this;
  }

  withData(data) {
    if (typeof data === "object" && !(data instanceof FormData)) {
      this.opts["data"] = JSON.stringify(data);
    } else {
      this.opts["data"] = data;
    }
    return this;
  }

  withProgressFn(fn) {
    this.opts["onUploadProgress"] = fn;
    return this;
  }

  build() {
    return this.opts;
  }

  validateTokenExpiry(token) {
     if(token){
      const jwtPayload = JSON.parse(window.atob(token.split('.')[1]))
      const isExpired = Date.now() >= jwtPayload.exp * 1000;
        return isExpired;
      }
    }
}

export default BaseService;