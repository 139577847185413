import React, {Component} from "react";
import LoggedInContainerHeader from "../Components/LoggedInContainer/LoggedInContainerHeader";
import {connect} from "react-redux";
import {resetData} from "../actions/index";
import SubmitPageContactInfo from "../Components/Submit/SubmitPageContactInfo";
import SubmitPageAppointmentInfo from "../Components/Submit/SubmitPageAppointmentInfo";
import SubmitPageBusinessSpecificInfo from "../Components/Submit/SubmitPageBusinessSpecificInfo";
import {Redirect} from "react-router-dom";
import SquareButton from "../Components/SquareButton";

class Submit extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  returnToMenu = () => {
    this.props.resetData();
    this.props.history.replace("/landing");
  }

  render() {
    let {
      serviceQuestionAnswers,
      customerBrandInfo,
      customerOptionsSelected,
      brands,
      additionalInfo,
      franchiseServices,
      scheduledDateTimeObj,
      customerInfoTemp
    } = this.props;

    return (
      <div style={{width: "100%", flexDirection: "column", alignItems: "center"}}>
        {
          (!this.props.selectedCompany ||
           !this.props.customerInfoTemp) &&
          <Redirect to="/landing"/>
        }
        <LoggedInContainerHeader
          history={this.props.history}
          noBackButton={true}
        />
        <div style={{width: "100%", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: 75, maxWidth: 800}}>
          <div style={{fontSize: 20, fontFamily: "Montserrat SemiBold", marginTop: 5}}>
            Help is on the way!
          </div>
          <div style={{fontSize: 20, marginTop: 2, fontFamily: "Montserrat SemiBold"}}>
            Thank you for your submission.
          </div>
        </div>
        <div style={{width: "100%", display: "block", paddingBottom: 40, maxWidth: 800, alignItems: "center"}}>
          {
            scheduledDateTimeObj &&
            serviceQuestionAnswers &&
            <SubmitPageAppointmentInfo
              franchiseServices={franchiseServices}
              scheduledDateTimeObj={scheduledDateTimeObj}
              serviceType={serviceQuestionAnswers.serviceSelection}
            />
          }
          <SubmitPageContactInfo
            customerContactInfo={customerInfoTemp}
          />
          {
            customerBrandInfo &&
            customerOptionsSelected &&
            brands &&
            additionalInfo &&
            <SubmitPageBusinessSpecificInfo
              itemToFix={"Appliance"} //need to find a "good" way to make this dynamic
              brandName={brands.find(b => b.brandId == customerBrandInfo.brandId).brandName}
              problemsToFix={customerOptionsSelected.join(", ")}
              additionalInfo={additionalInfo}
            />
          }
          <div style={{width: "100%", paddingLeft: 20, paddingRight: 20, marginTop: 20}}>
            <SquareButton
              className="boxShadow"
              onClick={this.returnToMenu}
              text="RETURN TO MENU"
              buttonstyle={{height: 50, width: "100%", backgroundColor: "rgb(38, 125, 191)"}}
              textstyle={{color: "white", fontSize: 16, fontFamily: "Montserrat SemiBold"}}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    selectedCompany,
    customerInfoTemp,
    scheduledDateTimeObj,
    serviceQuestionAnswers,
    customerBrandInfo,
    brands,
    customerOptionsSelected,
    additionalInfo,
    franchiseServices
  } = state.schedulingReducer;
  return {
    ...state,
    customerInfoTemp,
    selectedCompany,
    scheduledDateTimeObj,
    serviceQuestionAnswers,
    customerBrandInfo,
    brands,
    customerOptionsSelected,
    additionalInfo,
    franchiseServices
  };
}

export default connect(mapStateToProps, {resetData})(Submit);