import React, {Component} from "react";
import {connect} from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import {Container} from "reactstrap";
import routes from "../routes";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

class LoggedInContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <div style={{display: "flex", flexDirection: "column", width: "100%", height: "100%"}}>
      {
        (() => {
          const userIsAllowedAccess = this.props.userLoggedIn || window.location.hash.includes("forgotPassword");
          if (!userIsAllowedAccess) {
            return <Redirect to="/login"/>;
          }
        })()
      }
        <div style={{width: "100%", height: "100%"}}>
        <Container fluid style={{padding: 0, width: "100%", flexDirection: "none"}}>
          <Switch history={history}>
            {
              routes.map((route, idx) => {
                return route.component ?
                  (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => (
                        <route.component {...props}/>
                      )}
                    />
                  ) :
                  (null);
              })
            }
          </Switch>
        </Container>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {userLoggedIn} = state.loginReducer;
  return {
    ...state,
    userLoggedIn
  };
}

export default connect(mapStateToProps, {})(LoggedInContainer);
