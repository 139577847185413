import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as serviceWorker from './serviceWorker';
// import ReactGA from 'react-ga';

// const tagManagerArgs = {
// 	gtmId: 'GTM-M68K4MG'
// }
 
// TagManager.initialize('GTM-M68K4MG');
//  ReactGA.initialize(global.gaCode);

//  console.log('GA',global.gaCode)

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (module.hot) {
    module.hot.accept();
  }