import React, {Component} from "react";

class ConfirmationPageAbout extends Component { 
  
  render() {
    return (
      <div 
        style={{
          borderBottomStyle: "solid",
          borderBottomWidth: 0.5,
          borderColor: "gray",
          width: "100%",
          padding: 30,
          flexDirection: "column"
        }}
      >
        {/*Company Name*/}
        <div style={{flex: 1, width: "100%", justifyContent: "center", marginBottom: 15}}>
          <div style={{fontSize: 28, textAlign: "left", alignSelf: "flex-start", width: "100%", fontFamily: "Montserrat SemiBold"}}>
            About
          </div>
        </div>
        <div style={{width: "100%"}}>
          <div style={{fontSize: 20, marginVertical: 5, fontFamily: "Montserrat Light"}}>
            Add interesting content here
          </div>
        </div>
      </div>
    )
  }
}

export default ConfirmationPageAbout;