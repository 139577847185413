import React, { Component } from 'react';
import { connect } from 'react-redux';
import Alert from '../Components/Alert';
import SquareButton from '../Components/SquareButton';
import LoggedInContainerHeader from '../Components/LoggedInContainer/LoggedInContainerHeader';
import { saveScheduledDateAndTime, getTechAvailability, resetData, acknowledgeEvent } from '../actions/index';
import { Redirect } from 'react-router-dom';
import { EventStatuses } from '../system';
import Loader from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button } from "reactstrap";
import moment from 'moment';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 1
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 1
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 1
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1
	}
};


class Calendar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedDate: null,
			selectedTime: null,
			startDate: moment().startOf('isoWeek'),
			firstWeekStart: this.props.startingWeek,
			weekObjects: [],
			techAvailability: [],
			b: [],
			futureWeeks: 49,
			selectedDay: '',
			selectedDayTimeSlots: [],


			days: 84,
			startDate1: moment().startOf("isoWeek"),
			activeIndex: 0,
			selectedIndex: 0,

			indexAPICall: null
		};
		this.redirectEndpoint = null;
		this.isoDayArray = ["", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
	}
	arraysEqual = (a, b) => {
		if (a === b) return true;
		if (a == null || b == null) return false;
		if (a.length !== b.length) return false;

		for (var i = 0; i < a.length; ++i) {
			if (a[i] !== b[i]) return false;
		}
		return true;
	}
	setTime = (sequenceId) => {
		this.setState({ sequenceId: sequenceId });
	}

	selectDay = (date, dayOfWeek, elementsToShow) => {
		this.setState({ selectedDay: date, selectedDayTimeSlots: elementsToShow });

	}

	dayTap = (date, dayOfWeek, elementsToShow) => {
		this.setState({ selectedDay: date, selectedDayTimeSlots: elementsToShow });
		this.setState({ selectedIndex: this.state.activeIndex });
		if (this.state.activeIndex !== this.state.selectedIndex) {
			this.setState({ sequenceId: null });

		}

	}

	getNextTechAvailability = (dateObject) => {

		let dateObj = { ...dateObject.week1 };
		let { selectedCompany, serviceQuestionAnswers } = this.props;
		let { licenseNumber, franchiseWebLocationId } = selectedCompany;
		let { Zip } = this.props.customerInfoTemp;
		let { serviceSelection } = serviceQuestionAnswers;
		let future = moment(dateObj).subtract(54, "days").format("MM/DD/YYYY");
		this.setState({
			startDate1: moment(dateObj).format("MM/DD/YYYY"),
		}, () => {
			this.props.getTechAvailability({
				zipCode: Zip,
				serviceSkuId: serviceSelection,
				webLocationId: franchiseWebLocationId,
				dateStart: future,
				licenseNumber: licenseNumber,
				isEstimate: false,
				NumberOfDays: 108
			});
		});

	}
	triggerAPI() {
		let { startDate } = this.state;
		let { selectedCompany, serviceQuestionAnswers } = this.props;
		let { licenseNumber, franchiseWebLocationId } = selectedCompany;
		let { Zip } = this.props.customerInfoTemp;
		let { serviceSelection } = serviceQuestionAnswers;

		if (!(this.state.techAvailability && this.state.techAvailability.resultCollection)) {

			this.props.getTechAvailability({
				zipCode: Zip,
				webLocationId: franchiseWebLocationId,
				serviceSkuId: serviceSelection,
				dateStart: startDate.format("MM/DD/YYYY"),
				licenseNumber: licenseNumber,
				isEstimate: false,
				NumberOfDays: 108
			});
		}

	};
	resetDate = ({

	}) => {
		this.setState({
			dateTimeSelected: null
		})
	}

	renderCalendarAvailabilityRow = (weekStart) => {
		let calendarArray = [];
		let availability = this.state.techAvailability;
		if (!availability) {
			return;
		}
		for (var i = 0; i < 7; i++) {
			let weekStartFilterFormat = weekStart.format("YYYY-MM-DD");
			let applicableElements = availability.filter(a => a.startDate.startsWith(weekStartFilterFormat));
			calendarArray.push(this.renderCalendarAvailabilityDay(weekStart.format("MM/DD/YY"), this.isoDayArray[weekStart.isoWeekday()], applicableElements));
			weekStart.add(1, "days");
		}

		return calendarArray;
	}


	renderCalendarAvailabilityDay = (date, dayOfWeek, elementsToShow) => {
		let selectedStyle = {};
		let selectedTextStyle = {};
		let disabledTextStyle = {};
		if (this.state.selectedDay === date) {
			selectedStyle = style.btnActive;
			selectedTextStyle = style.btnActiveText;
		} else {
			selectedTextStyle = style.btnText;
		}

		let availableCount = elementsToShow.filter(function (e) { return e.isAvailable; }).length;
		if (availableCount) {
			availableCount = true;

		} else {
			availableCount = false;
			disabledTextStyle = style.disabledTextStyle;
		}


		return (
			<div
				style={disabledTextStyle}
				onClick={() => {
					this.selectDay(date, dayOfWeek, elementsToShow);
					this.dayTap(date, dayOfWeek, elementsToShow);
				}}
				style={{ ...selectedStyle, ...disabledTextStyle }}>
				<div style={{ width: "100%", flexDirection: "column", justifyContent: "center", marginVertical: 5, margin: '10px' }}>
					<div style={{ ...selectedTextStyle, ...disabledTextStyle }}>
						{moment(date).format('dd')}
					</div>
					<div style={{ ...selectedTextStyle, ...disabledTextStyle }}>
						{moment(date).format('MM/DD')}
					</div>
				</div>

			</div>
		);
	}





	componentDidMount() {

		let { startDate } = this.state;
		let { selectedCompany, serviceQuestionAnswers } = this.props;

		if (!selectedCompany) {
			return;
		}
		console.log('component did mount', this.state.techAvailability)
		this.triggerAPI();
		let { licenseNumber } = selectedCompany;
		let { Zip } = this.props.customerInfoTemp;
		let { serviceSelection } = serviceQuestionAnswers;

		if (this.state.startDate) {
			let sampleObject = [];
			let firstWeekStart = this.state.startDate;

			for (let i = 0; i < this.state.futureWeeks; i++) {
				sampleObject[i] = {};
				sampleObject[i]['week1'] = firstWeekStart;
				sampleObject[i]['week2'] = moment(firstWeekStart).add(7, "days");
				firstWeekStart = moment(firstWeekStart).add(14, "days");
			}
			this.setState({ firstWeekStart: firstWeekStart });

			this.setState({ weekObjects: sampleObject });


		}
	}

	componentDidUpdate(prevProps, prevState) {
		let { eventStatuses } = this.props;
		let { COMPLETED } = EventStatuses;
		if (eventStatuses['saveScheduledDateAndTime'] === COMPLETED && this.redirectEndpoint) {
			this.props.acknowledgeEvent('saveScheduledDateAndTime');
			this.props.history.push(this.redirectEndpoint);
		} else if (eventStatuses.getTechAvailability === 'FAILED') {
			this.errorHandling();
		}


		if ((this.props.techAvailability && this.props.techAvailability.resultCollection)) {
			if (this.state.techAvailability && this.state.techAvailability.length === 0) {

				this.setState({ techAvailability: this.props.techAvailability.resultCollection })
			}
		}

		if ((this.props.techAvailability && this.props.techAvailability.resultCollection && prevProps.techAvailability && prevProps.techAvailability.resultCollection)) {
			if (!this.arraysEqual(prevProps.techAvailability && prevProps.techAvailability.resultCollection, this.props.techAvailability && this.props.techAvailability.resultCollection)) {

				this.setState({ techAvailability: this.props.techAvailability.resultCollection })


			}

		}


	}

	onDateSelect = ({ textContent, date, dayOfWeek, sequenceId, scheduleId, startTime, endTime, startHour }) => {

		this.setState({
			dateTimeSelected: {
				sequenceId,
				timeSlot: textContent,
				date,
				dayOfWeek,
				scheduleId,
				startTime,
				endTime,
				startHour
			}
		});
	};




	errorHandling = () => {
		this.handleError.showWithMessage('ERROR', "Sorry Something went worng, please try again");
	};

	onSubmit = () => {
		let { dateTimeSelected } = this.state;
		/* 	this.redirectEndpoint = '/additionalinfo'; */
		this.props.saveScheduledDateAndTime(dateTimeSelected);

		let route = "";
		route = "/additionalinfo";


		this.props.history.push(route);
	};


	renderDailyTimeSlots = (index) => {
		
		if (index == this.state.selectedIndex && this.state.selectedIndex === this.state.activeIndex && this.state.selectedDayTimeSlots && this.state.selectedDayTimeSlots.length) {
			return (
				<div style={{ width: "100%", flexDirection: "column" }}>
					<div style={{ marginVertical: 10, fontWeight: "bold", fontSize: 18 }}>
						Available Slots
					</div>
					<div style={{ flexWrap: 'wrap', width: "100%", flexDirection: "row" }}>
						{
							this.state.selectedDayTimeSlots.map((e, k) => {
								let text;
								let selectedStyle = {};
								let selectedTextStyle = {};
								let sequenceId = e.$id;
								let containerStyle = {};
								let textStyle = {};
								let startDate = moment(e.startDate);
								let startTime = moment(e.startDate).format('hh:mm');
								let endTime = moment(e.endDate).format('hh:mm');
								let date = moment(e.startDate).format('MM/DD/YYYY');
								let dayOfWeek = moment(e.startDate).format('dddd');
								let startHour = startDate.hour();
								let scheduleId = e.scheduleID;

								if (!e.isAvailable) {
									containerStyle = style.unavailableContainerStyle;
									textStyle = style.unAvailableTextStyle;
								} else {
									containerStyle = style.availableContainerStyle;
									textStyle = style.availableTextStyle;
								}

								if (e.displayText.toLowerCase() === 'morning' || e.displayText.toLowerCase() === 'afternoon') {
									//We show AM OR PM
									text = startHour < 12 ? "AM" : "PM";

								} else if (e.displayText.toLowerCase() === 'after hours') {
									text = e.displayText;
								} else {
									text = e.displayText;

								}
								if (this.state.sequenceId === sequenceId) {
									selectedStyle = style.btnActive;
									selectedTextStyle = style.btnActiveText;
								} else {
									selectedTextStyle = textStyle;
								}
								return (
									<div key={k} style={{ ...containerStyle, ...selectedStyle }}>
										<div
											disabled={!e.isAvailable}
											style={{ width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}
											onClick={() => {
												this.setTime(sequenceId);
												this.setState({ selectedIndex: this.state.activeIndex, sequenceId: sequenceId });
												this.onDateSelect({
													textContent: text,
													date,
													dayOfWeek,
													sequenceId,
													scheduleId,
													startTime,
													endTime,
													startHour
												});
											}}
										>
											<div style={{ ...textStyle, ...selectedTextStyle }}  >{text}
											</div>
										</div>
									</div>
								)

							})
						}
					</div>
				</div>
			)
		}

	}
	onChangeSlide(index, b) {
		const { weekObjects } = this.state;
		const copy = [...weekObjects];
		let firstWeekStart = this.state.firstWeekStart;
		let sampleObject = [];
		this.setState({ activeIndex: index });
		console.log('index is ', index)

		if (index == this.state.futureWeeks - 1) {
			for (let i = 0; i < this.state.futureWeeks; i++) {
				sampleObject[i] = {};
				sampleObject[i]['week1'] = firstWeekStart;
				sampleObject[i]['week2'] = moment(firstWeekStart).add(7, "days");
				firstWeekStart = moment(firstWeekStart).add(14, "days");
			}
			this.setState({ firstWeekStart: firstWeekStart })
			this.setState({ weekObjects: [...this.state.weekObjects, ...sampleObject], futureWeeks: this.state.futureWeeks + 2 })

		}


		if (index % 3 == 0 && index != 0 && index !== this.state.indexAPICall) {
			this.setState({ indexAPICall: index })

			this.getNextTechAvailability(copy[index]);
		}


	}

	render() {
		const handleOnDragStart = (e) => e.preventDefault()

		const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
			const { carouselState: { currentSlide } } = rest;
			return (
				<div className="carousel-button-group">

					<Button
						className={currentSlide === 0 ? 'disable-carousel-button-group-previous' : 'carousel-button-group-previous'} onClick={() => previous()}
						style={{ backgroundColor: 'rgba(0,0,0,0.6)', borderRadius: '50%', color: "white", marginHorizontal: 10, width: 40, height: 40, alignItems: "center", justifyContent: "center" }}
					>
						<FontAwesomeIcon icon="angle-left" />

					</Button>


					<Button
						className={'carousel-button-group-next'} onClick={() => next()}
						style={{ backgroundColor: 'rgba(0,0,0,0.6)', borderRadius: '50%', color: "white", marginHorizontal: 10, width: 40, height: 40, alignItems: "center", justifyContent: "center" }}
					>
						<FontAwesomeIcon
							icon="angle-right"
						/>
					</Button>



				</div>
			);
		};
		return (
			<div style={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}>
				{!this.props.selectedCompany && <Redirect to="/landing" />}
				<LoggedInContainerHeader
					history={this.props.history}
					resetData={this.props.resetData}
					showCancelButton={true}
				/>
				<div style={{
					paddingLeft: '20px',
					paddingRight: '20px', maxWidth: 800, width: '100%', marginTop: 75, display: 'block', marginBottom: 75
				}}>
					<div
						style={{
							width: '100%',
							marginTop: 30,
							marginBottom: 30,

							paddingRight: 20,
							fontSize: 28,
							fontFamily: 'Montserrat SemiBold'
						}}
					>
						Let us know what time is best for you.
					</div>

					<Alert
						ref={(r) => {
							this.handleError = r;
						}}
					/>
					{
						this.state.techAvailability && this.state.techAvailability.length && this.state.weekObjects ?

							<Carousel
								arrows={false} customButtonGroup={<ButtonGroup />}
								afterChange={(nextSlide, { currentSlide, onMove }) => {
									this.onChangeSlide(currentSlide, onMove);
								}}
								beforeChange={(nextSlide, { currentSlide, onMove }) => {
									this.onChangeSlide(currentSlide, onMove);
								}}
								responsive={responsive}>
								{
									this.state.weekObjects.map((item, index) => {
										return (
											<div>

												<div style={{ width: "100%", flexDirection: "column" }}>
													{/*1st week row*/}
													<div style={style.weekRowStyle}>
														{
															this.renderCalendarAvailabilityRow(moment(item.week1))
														}
													</div>
													{/*2nd week row*/}
													<div style={style.weekRowStyle}>
														{
															this.renderCalendarAvailabilityRow(moment(item.week2))
														}
													</div>
													{<div style={style.weekRowStyle}>
														{
															this.renderDailyTimeSlots(index)
														}
													</div>}



												</div>


											</div>
										)
									})
								}
							</Carousel>
							:
							< div style={{ width: "100%", height: 200, alignItems: "center", justifyContent: "center" }}>
								<Loader
									type="Oval"
									color="#a5a9af"
									height="50"
									width="50"
								/>
							</div>


					}

					<div
						style={{
							width: '100%',
							alignItems: 'center',
							justifyContent: 'center',
							marginTop: 40,
							paddingLeft: 20,
							paddingRight: 20
						}}
					>

					</div>
					<SquareButton
						className="boxShadow"
						disabled={!this.state.dateTimeSelected}
						onClick={this.onSubmit}
						text="NEXT"
						buttonstyle={{
							height: 50,
							width: '100%',
							backgroundColor: 'rgb(38, 125, 191)',
							maxWidth: 800
						}}
						textstyle={{ color: 'white', fontSize: 16, fontFamily: 'Montserrat SemiBold' }}
					/>
				</div>

			</div >
		);
	}
}

function mapStateToProps(state) {
	const { selectedCompany, serviceQuestionAnswers, techAvailability, customerInfoTemp } = state.schedulingReducer;
	const { eventStatuses } = state.systemReducer;
	return {
		...state,
		selectedCompany,
		serviceQuestionAnswers,
		techAvailability,
		customerInfoTemp,
		eventStatuses
	};
}


const style = {
	weekRowStyle: {
		width: "100%",
		flexDirection: "row",
		marginTop: 10,
		marginBottom: 10,
		justifyContent: "space-between"
	},
	unavailableContainerStyle: {
		height: 75,
		width: 75,
		borderWidth: 0,
		borderRadius: 2,
		marginTop: 5,
		marginBottom: 5,
		marginRight: 5,
		border: '2px solid #bebebe',
		pointerEvents: 'none'
	},
	unAvailableTextStyle: {
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		height: "100%",
		color: "#bebebe",
		fontSize: 12,
		paddingLeft: 5,
		paddingRight: 5,

	},
	availableContainerStyle: {
		cursor: "pointer",
		height: 75,
		width: 75,
		borderWidth: 0,
		borderRadius: 2,
		backgroundColor: "white",
		border: '2px solid #267dbf',
		marginTop: 5,
		marginBottom: 5,
		marginRight: 5

	},
	availableTextStyle: {
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		height: "100%",
		color: "black",
		fontSize: 12,
		paddingLeft: 5,
		paddingRight: 5,

	},
	selectedContainerStyle: {
		cursor: "pointer",
		height: 75,
		width: 75,
		borderWidth: 0,
		borderRadius: 2,
		backgroundColor: "rgb(121, 181, 232)",
		marginTop: 5,
		marginBottom: 5
	},
	selectedTextStyle: {
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		height: "100%",
		color: "white",
		paddingLeft: 10,
		paddingRight: 10,
		fontSize: 12

	},
	btnActive: {

		borderWidth: 0,
		borderRadius: 2,
		backgroundColor: "#267dbf",
	},
	btnActiveText: {
		fontSize: 12,
		color: 'white',

	},
	btnText: {
		fontSize: 12,
		color: '#267dbf'
	},
	disabledTextStyle: {
		fontSize: 12,
		color: '#b7b7b7',
		pointerEvents: 'none'
	}
};

export default connect(mapStateToProps, {
	resetData,
	saveScheduledDateAndTime,
	getTechAvailability,
	acknowledgeEvent
})(Calendar);
