import * as types from '../actions/index';

let schedulingState = {
	previousAddresses: [],
	selectedAddress: null,
	selectedCompany: null,
	customerInfoTemp: null,
	scheduledDate: null,
	scheduledTime: null,
	serviceQuestionAnswers: null,
	brands: [],
	serviceTypeProblemHashMap: {},
	locationMethod: 'gps',
	landingPageLoaded: false,
	error: "",
	selectedServiceCategory:{},
  currentSelectedFranchiseFees: null
};

const schedulingReducer = (state = schedulingState, action) => {
	// console.log('action: ' + action.type);
	// console.log('state: ', state);
	switch (action.type) {
		case types.SCHEDULING_SERVICE_LOADING:
			return {
				...state,
				loading: true
			};

		case types.COMPANY_SELECTED_FOR_APPOINTMENT:
			return {
				...state,
				selectedCompany: action.selectedCompany
			};

		case types.USER_ADDRESS_SUBMITTED_FOR_APPOINTMENT_SUCCESS:
			return {
				...state,
				selectedAddress: action.selectedAddress,
				locationMethod: action.method
			};

		case types.RETRIEVE_ADDRESSES_FOR_APPOINTMENT_SUCCESS:
			return {
				...state,
				previousAddresses: action.previousAddresses
			};

		case types.GET_SERVICE_CATEGORIES_SUCCESSFUL:
			return {
				...state,
				serviceCategories: action.serviceCategories
			};

		case types.GET_COMPANIES_SUCCESSFUL:
			let companiesHashMap = {};
			action.companies.forEach((c) => {
				companiesHashMap[c.conceptId] = c;
			});
			return {
				...state,
				companies: action.companies,
				companiesHashMap,
				loading: false
			};
			
		case types.SELECTED_SERVICE_FROM_SEARCH:
			return {
				...state,
				selectedServiceCategory: action.catergorySelected
			};

		case types.RESET_DATA:
			return {
				...state,
				locationMethod: 'gps',
				selectedAddress: null,
				selectedCompany: null,
				customerInfoTemp: null,
				scheduledDate: null,
				scheduledTime: null,
				serviceQuestionAnswers: null,
				customerOptionsSelected: null,
				additionalInfo: null,
				franchiseServices: null,
				techAvailability: null,
		currentSelectedFranchiseFees: null,
		bookLeadSuccess: false
			};

		case types.SET_CUSTOMER_INFO:
			return {
				...state,
				customerInfoTemp: action.customerInfo
			};

		case types.GET_CUSTOM_SERVICE_QUESTIONS_SUCCESSFUL:
			return {
				...state,
				customServiceQuestions: action.customServiceQuestions
			};

		case types.SAVE_SERVICE_QUESTIONS:
			return {
				...state,
				serviceQuestionAnswers: action.serviceQuestionAnswers,
				techAvailability:null
			};

		case types.SAVE_SCHEDULED_DATE_AND_TIME:
			return {
				...state,
				scheduledDateTimeObj: action.scheduledDateTimeObj
			};

		case types.GET_BRANDS_SUCCESSFUL:
			return {
				...state,
				brands: action.brands
			};

		case types.SAVE_BRAND_INFO:
			return {
				...state,
				customerBrandInfo: {
					brandId: action.brandId
				}
			};

		case types.SAVE_OPTION_INFO:
			return {
				...state,
				customerOptionsSelected: action['options']
			};

		case types.SAVE_ADDITIONAL_INFO:
			return {
				...state,
				additionalInfo: action.additionalInfo
			};

		case types.GET_NEARBY_FRANCHISES_SUCCESSFUL:
			var { nearbyFranchises } = action;
			return {
				...state,
				nearbyFranchises
			};
		case types.GET_NEARBY_FRANCHISES_FAILED:
			var nearbyFranchises = [];

			return {
				...state,
				nearbyFranchises
			};

		case types.GET_NEARBY_SINGULAR_FRANCHISES_SUCCESSFUL:
			var { nearbyFranchises } = action;

			return {
				...state,
				singularNearbyFranchises: nearbyFranchises
			};

		case types.CLEAR_NEARBY_SINGULAR_FRANCHISE:
			return {
				...state,
				singularNearbyFranchises: null
			};

		case types.GET_FRANCHISE_DETAIL_INFO_SUCCESS:
			var { selectedCompany } = state;
			selectedCompany.franchiseDetails = action.franchiseDetailInfo;
			return {
				...state,
				selectedCompany
			};

		case types.GET_FRANCHISE_SERVICES_INFO_SUCCESS:
			var { nearbyFranchises } = state;
			var { franchiseServices } = action;
			
			nearbyFranchises.forEach((c) => {
				c.franchiseServices = franchiseServices.filter((fs) => fs.FranchiseId == c.FranchiseId);
			});
			return {
				...state,
				nearbyFranchises,
				franchiseServices
			};

		case types.GET_FRANCHISE_ROUND_ROBIN_INFO_SUCCESS:
			var { nearbyFranchises } = state;
			var { franchiseRoundRobinInfo } = action;
			nearbyFranchises.forEach((c) => {
				if (c.Franchise.ConceptId == franchiseRoundRobinInfo[0].ConceptID) {
					c.franchiseRoundRobinData = franchiseRoundRobinInfo[0];
				}
			});

			return {
				...state,
				nearbyFranchises
			};

		case types.GET_SERVICE_SEARCH_TERM_RESULTS_SUCCESS:
			var { searchTermResults } = action;

			return {
				...state,
				searchTermResults
			};

		case types.CLEAR_NEARBY_FRANCHISES:
			return {
				...state,
				nearbyFranchises: null
			};

		case types.GET_CUSTOMERS_BASED_ON_LOCATION_SUCCESS:
			return {
				...state,
				customersByLocation: action.customers
			};

		case types.GET_CUSTOMERS_BY_BRAND_SUCCESSFUL:
			return {
				...state,
				customersByBrand: action.customers
			};

		case types.GET_CUSTOMERS_BY_SEARCH_CRITERIA_SUCCESSFUL:
			return {
				...state,
				customersBySearchCriteria: action.customers
			};

		case types.SELECT_POSTAL_CODE:
			return {
				...state,
				activePostalCode: action.postalCode
			};

		case types.GET_SERVICE_TYPE_PROBLEMS_SUCCESSFUL:
			var { serviceTypeProblemHashMap } = state;
			serviceTypeProblemHashMap[action.serviceSkuId] = action.serviceTypeProblems;
			return {
				...state,
				serviceTypeProblemHashMap
			};

		case types.GET_FRANCHISE_SERVICES_INFO_NEW_SUCCESS:
			var { franchiseServices } = action;
			return {
				...state,
				franchiseServicesNew: franchiseServices
			};

		case types.GET_TECH_AVAILABILITY_SUCCESSFUL:
			return {
				...state,
				techAvailability: action.techAvailability
			};
		case types.GET_TECH_AVAILABILITY_FAILED:
			return {
				...state,
				error: action.payload.response.data.Message
			};

		case types.CLEAR_SELECTED_CUSTOMER_INFO:
			return {
				...state,
				customerInfoTemp: null
			};

		case types.LANDING_SCREEN_LOADED:
			return {
				...state,
				landingPageLoaded: true
			};

		case types.GET_SMARTWARE_PRIMARY_LEAD_SOURCES_SUCCESSFUL:
			return {
				...state,
				smartwarePrimaryLeadSources: action.smartwarePrimaryLeadSources
			};

		case types.GET_CLEO_PRIMARY_LEAD_SOURCES_SUCCESSFUL:
			return {
				...state,
				CLEOPrimaryLeadSources: action.CLEOPrimaryLeadSources
			};

		case types.GET_SMARTWARE_SECONDARY_LEAD_SOURCES_SUCCESSFUL:
			return {
				...state,
				smartwareSecondaryLeadSources: action.smartwareSecondaryLeadSources
			};

		case types.GET_CLEO_SECONDARY_LEAD_SOURCES_SUCCESSFUL:
			return {
				...state,
				CLEOSecondaryLeadSources: action.CLEOSecondaryLeadSources
			};

		case types.BOOK_LEAD_SUCCESS:
			return {
				...state,
				bookLeadSuccess: true
			};

		case types.GET_FRANCHISE_FEES:
      return {
        ...state
      }

    case types.GET_FRANCHISE_FEES_SUCCESS:
      return {
        ...state,
        currentSelectedFranchiseFees: action.franchiseFees
      }

	  case types.STATES_BASEDON_ZIPCODE_SUCCESS:
		console.log('statesBasedOn',action.statesBasedOnLocation)
		return {
		  ...state,
		  statesBasedOnLocation: action.statesBasedOnLocation
		}
		
		default:
			return state;
	}
};

export default schedulingReducer;
