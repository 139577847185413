import * as types from "../actions/index";

let loginState = {
	userLoggedIn: false,
	loginFailure: false,
  loginFailureMessage: null,
  loggedInUser: null,
  passwordExpiryDate : '',
  changePasswordFailed:'',
  changepasswordSuccess : ''
};
const loginReducer = (state = loginState, action) => {
  switch (action.type) {
    case types.USER_LOGIN_SUCCESS:
      return {
        ...state,
        userLoggedIn: true,
        loggedInUser: action.user,
        loginFailure: false,
        loginFailureMessage: null,
      };

     case types.USER_LOGIN_FAILURE:
     	return {
     		...state,
        userLoggedIn: false,
        loginFailure: true,
        loginFailureMessage: action.message
     	};

    case types.USER_LOGIN_FAILURE_RESET:
      return {
        ...state,
        userLoggedIn: false,
        loginFailure: false,
        loginFailureMessage: null
      }
    
    case types.GET_LOGGED_IN_USER_INFO_SUCCESS:
      let associatedFranchiseIds = action.claims
      .filter(c => c.Type == "FranForce.EmployedFranchiseId" || c.Type == "FranForce.OwnedFranchiseId")
      .map(c => c.Value);

      return {
        ...state,
        email: action.email,
        firstName: action.firstName,
        lastName: action.lastName,
        userId: action.userId,
        passwordExpiryDate:action.passwordExpiryDate,
        associatedFranchiseIds,
      };

      case types.UPDATE_EXPIRY_PASSWORD_SUCCESS:
        return {
          ...state,
          changepasswordSuccess : action.passwordChangedSuccess,
          changePasswordFailed : ''
        };

      case types.UPDATE_EXPIRY_PASSWORD_FAILED:
        return {
          ...state,
          changePasswordFailed : action.passwordChangedFailed
        };

        case types.UPDATE_EXPIRED_PASSWORD_SUCCESS:
        return {
          ...state,
          passwordUpdatedSuccess : action.updatedExpiredpassword,
          FailedExpiredPassword : ''
        };

      case types.UPDATE_EXPIRED_PASSWORD_FAILED:
        return {
          ...state,
          FailedExpiredPassword : action.failedExpiredPassword
        };
      
    default:
      return state;
  }
};

export default loginReducer;