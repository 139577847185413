import {EventStatuses} from "../system";
import loginService from "../services/loginService";
import schedulingService from "../services/schedulingService";

export const NEW_SYSTEM_EVENT = "NEW_SYSTEM_EVENT";
export const EVENT_STATUS_CHANGE = "EVENT_STATUS_CHANGE";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGOUT = "USER_LOGOUT"
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_LOGIN_FAILURE_RESET = "USER_LOGIN_FAILURE_RESET";
export const COMPANY_SELECTED_FOR_APPOINTMENT = "COMPANY_SELECTED_FOR_APPOINTMENT";
export const USER_ADDRESS_SUBMITTED_FOR_APPOINTMENT_SUCCESS = "USER_ADDRESS_SUBMITTED_FOR_APPOINTMENT_SUCCESS";
export const RETRIEVE_ADDRESSES_FOR_APPOINTMENT_SUCCESS = "RETRIEVE_ADDRESSES_FOR_APPOINTMENT_SUCCESS";
export const GET_SERVICE_CATEGORIES_SUCCESSFUL = "GET_SERVICE_CATEGORIES_SUCCESSFUL";
export const GET_COMPANIES_SUCCESSFUL = "GET_COMPANIES_SUCCESSFUL";
export const RESET_DATA = "RESET_DATA";
export const SET_CUSTOMER_INFO = "SET_CUSTOMER_INFO";
export const GET_CUSTOM_SERVICE_QUESTIONS_SUCCESSFUL = "GET_CUSTOM_SERVICE_QUESTIONS_SUCCESSFUL";
export const SAVE_SERVICE_QUESTIONS = "SAVE_SERVICE_QUESTIONS";
export const SAVE_SCHEDULED_DATE_AND_TIME = "SAVE_SCHEDULED_DATE_AND_TIME";
export const GET_BRANDS_SUCCESSFUL = "GET_BRANDS_SUCCESSFUL";
export const SAVE_BRAND_INFO = "SAVE_BRAND_INFO";
export const SAVE_OPTION_INFO = "SAVE_OPTION_INFO";
export const SAVE_ADDITIONAL_INFO = "SAVE_ADDITIONAL_INFO";
export const SCHEDULING_SERVICE_LOADING = "SCHEDULING_SERVICE_LOADING";
export const GET_LOGGED_IN_USER_INFO_SUCCESS = "GET_LOGGED_IN_USER_INFO_SUCCESS";
export const GET_NEARBY_FRANCHISES_SUCCESSFUL = "GET_NEARBY_FRANCHISES_SUCCESSFUL";
export const GET_NEARBY_FRANCHISES_FAILED = "GET_NEARBY_FRANCHISES_FAILED";
export const GET_FRANCHISE_DETAIL_INFO_SUCCESS = "GET_FRANCHISE_DETAIL_INFO_SUCCESS";
export const GET_FRANCHISE_SERVICES_INFO_SUCCESS = "GET_FRANCHISE_SERVICES_INFO_SUCCESS";
export const GET_FRANCHISE_ROUND_ROBIN_INFO_SUCCESS = "GET_FRANCHISE_ROUND_ROBIN_INFO_SUCCESS";
export const GET_SERVICE_SEARCH_TERM_RESULTS_SUCCESS = "GET_SERVICE_SEARCH_TERM_RESULTS_SUCCESS";
export const CLEAR_NEARBY_FRANCHISES = "CLEAR_NEARBY_FRANCHISES";
export const GET_CUSTOMERS_BASED_ON_LOCATION_SUCCESS = "GET_CUSTOMERS_BASED_ON_LOCATION_SUCCESS";
export const SELECT_POSTAL_CODE = "SELECT_POSTAL_CODE";
export const GET_SERVICE_TYPE_PROBLEMS_SUCCESSFUL = "GET_SERVICE_TYPE_PROBLEMS_SUCCESSFUL";
export const GET_FRANCHISE_SERVICES_INFO_NEW_SUCCESS = "GET_FRANCHISE_SERVICES_INFO_NEW_SUCCESS";
export const GET_TECH_AVAILABILITY_SUCCESSFUL = "GET_TECH_AVAILABILITY_SUCCESSFUL";
export const GET_TECH_AVAILABILITY_FAILED = "GET_TECH_AVAILABILITY_FAILED";
export const QUEUE_MESSAGE = "QUEUE_MESSAGE";
export const MESSAGE_DISPLAYED = "MESSAGE_DISPLAYED";
export const DEQUEUE_MESSAGE = "DEQUEUE_MESSAGE";
export const CLEAR_SELECTED_CUSTOMER_INFO = "CLEAR_SELECTED_CUSTOMER_INFO";
export const LANDING_SCREEN_LOADED = "LANDING_SCREEN_LOADED";
export const GET_CUSTOMERS_BY_SEARCH_CRITERIA_SUCCESSFUL = "GET_CUSTOMERS_BY_SEARCH_CRITERIA_SUCCESSFUL";
export const GET_CUSTOMERS_BY_BRAND_SUCCESSFUL = "GET_CUSTOMERS_BY_BRAND_SUCCESSFUL";
export const SET_LOCATION_SERVICES_STATUS = "SET_LOCATION_SERVICES_STATUS";
export const GET_SMARTWARE_PRIMARY_LEAD_SOURCES_SUCCESSFUL = "GET_SMARTWARE_PRIMARY_LEAD_SOURCES_SUCCESSFUL";
export const GET_CLEO_PRIMARY_LEAD_SOURCES_SUCCESSFUL = "GET_CLEO_PRIMARY_LEAD_SOURCES_SUCCESSFUL";
export const GET_SMARTWARE_SECONDARY_LEAD_SOURCES_SUCCESSFUL = "GET_SMARTWARE_SECONDARY_LEAD_SOURCES_SUCCESSFUL";
export const GET_CLEO_SECONDARY_LEAD_SOURCES_SUCCESSFUL = "GET_CLEO_SECONDARY_LEAD_SOURCES_SUCCESSFUL";
export const BOOK_LEAD_SUCCESS = "BOOK_LEAD_SUCCESS";
export const RESET_TECH_AVAILABILITY = "RESET_TECH_AVAILABILITY";
export const GET_NEARBY_SINGULAR_FRANCHISES_SUCCESSFUL = "GET_NEARBY_SINGULAR_FRANCHISES_SUCCESSFUL";
export const CLEAR_NEARBY_SINGULAR_FRANCHISE = "CLEAR_NEARBY_SINGULAR_FRANCHISE";
export const GET_FRANCHISE_FEES = "GET_FRANCHISE_FEES"
export const GET_FRANCHISE_FEES_SUCCESS = "GET_FRANCHISE_FEES_SUCCESS"
export const GET_FRANCHISE_FEES_ERROR = "GET_FRANCHISE_FEES_ERROR"
export const SEND_PASSWORD_RESET_EMAIL = "SEND_PASSWORD_RESET_EMAIL"
export const SEND_PASSWORD_RESET_EMAIL_SUCCESS = "SEND_PASSWORD_RESET_EMAIL_SUCCESS"
export const CLEAR_FORGOT_PASSWORD_EMAIL = "CLEAR_FORGOT_PASSWORD_EMAIL"
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS"
export const STATES_BASEDON_ZIPCODE = "STATES_BASEDON_ZIPCODE"
export const STATES_BASEDON_ZIPCODE_SUCCESS = "STATES_BASEDON_ZIPCODE_SUCCESS"
export const STATES_BASEDON_ZIPCODE_FAILED= "STATES_BASEDON_ZIPCODE_FAILED"
export const UPDATE_EXPIRY_PASSWORD_SUCCESS= "UPDATE_EXPIRY_PASSWORD_SUCCESS"
export const UPDATE_EXPIRY_PASSWORD_FAILED= "UPDATE_EXPIRY_PASSWORD_FAILED"
export const UPDATE_EXPIRED_PASSWORD_SUCCESS= "UPDATE_EXPIRED_PASSWORD_SUCCESS"
export const UPDATE_EXPIRED_PASSWORD_FAILED= "UPDATE_EXPIRED_PASSWORD_FAILED"
export const SELECTED_SERVICE_FROM_SEARCH= "SELECTED_SERVICE_FROM_SEARCH"

export const startEvent = eventName => {
  return {
    type: EVENT_STATUS_CHANGE,
    eventStatus: EventStatuses.PENDING,
    eventName
  };
};

export const completeEvent = eventName => {
  return {
    type: EVENT_STATUS_CHANGE,
    eventStatus: EventStatuses.COMPLETED,
    eventName
  };
};

export const failEvent = eventName => {
  return {
    type: EVENT_STATUS_CHANGE,
    eventStatus: EventStatuses.FAILED,
    eventName
  };
};

export const acknowledgeEvent = eventName => {
  return {
    type: EVENT_STATUS_CHANGE,
    eventStatus: EventStatuses.ACKNOWLEDGED,
    eventName
  };
};

export const login = (username, password, bioAuthEnrolled) => {
	return dispatch => {
		loginService.login(username, password, bioAuthEnrolled, dispatch);
	};
};

export const loginSuccess = user => {
	return {
		type: USER_LOGIN_SUCCESS,
    user
	};
};

export const logout = () => {
	return {
		type: USER_LOGOUT,
	};
};

export const loginFailure = message => {
	return {
		type: USER_LOGIN_FAILURE,
    message
	};
};

export const loginFailureAcknowledged = () => {
  return {
    type: USER_LOGIN_FAILURE_RESET
  };
};

export const getLoggedInUserInformation = () => {
  return dispatch => {
    loginService.getLoggedInUserInformation(dispatch);
  }
}

export const getLoggedInUserInformationSuccess = ({Email, FirstName, LastName, UserID, Claims,PassWordExpirationDate}) => {
  return {
    type: GET_LOGGED_IN_USER_INFO_SUCCESS,
    email: Email,
    firstName: FirstName,
    lastName: LastName,
    userId: UserID,
    claims: Claims,
    passwordExpiryDate : PassWordExpirationDate
  };
};

export const selectCompanyForAppointment = selectedCompany => {
  return dispatch => {
    schedulingService.selectCompanyForAppointment(selectedCompany, dispatch);
  }
};

export const statesBasedOnZipcode = region => {
  return dispatch => {
    schedulingService.selectedRegion(region, dispatch);
  }
};

export const selectCompanyForAppointmentSuccess = company => {
  return {
    type: COMPANY_SELECTED_FOR_APPOINTMENT,
    selectedCompany: company
  };
}

export const submitAddressForAppointment = (address, shouldPreserve, method) => {
  return dispatch => {
    schedulingService.submitAddressForAppointment(address, shouldPreserve, method, dispatch);
  };
};

export const submitAddressForAppointmentSuccess = (selectedAddress, method) => {
  return {
    type: USER_ADDRESS_SUBMITTED_FOR_APPOINTMENT_SUCCESS,
    selectedAddress,
    method
  };
};

export const getPreviousAddressesForAppointment = () => {
  return dispatch => {
    schedulingService.getPreviousAddressesForAppointment(dispatch);
  };
};

export const getPreviousAddressesForAppointmentSuccess = previousAddresses => {
  return {
    type: RETRIEVE_ADDRESSES_FOR_APPOINTMENT_SUCCESS,
    previousAddresses
  };
};

export const getServiceCategories = () => {
  return dispatch => {
    schedulingService.getServiceCategories(dispatch);
  };
};

export const getServiceCategoriesSuccessful = serviceCategories => {
  return {
    type: GET_SERVICE_CATEGORIES_SUCCESSFUL,
    serviceCategories
  };
};

export const getCompanies = () => {
  return dispatch => {
    schedulingService.getCompanies(dispatch);
  };
};

export const getCompaniesSuccessful = companies => {
  return {
    type: GET_COMPANIES_SUCCESSFUL,
    companies
  };
};

export const resetData = () => {
  return {
    type: RESET_DATA
  };
};

export const setCustomerInfo = customerInfo => {
  return dispatch => {
    schedulingService.setCustomerInfo(customerInfo, dispatch);
  };
};

export const updateExpiryPasswordInfo = (oldPassword,newPassword,confirmPassword) => {
  return dispatch => {
    loginService.updateExpiryPasswordInfo(oldPassword,newPassword,confirmPassword, dispatch);
  };
};

export const updateExpiredPassword = (oldPassword,newPassword,confirmPassword,email) => {
  return dispatch => {
    loginService.updateExpiredPassword(oldPassword,newPassword,confirmPassword,email, dispatch);
  };
};

export const updateExpiryPasswordInfoSuccess = (passwordChangedSuccess) => {
  return {
    type: UPDATE_EXPIRY_PASSWORD_SUCCESS,
    passwordChangedSuccess
  };
};

export const updateExpiryPasswordInfoFailed = passwordChangedFailed => {
  return {
    type: UPDATE_EXPIRY_PASSWORD_FAILED,
    passwordChangedFailed
  };
};

export const updateExpiredPasswordSuccess = updatedExpiredpassword => {
  return {
    type: UPDATE_EXPIRED_PASSWORD_SUCCESS,
    updatedExpiredpassword
  };
};

export const updateExpiredPasswordFailed = failedExpiredPassword => {
  return {
    type: UPDATE_EXPIRED_PASSWORD_FAILED,
    failedExpiredPassword
  };
};

export const setCustomerInfoSuccess = customerInfo => {
  return {
    type: SET_CUSTOMER_INFO,
    customerInfo
  };
}

export const getCustomServiceQuestions = () => {
  return dispatch => {
    schedulingService.getCustomServiceQuestions(dispatch);
  };
};

export const getCustomServiceQuestionsSuccessful = customServiceQuestions => {
  return {
    type: GET_CUSTOM_SERVICE_QUESTIONS_SUCCESSFUL,
    customServiceQuestions
  };
};

export const saveServiceQuestions = serviceQuestionAnswers => {
  return dispatch => {
    schedulingService.saveServiceQuestions(serviceQuestionAnswers, dispatch);
  };
};

export const saveServiceQuestionsSuccess = serviceQuestionAnswers => {
  return {
    type: SAVE_SERVICE_QUESTIONS,
    serviceQuestionAnswers
  };
}

export const saveScheduledDateAndTime = (scheduledDateTimeObj) => {
  return dispatch => {
    schedulingService.saveScheduledDateAndTime(scheduledDateTimeObj, dispatch);
  }
};

export const saveScheduledDateAndTimeSuccess = (scheduledDateTimeObj) => {
  return {
    type: SAVE_SCHEDULED_DATE_AND_TIME,
    scheduledDateTimeObj
  };
}

export const getBrands = (conceptCode) => {
  return dispatch => {
    schedulingService.getBrands(conceptCode, dispatch);
  };
};

export const getbrandsSuccessful = brands => {
  return {
    type: GET_BRANDS_SUCCESSFUL,
    brands
  };
};

export const saveBrandInformation = ({brandId}) => {
  return dispatch => {
    schedulingService.saveBrandInformation(brandId, dispatch);
  };
};

export const saveBrandInformationSuccess = brandId => {
  return {
    type: SAVE_BRAND_INFO,
    brandId
  };
};

export const saveOptionsSelected = options => {
  return dispatch => {
    schedulingService.saveOptionsSelected(options, dispatch);
  };
};

export const saveOptionsSelectedSuccess = options => {
  return {
    type: SAVE_OPTION_INFO,
    options
  };
};

export const saveAdditionalInfo = additionalInfo => {
  return dispatch => {
    schedulingService.saveAdditionalInfo(additionalInfo, dispatch);
  };
};

export const saveAdditionalInfoSuccess = additionalInfo => {
  return {
    type: SAVE_ADDITIONAL_INFO,
    additionalInfo
  };
};

export const showLoading = () => {
  return {
    type: SCHEDULING_SERVICE_LOADING
  };
};

export const getNearbyFranchises = zipCode => {
  return dispatch => {
    schedulingService.getNearbyFranchises(zipCode, dispatch);
  };
};

export const getNearbyFranchisesFilteredByServiceId = (zipCode, serviceId) => {
  return dispatch => {
    schedulingService.getNearbyFranchisesFilteredByServiceId(zipCode, serviceId, dispatch);
  };
};

export const getNearbyFranchisesByCoordinates = (latitude, longitude) => {
  return dispatch => {
    schedulingService.getNearbyFranchisesByCoordinates(latitude, longitude, dispatch);
  };
};

export const getNearbyFranchisesFilteredByServiceIdWithCoordinates  = (latitude, longitude, serviceId) => {
  return dispatch => {
    schedulingService.getNearbyFranchisesFilteredByServiceIdWithCoordinates(latitude, longitude, serviceId, dispatch);
  };
};

export const getNearbyFranchisesSuccessful = nearbyFranchises => {
  return {
    type: GET_NEARBY_FRANCHISES_SUCCESSFUL,
    nearbyFranchises
  };
};
export const getNearbyFranchisesFailed = () => {
  return {
    type: GET_NEARBY_FRANCHISES_FAILED,
  };
};


export const getStatesSuccessful = statesBasedOnLocation => {
  return {
    type: STATES_BASEDON_ZIPCODE_SUCCESS,
    statesBasedOnLocation
  };
};
export const getStatesFailed = () => {
  return {
    type: STATES_BASEDON_ZIPCODE_FAILED,
  };
};

export const getFranchiseDetailInfo = franchiseWebLocationId => {
  return dispatch => {
    schedulingService.getFranchiseDetailInfo(franchiseWebLocationId, dispatch);
  };
};

export const getFranchiseDetailInfoSuccessful = franchiseDetailInfo => {
  return {
    type: GET_FRANCHISE_DETAIL_INFO_SUCCESS,
    franchiseDetailInfo
  };
};

export const getFranchiseServicesInfo = franchiseLicenseIds => {
  return dispatch => {
    schedulingService.getFranchiseServicesInfo(franchiseLicenseIds, dispatch);
  };
};

export const getFranchiseServicesInfoSuccessful = franchiseServices => {
  return {
    type: GET_FRANCHISE_SERVICES_INFO_SUCCESS,
    franchiseServices
  };
};

export const getFranchiseRoundRobinInfo = (conceptCode, zipCode) => {
  return dispatch => {
    schedulingService.getFranchiseRoundRobinInfo(conceptCode, zipCode, dispatch);
  };
};

export const getFranchiseRoundRobinInfoSuccessful = franchiseRoundRobinInfo => {
  return {
    type: GET_FRANCHISE_ROUND_ROBIN_INFO_SUCCESS,
    franchiseRoundRobinInfo
  };
};

export const getServiceSearchTermResults = (searchTerm,zipCode,serviceModifierValue) => {
  return dispatch => {
    schedulingService.getServiceSearchTermResults(searchTerm,zipCode,serviceModifierValue, dispatch);
  };
};

export const getServiceSearchTermResultsSuccessful = searchTermResults => {
  return {
    type: GET_SERVICE_SEARCH_TERM_RESULTS_SUCCESS,
    searchTermResults
  };
};

export const clearNearbyFranchises = () => {
  return {
    type: CLEAR_NEARBY_FRANCHISES
  };
};

export const getCustomersBasedOnLocation = (franchiseIds, lat, lng,) => {
  return dispatch => {
    schedulingService.getCustomersBasedOnLocation(franchiseIds, lat, lng, dispatch);
  };
};

export const getCustomersBasedOnLocationSuccessful = customers => {
  return {
    type: GET_CUSTOMERS_BASED_ON_LOCATION_SUCCESS,
    customers
  };
};

export const selectPostalCode = postalCode => {
  return {
    type: SELECT_POSTAL_CODE,
    postalCode
  };
};

export const getServiceTypeProblems = serviceSkuId => {
  return dispatch => {
    schedulingService.getServiceTypeProblems(serviceSkuId, dispatch);
  };
};

export const getServiceTypeProblemsSuccessful = (serviceSkuId, serviceTypeProblems) => {
  return {
    type: GET_SERVICE_TYPE_PROBLEMS_SUCCESSFUL,
    serviceSkuId,
    serviceTypeProblems
  };
};

export const getFranchiseServicesInfoNew = () => {
  return dispatch => {
    schedulingService.getFranchiseServicesInfoNew(dispatch);
  };
};

export const getFranchiseServicesInfoNewSuccessful = franchiseServices => {
  return {
    type: GET_FRANCHISE_SERVICES_INFO_NEW_SUCCESS,
    franchiseServices
  };
};


export const getTechAvailability = techAvailabilityParamsObj => {
  return dispatch => {
    schedulingService.getTechAvailability(techAvailabilityParamsObj, dispatch);
  }
}

export const getTechAvailabilitySuccessful = techAvailability => {
  return {
    type: GET_TECH_AVAILABILITY_SUCCESSFUL,
    techAvailability
  };
};
export const getTechAvailabilityFailed = error => {
  console.log("=======", error)
  return {
    type: GET_TECH_AVAILABILITY_FAILED,
    payload: error
  };
};

export const bookLead = leadBookObj => {
  return dispatch => {
    schedulingService.bookLead(leadBookObj, dispatch);
  };
};

export const queueMessage = (title, body) => {
  return {
    type: QUEUE_MESSAGE,
    message: {
      title,
      body
    }
  };
};

export const acknowledgeMessageDisplayed = () => {
  return {
    type: MESSAGE_DISPLAYED
  };
};

export const acknowledgeMessageConsumed = () => {
  return {
    type: DEQUEUE_MESSAGE
  };
};

export const clearSelectedCustomerInfo = () => {
  return {
    type: CLEAR_SELECTED_CUSTOMER_INFO
  };
};

export const landingScreenLoaded = () => {
  return {
    type: LANDING_SCREEN_LOADED
  };
};

export const getCustomersBySearchCriteria = ({firstName, lastName, phone, email, address, city, state, zip}) => {
  return dispatch => {
    schedulingService.getCustomersBySearchCriteria(firstName, lastName, phone, email, address, city, state, zip, dispatch);
  };
};

export const getCustomersByBrand = (franchiseIds, lat, lng,) => {
  return dispatch => {
    schedulingService.getCustomersByBrand(franchiseIds, lat, lng, dispatch);
  };
};

export const getCustomersBySearchCriteriaSuccessful = customers => {
  return {
    type: GET_CUSTOMERS_BY_SEARCH_CRITERIA_SUCCESSFUL,
    customers
  };
};

export const getCustomersByBrandSuccessful = customers => {
  return {
    type: GET_CUSTOMERS_BY_BRAND_SUCCESSFUL,
    customers
  };
};

export const setLocationServicesAsDisabled = () => {
  return {
    type: SET_LOCATION_SERVICES_STATUS,
    locationServicesEnabled: false
  };
};

export const bookLeadSuccessful = () => {
  return {
    type: BOOK_LEAD_SUCCESS
  };
};

export const resetTechAvailability = () => {
  return {
    type: RESET_TECH_AVAILABILITY
  };
};

export const getNearbySingularFranchiseSuccessful = nearbyFranchises => {
  return {
    type: GET_NEARBY_SINGULAR_FRANCHISES_SUCCESSFUL,
    nearbyFranchises
  };
};

export const getNearbySingularFranchise = (fullAddress, conceptCode) => {
  return dispatch => {
    schedulingService.getNearbySingularFranchise(fullAddress, conceptCode, dispatch);
  };
};

export const clearNearbySingularFranchise = () => {
  return {
    type: CLEAR_NEARBY_SINGULAR_FRANCHISE
  };
};

export const getFeesForFranchise = (franchiseId) => {
  return dispatch => {
    schedulingService.getFeesForFranchise(franchiseId, dispatch);
  };
};

export const getFeesForFranchiseSuccess = (franchiseFees) => {
  return {
    type: GET_FRANCHISE_FEES_SUCCESS,
    franchiseFees
  };
}

export const getFeesForFranchiseFailed = error => {
  return {
    type: GET_FRANCHISE_FEES_ERROR,
    payload: error
  };
};

export const sendForgotPasswordEmail = (email) => {
  return dispatch => {
    loginService.sendForgotPasswordEmail(dispatch, email)
  }
};

export const sendForgotPasswordEmailSuccess = () => {
  return {
    type: SEND_PASSWORD_RESET_EMAIL_SUCCESS
  };
};

export const clearForgotPasswordEmailStatus = () => {
  return dispatch => {
    loginService.clearForgotPasswordEmail(dispatch)
  }
};

export const clearSentForgotPasswordEmail = () => {
  return dispatch => {
    loginService.clearSentForgotPassword(dispatch)
  }
};

export const updatePassword = (password, email, guid) => {
  return dispatch => {
    loginService.updatePassword(dispatch, password, email, guid)
  }
};

export const updatePasswordSuccess = () => {
  return {
    type: UPDATE_PASSWORD_SUCCESS
  };
};


export const selectedServiceFromSearch = (catergorySelected) => {
  return {
    type: SELECTED_SERVICE_FROM_SEARCH,
    catergorySelected
  };
}