import React, {Component} from "react";

class SubmitPageBusinessSpecificInfo extends Component {
  render() {
    let {brandName, problemsToFix, additionalInfo, itemToFix} = this.props;
    return (
      <div
        style={{
          width: "100%",
          padding: 20,
          marginTop: 20,
          borderBottomWidth: 1,
          borderBottomColor: "darkgrey",
          borderBottomStyle:"solid",
          display: "block"
        }}
      >
        <div style={{width: "100%", fontSize: 26, fontWeight: "bold"}}>
          Extra Information
        </div>
        {/*Row*/}
        <div style={{width: "100%", flexDirection: "row", marginTop: 10}}>
          <div style={{flexDirection: "column", flex: 1, alignItems: "flex-start", color: "#565656"}}>
            <div style={{fontSize: 18, marginTop: 2, marginBottom: 2, fontWeight: "bold"}}>Brand</div>
            <div style={{fontSize: 18, marginTop: 2, marginBottom: 2}}>
              {
                brandName
              }
            </div>
          </div>
        </div>
        {/*Row*/}
        <div style={{width: "100%", flexDirection: "row", marginTop: 10}}>
          <div style={{flexDirection: "column", flex: 1, alignItems: "flex-start", color: "#565656"}}>
            <div style={{fontSize: 18, marginTop: 2, marginBottom: 2, fontWeight: "bold"}}>Repair Needs</div>
            <div style={{fontSize: 18, marginTop: 2, marginBottom: 2}}>
              {
                problemsToFix
              }
            </div>
          </div>
        </div>
        {/*Row*/}
        {
          additionalInfo &&
          <div style={{width: "100%", flexDirection: "row", marginTop: 10}}>
            <div style={{flexDirection: "column", flex: 1, alignItems: "flex-start", color: "#565656"}}>
              <div style={{fontSize: 18, marginTop: 2, marginBottom: 2, fontWeight: "bold"}}>Issue</div>
              <div style={{fontSize: 18, marginTop: 2, marginBottom: 2}}>
                {
                  additionalInfo
                }
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default SubmitPageBusinessSpecificInfo;