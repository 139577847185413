import React, {Component} from "react";

class SubmitPageAppointmentInfo extends Component {
  render() {
    let {scheduledDateTimeObj, serviceType} = this.props;
    return (
      <div
        style={{
          width: "100%",
          padding: 20,
          marginTop: 20,
          borderBottomWidth: 1,
          borderBottomColor: "darkgrey",
          borderBottomStyle:"solid",
          display: "block"
        }}
      >
        <div  className={`neighborHeaderTextBig`} style={{width: "100%", fontWeight: "bold"}}>
          Appointment Information
        </div>
        {/*Row*/}
        {
          scheduledDateTimeObj &&
          <div style={{width: "100%", flexDirection: "row", marginTop: 10}}>
            <div style={{flexDirection: "column", flex: 1, alignItems: "flex-start", color: "#565656"}}>
              <div className={`neighborHeaderTextLarge`} style={{ marginTop: 2, marginBottom: 2, fontWeight: "bold"}}>Service Date</div>
              <div className={`neighborHeaderTextLarge`} style={{ marginTop: 2, marginBottom: 2}}>
                {
                  `${scheduledDateTimeObj.date} (${scheduledDateTimeObj.dayOfWeek}) ${scheduledDateTimeObj.timeSlot}`
                }
              </div>
            </div>
          </div>
        }
        {/*Row*/}
        {
          serviceType &&
          <div style={{width: "100%", flexDirection: "row", marginTop: 10}}>
            <div style={{flexDirection: "column", flex: 1, alignItems: "flex-start", color: "#565656"}}>
              <div className={`neighborHeaderTextLarge`} style={{ marginTop: 2, marginBottom: 2, fontWeight: "bold"}}>Service Type</div>
              <div className={`neighborHeaderTextLarge`} style={{ marginTop: 2, marginBottom: 2}}>
                {
                  this.props.franchiseServices.find(fs => fs.serviceSkuId == serviceType) ? 
                  this.props.franchiseServices.find(fs => fs.serviceSkuId == serviceType).serviceSkuName : serviceType
                }
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default SubmitPageAppointmentInfo;