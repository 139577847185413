import React, {Component} from "react";
import { connect } from "react-redux";
import LoggedInContainerHeader from "../Components/LoggedInContainer/LoggedInContainerHeader";
import {AvForm, AvGroup, AvField, AvCheckboxGroup, AvCheckbox} from "availity-reactstrap-validation";
import {Col, Row} from "reactstrap";
import SquareButton from "../Components/SquareButton";
import {Redirect} from "react-router-dom";
import {
  resetData,
  getBrands,
  saveBrandInformation,
  saveOptionsSelected,
  acknowledgeEvent
} from "../actions/index";
import {EventStatuses} from "../system";

class BrandInformation extends Component {
  constructor(props) {
    super(props);
    this.redirectEndpoint = null;
  }

  componentDidMount(){
    this.props.getBrands();
  }

  componentDidUpdate() {
    let {eventStatuses} = this.props;
    let {COMPLETED} = EventStatuses;
    if (
      eventStatuses["saveBrandInformation"] === COMPLETED &&
      eventStatuses["saveOptionsSelected"] === COMPLETED &&
      this.props.customerBrandInfo &&
      this.props.customerOptionsSelected &&
      this.redirectEndpoint
    ) {
      this.props.acknowledgeEvent("saveBrandInformation");
      this.props.acknowledgeEvent("saveOptionsSelected");
      this.props.history.push(this.redirectEndpoint);
    }
  }

  saveBrandInfo = (event, values) => {
    let {
      brandSelection,
      issues
    } = values;

    this.redirectEndpoint = "/calendar";
    this.props.saveBrandInformation({brandId: brandSelection});
    this.props.saveOptionsSelected(issues);
  }

  render() {
    return (
      <div style={{width: "100%", flexDirection: "column"}}>
        {
          !this.props.selectedCompany &&
          <Redirect to="/landing"/>
        }
        <LoggedInContainerHeader
          history={this.props.history}
          resetData={this.props.resetData}
          showCancelButton={true}
        />
        <div style={{width: "100%", overflowY: "scroll", display: "block"}}>
          <div style={{width: "100%", paddingRight: 20, paddingLeft: 20, alignItems: "center", justifyContent: "center"}}>
            <AvForm onValidSubmit={this.saveBrandInfo} style={{width: "100%", maxWidth: 800, marginBottom: 50}}>
              <Row>
                <div style={{width: "100%", marginTop: 30, marginBottom: 30, fontSize: 28, paddingLeft: 15}}>
                  Brand Information
                </div>
              </Row>
              <Row>
                <Col xs="12" sm="12">
                  <AvGroup style={{width: "100%"}}>
                    <AvField
                      name="brandSelection"
                      placeholder="Select Your Service"
                      required
                      type="select"
                    >
                      <option value="">--</option>
                      {
                        this.props.brands &&
                        this.props.brands.map((b, k) => {
                          return <option key={k} value={b.brandId}>{b.brandName}</option>;
                        })
                      }
                    </AvField>
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <div style={{width: "100%", marginTop: 30, marginBottom: 30, fontSize: 28, paddingLeft: 15}}>
                  Issues
                </div>
              </Row>
              <Row>
                <Col xs="12" sm="12">
                  <AvCheckboxGroup name="issues">
                    <AvCheckbox label="Noisy" value="Noisy"/>
                    <AvCheckbox label="Not Draining" value="Not Draining"/>
                    <AvCheckbox label="Door" value="Door"/>
                    <AvCheckbox label="Not Cleaning Dishes" value="Not Cleaning Dishes"/>
                    <AvCheckbox label="Controller/Timer" value="Controller/Timer"/>
                    <AvCheckbox label="Etching Glasses" value="Etching Glasses"/>
                  </AvCheckboxGroup>
                </Col>
              </Row>

              <Row>
                <SquareButton                  
                  text="NEXT"
                  buttonstyle={{height: 50, width: "100%", backgroundColor: "rgb(38, 125, 191)", marginTop: 10}}
                  textstyle={{fontFamily: global.themeFontSemiBold, color: "white", fontSize: 16}}
                />
              </Row>
            </AvForm>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    selectedCompany,
    brands,
    serviceQuestionAnswers,
    customerBrandInfo,
    customerOptionsSelected
  } = state.schedulingReducer;
  const {eventStatuses} = state.systemReducer;
  return {
    ...state,
    selectedCompany,
    brands,
    serviceQuestionAnswers,
    customerBrandInfo,
    customerOptionsSelected,
    eventStatuses
  };
}

export default connect(mapStateToProps, {
  resetData,
  getBrands,
  saveBrandInformation,
  saveOptionsSelected,
  acknowledgeEvent
})(BrandInformation);