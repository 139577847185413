import {
  loginSuccess,
  loginFailure,
  startEvent,
  completeEvent,
  failEvent,
  getLoggedInUserInformationSuccess,
  sendForgotPasswordEmailSuccess,
  acknowledgeEvent,
  updatePasswordSuccess,
  updateExpiryPasswordInfoSuccess,
  updateExpiryPasswordInfoFailed,
  updateExpiredPasswordSuccess,
  updateExpiredPasswordFailed,
  logout
} from "../actions/index";
import BaseService from "./baseService";
import axios from "axios";

const LOGIN_URL = "/Token";
const LOGGED_IN_USER_INFO_URL = "/Account/UserInfo";
const FORGOT_PASSWORD_SEND_EMAIL = "/Account/ForgotPasswordSend?type=ran";
const FORGOT_PASSWORD_UPDATE = "/Account/ForgotPasswordReset";
const UPDATE_EXPIRY_PASSWORD = "/Account/ChangePassword";
const UPDATE_EXPIRED_PASSWORD = "/Account/UpdateExpiredPassword"


class LoginService extends BaseService {
  async login(username, password, bioAuthEnrolled, dispatch) {
    dispatch(startEvent("login"));
    try {
      var data = `userName=${encodeURIComponent(username)}&password=${password}&grant_type=password`;
      const options = this.axiosOptions()
        .asPOST()
        .withURLEncodedHeaders()
        .withData(data)
        .withURL(global.authBaseUrl + LOGIN_URL)
        .build();
      const response = await axios(options);
      var responseData = response.data;
      await localStorage.setItem("token", responseData.access_token);

      if (responseData.error) {
        dispatch(failEvent("login"));
        dispatch(loginFailure("Username or Password is incorrect"));
      } else {
        dispatch(completeEvent("login"));
        dispatch(loginSuccess({}));
      }
    } catch (error) {
      console.log('error',error.response.data.error_description)
      dispatch(failEvent("login"));
      if(error.response.data.error_description){
        dispatch(loginFailure(error.response.data.error_description));
      }else{
        dispatch(loginFailure("An error occurred while attempting to login"));
      }
    }
  }

  async getLoggedInUserInformation(dispatch) {
    dispatch(startEvent("getLoggedInUserInformation"));
    const token = await localStorage.getItem("token");
    try {
      const options = this.axiosOptions()
        .asGET()
        .withJSONHeaders(token)
        .withURL(global.authBaseUrl + LOGGED_IN_USER_INFO_URL)
        .build();

      const response = await axios(options);
      var responseData = response.data;

      console.log("getLoggedInUserInformation response: ", response);

      dispatch(getLoggedInUserInformationSuccess(responseData));
      dispatch(completeEvent("getLoggedInUserInformation"));
    } catch (error) {
      console.log("getLoggedInUserInformation error:", error);
      dispatch(failEvent("getLoggedInUserInformation"));
      if(this.validateTokenExpiry(token)){
        dispatch(logout());
        localStorage.clear();
        window.location.href =  window.location.origin + '/#/login'
      }
    }
  }

  async sendForgotPasswordEmail(dispatch, email) {
    dispatch(startEvent("sendForgotPasswordEmail"));
    try {
      const options = this.axiosOptions()
        .asPOST()
        .withData(`"${email}"`)
        .withJSONHeaders()
        .withURL(global.authBaseUrl + FORGOT_PASSWORD_SEND_EMAIL)
        .build();

      console.log(
        "TCL: LoginService -> sendForgotPasswordEmail -> global.authBaseUrl + FORGOT_PASSWORD_SEND_EMAIL",
        global.authBaseUrl + FORGOT_PASSWORD_SEND_EMAIL
      );
      console.log("TCL: LoginService -> updatePassword -> options", JSON.stringify(options));

      const response = await axios(options);
      var responseData = response.data;

      console.log("TCL: LoginService -> sendForgotPasswordEmail -> responseData", responseData);

      dispatch(sendForgotPasswordEmailSuccess());
      dispatch(completeEvent("sendForgotPasswordEmail"));
    } catch (error) {
      console.log("sendForgotPasswordEmail error:", error.response.data.Message);
      dispatch(failEvent("sendForgotPasswordEmail"));
    }
  }

  async updatePassword(dispatch, password, email, resetGuid) {
    console.log("TCL: LoginService -> updatePassword -> password, email, resetGuid", password, email, resetGuid);
    dispatch(startEvent("updatePassword"));
    try {
      const options = this.axiosOptions()
        .asPOST()
        .withJSONHeaders()
        .withData({
          email,
          resetGuid,
          newPassword: password,
          newPasswordConfirm: password
        })
        .withURL(global.authBaseUrl + FORGOT_PASSWORD_UPDATE)
        .build();

      console.log("TCL: LoginService -> updatePassword -> options", options);

      const response = await axios(options);
      var responseData = response.data;

      console.log("responseData: ", responseData);
      dispatch(updatePasswordSuccess());
      dispatch(completeEvent("updatePassword"));
    } catch (error) {
      console.log("updatePassword error:", error);
      console.log("TCL: LoginService -> updatePassword -> error", JSON.stringify(error));
      dispatch(failEvent("updatePassword"));
    }
  }

  async clearForgotPasswordEmail(dispatch) {
    dispatch(acknowledgeEvent("updatePassword"));
  }

  async clearSentForgotPassword(dispatch) {
    dispatch(acknowledgeEvent("sendForgotPasswordEmail"));
  }

  async updateExpiryPasswordInfo(oldPassword,newPassword,confirmPassword,dispatch) {
    dispatch(startEvent("updateExpiryPasswordInfo"));
    const token = await localStorage.getItem("token");
    try {
      const options = this.axiosOptions()
        .asPOST()
        .withJSONHeaders(token)
        .withData({
          ConfirmPassword: confirmPassword,
          NewPassword: newPassword,
          OldPassword: oldPassword
        })
        .withURL(global.authBaseUrl + UPDATE_EXPIRY_PASSWORD)
        .build();
      const response = await axios(options);
      const changePasswordSuccess = {
        success : true,
        status : response.status
      }
      dispatch(updateExpiryPasswordInfoSuccess(changePasswordSuccess));
      dispatch(completeEvent("updateExpiryPasswordInfo"));
    } catch (error) {
      const errorResponse = error.response.data.ModelState;
      dispatch(updateExpiryPasswordInfoFailed(errorResponse));
      dispatch(failEvent("updateExpiryPasswordInfo"));
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
    }
  }

  async updateExpiredPassword(oldPassword,newPassword,confirmPassword,email,dispatch) {
    dispatch(startEvent("updateExpiredPassword"));
    const token = await localStorage.getItem("token");
    try {
      const options = this.axiosOptions()
        .asPOST()
        .withJSONHeaders(token)
        .withData({
          NewPasswordConfirm: confirmPassword,
          NewPassword: newPassword,
          OldPassword: oldPassword,
          Email : email
        })
        .withURL(global.authBaseUrl + UPDATE_EXPIRED_PASSWORD)
        .build();
      const response = await axios(options);
      const passwordUpdatedSuccess = {
        success : true,
        status : response.status,
        statusText : response.statusText
      }
      dispatch(updateExpiredPasswordSuccess(passwordUpdatedSuccess));
      dispatch(completeEvent("updateExpiredPassword"));
    } catch (error) {
      const errorResponse = error.response.data.Message;
      dispatch(updateExpiredPasswordFailed(errorResponse));
      dispatch(failEvent("updateExpiredPassword"));
      if(this.validateTokenExpiry(token)){
        localStorage.clear();
        dispatch(logout());
        window.location.href =  window.location.origin + '/#/login'
      }
    }
  }

  updateExpiredPassword
}

export default new LoginService();
