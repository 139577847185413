import React, {Component} from "react";
import {Modal, ModalBody, Col} from "reactstrap";
import SquareButton from "./SquareButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Alert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  show = () => {
    this.setState({
      visible: true
    });
  }

  showWithMessage = (subject, message, hasCancelButton, confirmText) => {
    this.setState({
      visible: true,
      subject,
      message,
      hasCancelButton,
      confirmText
    });
  }

  render() {
    return (
      <Modal centered isOpen={this.state.visible} toggle={() =>{this.setState({visible: false});}}>
        {/* <ModalHeader>

        </ModalHeader> */}
        <ModalBody>
        {
          (this.state.subject || this.state.message) ?
            <div style={{width: "100%", flexDirection: "column"}}>
              <div style={{width: "100%", flexDirection: "row"}}>
                <div style={{width: "100%", fontSize: 20, fontWeight: "bold", fontFamily: global.themeFontExtraBold}}>{this.state.subject || ""}</div>
                <div
                  onClick={() =>{this.setState({visible: false});}}
                  style={{width: 50, alignItems: "center", justifyContent: "center", cursor: "pointer"}}
                >
                  <FontAwesomeIcon icon="times"/>
                </div>
              </div>
              <div style={{width: "100%", marginTop: 20, marginBottom: 20, flexDirection: "row"}}>
                <Col md="12">
                  <div style={{fontFamily: global.themeFontRegular}}>
                    {this.state.message || ""}
                  </div>
                </Col>
              </div>
              <div style={{width: "100%", flexDirection: "row"}}>
                {
                  this.state.hasCancelButton &&
                    <SquareButton
                      onClick={()=>{
                        this.setState({visible: false});
                      }}
                      text="CANCEL"
                      buttonstyle={{height: 40, flex: 1, backgroundColor: "white", borderWidth: 1, borderColor: "rgb(38, 125, 191)", margin: 10}}
                      textstyle={{fontFamily: global.themeFontSemiBold, color: "rgb(38, 125, 191)", fontSize: 14}}
                    />
                }
                <SquareButton
                  onClick={()=>{
                    if (this.props.onConfirm) {
                      this.props.onConfirm();
                    }            
                    this.setState({visible: false});
                  }}
                  text={this.state.confirmText || "OK"}
                  buttonstyle={{height: 40, flex: 1, backgroundColor: "rgb(38, 125, 191)", margin: 10}}
                  textstyle={{fontFamily: global.themeFontSemiBold, color: "white", fontSize: 14}}
                />
              </div>
            </div> :
            this.props.children
          }
        </ModalBody>
      </Modal>
    );
  }
}

export default Alert;