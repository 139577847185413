import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { AvForm, AvGroup, AvField} from "availity-reactstrap-validation";
import { Toast, ToastHeader, ToastBody ,Col, Row} from "reactstrap";
import SquareButton from "../Components/SquareButton";
import {
  updateExpiryPasswordInfo,logout,updateExpiredPassword
} from '../actions';
import { connect } from 'react-redux';


class PasswordModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        modal: true,
        modalMessage: "",
        newPasswordError : '',
        confirmPasswordFailed:'',
        shouldDisplayModal: false,
        toastDisplay:false
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
    localStorage.setItem('showModalStatus',true)
  }

  componentDidUpdate() {
    if(this.props.changepasswordSuccess.success === true && !this.state.shouldDisplayModal && this.state.toastDisplay) {
      this.setState({ modalMessage: "Successfully updated password. You will be navigated to the login page.", shouldDisplayModal: true ,toastDisplay : false });
      setTimeout(() => {
        this.props.logout();
        localStorage.clear();
        this.props.history.push('/login');
      }, 2000);
    }else if(this.props.changePasswordFailed && !this.props.changePasswordFailed[""]){
      if(this.props.changePasswordFailed['model.ConfirmPassword'] && this.props.changePasswordFailed['model.NewPassword'] && !this.state.shouldDisplayModal && this.state.toastDisplay) {
        this.setState({ modalMessage: 'The new password and confirmation password do not match ,also new password must be at least 12 characters long', shouldDisplayModal: true,toastDisplay : false  });
        setTimeout(() => {
          this.setState({ shouldDisplayModal: false})
        }, 2000);
      }else if(this.props.changePasswordFailed['model.ConfirmPassword'] && !this.state.shouldDisplayModal && this.state.toastDisplay) {
        this.setState({ modalMessage: this.props.changePasswordFailed['model.ConfirmPassword'][0], shouldDisplayModal: true,toastDisplay : false  });
        setTimeout(() => {
          this.setState({ shouldDisplayModal: false})
        }, 2000);
      }else if(this.props.changePasswordFailed['model.NewPassword'] && !this.state.shouldDisplayModal && this.state.toastDisplay) {
        this.setState({ modalMessage: this.props.changePasswordFailed['model.NewPassword'][0], shouldDisplayModal: true,toastDisplay : false  });
        setTimeout(() => {
          this.setState({ shouldDisplayModal: false})
        }, 2000);
      }
    }else if(this.props.changePasswordFailed[""] && !this.state.shouldDisplayModal && this.state.toastDisplay) {
      this.setState({ modalMessage: this.props.changePasswordFailed[""][0], shouldDisplayModal: true,toastDisplay : false });
      setTimeout(() => {
        this.setState({ shouldDisplayModal: false})
      }, 2000);
    }else if(this.props.FailedExpiredPassword && !this.state.shouldDisplayModal && this.state.toastDisplay) {
      this.setState({ modalMessage: this.props.FailedExpiredPassword, shouldDisplayModal: true,toastDisplay : false });
      setTimeout(() => {
        this.setState({ shouldDisplayModal: false})
      }, 2000);
    }else if(this.props.passwordUpdatedSuccess && !this.state.shouldDisplayModal && this.state.toastDisplay) {
      this.setState({ modalMessage: 'Successfully updated password. You will be navigated to the login page.', shouldDisplayModal: true,toastDisplay : false });
      setTimeout(() => {
        this.setState({ shouldDisplayModal: false})
        this.props.logout();
        localStorage.clear();
        this.props.history.push('/login');
      }, 2000);
    }
  }

  passwordSubmit = (event,values) => {
    let {
      oldPassword,
      newPassword,
      confirmPassword,
    } = values;
    this.setState({
      toastDisplay: true
    });
    if(this.props.numOfExpirationDays.expirationDay !== null){
      this.props.updateExpiryPasswordInfo(oldPassword,newPassword,confirmPassword)
    }else{
      const emailVal = localStorage.getItem('email')
      this.props.updateExpiredPassword(oldPassword,newPassword,confirmPassword,emailVal)
    }
  }

  render() {
    let element = document.getElementsByClassName('close')[0];
    if(element && element.style && this.props.numOfExpirationDays.expirationDay == null){
      element.style.display = "none";
    }
    if(element && element.style && this.props.numOfExpirationDays.expirationDay !== null){
      element.onclick = function () { 
        localStorage.setItem('showModalStatus',true)
       }; 
    }
    if(performance.navigation.type === 1){
      localStorage.setItem('showModalStatus',true)
    }

    return (
      <div>
        <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} backdrop="static" style={{width:'48%'}}>
        <Toast
            style={{ position: "absolute", top: 0, left: '660px', minWidth: 300 }}
            isOpen={this.state.shouldDisplayModal}
          >
            <ToastHeader>{this.props.changepasswordSuccess.success === true ? "Success!" : "Error!"}</ToastHeader>
            <ToastBody>{this.state.modalMessage}</ToastBody>
          </Toast>
          {this.props.numOfExpirationDays.expirationDay == null ? 
          <ModalHeader className="modalTitle" toggle={this.toggle}>Neighborly Password Update</ModalHeader>
          : <ModalHeader className="modalTitle" toggle={this.toggle}>Update Password</ModalHeader>  
        }
          <ModalBody style={{display:"block"}}>
            <div style={{borderBottom : '1px solid #e5e5e5',paddingBottom:'8px',marginBottom:'10px'}}>
            {this.props.numOfExpirationDays.expirationDay == null 
           ? <><FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: "8px", marginTop: '5px', color: '#FAD957' }} /><span style={{ color: '#333', fontSize: '18px' }}> Your password has expired, please change password now</span></>
            : <><FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: "8px", marginTop: '5px', color: '#FAD957' }} /><span style={{ color: '#333', fontSize: '18px' }}>Your password will expire in: <span style={{ fontWeight: 500 }}>{this.props.numOfExpirationDays.expirationDay} days</span></span></>
          }
            
            </div>
            <div style={{display:'inline',color:'rgb(38, 125, 191)'}}>
            <img alt="info-circle" style={{width:'16px',height:'16px',marginRight:'5px'}} src={require('../Assets/information.png')} />  Your new password must:
            <div style={{padding:'10px',fontSize:'.8em'}}>
            <ul>
                <li>Contain at least one capital letter.</li>
                <li>Contain at least one lowercase letter.</li>
                <li>Contain at least one number.</li>
                <li>Contain at least one special character.</li>
                <li>Be at least 12 characters long.</li>
                <li>Cannot be the same as old password.</li>
            </ul>
            </div>
            </div>

            <div>
            <AvForm onValidSubmit={this.passwordSubmit} style={{ width: "100%"}}>
              <Row>
                <Col xs="12" sm="12">
                  <AvGroup style={{ width: "100%" }} className="passwordField">
                    <AvField
                      name="oldPassword"
                      label="Old Password"
                      type="password"
                      required
                    />
                  </AvGroup>
                </Col>
                </Row>
                <Row>
                <Col xs="12" sm="12">
                  <AvGroup style={{ width: "100%" }} className="passwordField">
                    <AvField
                      name="newPassword"
                      label="New Password"
                      type="password"
                      required
                    />
                  </AvGroup>
                </Col>
                </Row>
                <Row>
                <Col xs="12" sm="12">
                  <AvGroup style={{ width: "100%" }} className="passwordField">
                    <AvField
                      name="confirmPassword"
                      label="Confirm Password"
                      type="password"
                      required
                    />
                  </AvGroup>
                </Col>
              </Row>
              <Row style={{justifyContent:'center'}}>
                <SquareButton
                  className="boxShadow contactCreation updatePasswordBtn"
                  text="Update Password"
                  textstyle={{ fontFamily: global.themeFontSemiBold, color: "white", fontSize: 16 ,width:'95%'}}
                />
              </Row>
              </AvForm>
            </div>
          </ModalBody>
          {this.props.numOfExpirationDays.expirationDay !== null && 
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>Skip for now</Button>
          </ModalFooter>
          }
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
	const {
    changepasswordSuccess,changePasswordFailed,passwordExpiryDate,email,FailedExpiredPassword,
    passwordUpdatedSuccess
	} = state.loginReducer;

  return {
		...state,
    changepasswordSuccess,
    changePasswordFailed,
    passwordExpiryDate,
    FailedExpiredPassword,
    passwordUpdatedSuccess,
    email
	};
}

export default connect(mapStateToProps, {updateExpiryPasswordInfo,logout,updateExpiredPassword})(PasswordModal);