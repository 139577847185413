import React, {Component} from "react";
import {connect} from "react-redux";
import LoggedInContainerHeader from "../Components/LoggedInContainer/LoggedInContainerHeader";
import ConfirmationPageHeader from "../Components/Confirmation/ConfirmationPageHeader";
import ConfirmationPageServices from "../Components/Confirmation/ConfirmationPageServices";
import ConfirmationPageAbout from "../Components/Confirmation/ConfirmationPageAbout";
import {
  getFranchiseDetailInfo,
  getFranchiseServicesInfo,
  acknowledgeEvent
} from "../actions";
import {EventStatuses} from "../system";
import {Redirect} from "react-router-dom";

class Confirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null
    };
  }

  componentDidMount() {
    this.props.selectedCompany &&
    this.props.getFranchiseDetailInfo(this.props.selectedCompany.franchiseWebLocationId);
  }

  componentDidUpdate(prevProps) {
      window.scrollTo(0, 0);
    if ((this.props.eventStatuses["getFranchiseDetailInfo"] === EventStatuses.COMPLETED || this.props.eventStatuses["getFranchiseDetailInfo"] === EventStatuses.FAILED)) {
      this.props.acknowledgeEvent("getFranchiseDetailInfo");
    }
    if ((this.props.eventStatuses["getFranchiseServicesInfo"] === EventStatuses.COMPLETED || this.props.eventStatuses["getFranchiseServicesInfo"] === EventStatuses.FAILED)) {
      this.props.acknowledgeEvent("getFranchiseServicesInfo");
    }
  }
  render() {

    let filteredFranchiseService=[];
    if(this.props.selectedCompany && this.props.selectedCompany.conceptId){
      filteredFranchiseService = this.props.franchiseServicesNew.filter(fs => fs.conceptId == this.props.selectedCompany.conceptId)
    }
    // console.log(this.props.franchiseServicesNew.filter(fs => fs.conceptId == this.props.selectedCompany.conceptId))
    return (
      <div style={{width: "100%", flexDirection: "column", alignItems: "center"}}>
        {
          ((!this.props.history) || (!this.props.selectedCompany)) &&
          <Redirect to="/landing"/>
        }
        {
          this.state.redirect
        }
        <LoggedInContainerHeader
          history={this.props.history}
        />
        <div style={{width: "100%", marginTop: 50, display: "block", maxWidth: 800}}>
          <div style={{flexDirection: "column", width: "100%"}}>
            <ConfirmationPageHeader
              history={this.props.history}
            />
          </div>
          <div style={{flexDirection: "column", width: "100%"}}>
            <ConfirmationPageServices
              servicesOffered={filteredFranchiseService}
            />
          </div>        
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    selectedCompany,
    franchiseServicesNew
  } = state.schedulingReducer;
  const {eventStatuses} = state.systemReducer;
  return {
    ...state,
    selectedCompany,
    franchiseServicesNew,
    eventStatuses
  }
}

export default connect(mapStateToProps, {getFranchiseDetailInfo, getFranchiseServicesInfo, acknowledgeEvent})(Confirmation);