import React, { Component } from "react";
import { connect } from "react-redux";
import {
    getFranchiseServicesInfo,
    saveServiceQuestions
} from "../../actions";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    FormGroup
} from "reactstrap";
import { EventStatuses } from "../../system";
class HouseMasterServiceQuestions extends Component {
    submitServiceQuestions = (event, values) => {
        let {
            serviceSelection,
            bathroomCount,
            bedroomCount,
            squareFootage
        } = values;
        let data = {
            serviceSelection,
            bathroomCount,
            bedroomCount,
            squareFootage
        };
        this.props.saveServiceQuestions(data);
    }
    render() {
        return (
            <div style={{ width: "100%", flexDirection: "column" }}>
                <AvForm onValidSubmit={this.submitServiceQuestions} id="HMS-service-question-form">
                    <div style={{ width: "100%", marginTop: 30, marginBottom: 30, fontSize: 28, fontFamily: "Montserrat SemiBold" }}>
                        How Can We Help You?
          </div>
                    <FormGroup row
                        style={{ marginLeft: 0, marginRight: 0 }}
                    >
                        <AvField
                            disabled={this.props.eventStatuses["getFranchiseServicesInfo"] === EventStatuses.PENDING}
                            name="serviceSelection"
                            type="select"
                            placeholder="Select Your Service"
                            required
                        >
                            <option value="">--</option>
                            {
                                this.props.franchiseServices &&
                                this.props.franchiseServices.map((fs, k) => {
                                    return <option value={fs.serviceSkuId} key={k}>{fs.serviceSkuName}</option>;
                                })
                            }
                        </AvField>
                    </FormGroup>                    
                    <div style={{ width: "100%", marginTop: 30, marginBottom: 30, fontSize: 28, fontFamily: "Montserrat SemiBold" }}>
                        Estimated Square Footage
                    </div>
                    <FormGroup row
                        style={{ marginLeft: 0, marginRight: 0 }}
                    >
                        <AvField
                            name="squareFootage"
                            type="number"
                            min="0"
                        />
                    </FormGroup>
                </AvForm>
            </div>
        );
    }
}
function mapStateToProps(state) {
    const {
        brands,
        selectedCompany,
        serviceTypeProblemsHashMap,
        franchiseServices,
    } = state.schedulingReducer;
    const { eventStatuses } = state.systemReducer;
    return {
        ...state,
        brands,
        selectedCompany,
        serviceTypeProblemsHashMap,
        franchiseServices,
        eventStatuses
    };
}
export default connect(mapStateToProps, {
    getFranchiseServicesInfo,
    saveServiceQuestions
}, null, { forwardRef: true })(HouseMasterServiceQuestions);