import * as types from "../actions/index";
import {EventStatuses} from "../system";

let systemState = {
  eventStatuses: {},
  messageQueue: []
};

const systemReducer = (state = systemState, action) => {
  let {messageQueue} = state;
  let {message} = action;
  switch (action.type) {
    case types.EVENT_STATUS_CHANGE:
      var {eventStatuses} = state;
      eventStatuses[action.eventName] = action.eventStatus;

      if (eventStatuses[action.eventName] === EventStatuses.ACKNOWLEDGED) {
        delete eventStatuses[action.eventName];
      }

      return {
        ...state,
        eventStatuses
      };

    case types.QUEUE_MESSAGE:
      message.displayed = false;
      messageQueue.push(message);

      return {
        ...state,
        messageQueue
      };

    case types.MESSAGE_DISPLAYED:
      if (messageQueue.length > 0) {
        messageQueue[0].displayed = true;
      }

      return {
        ...state,
        messageQueue
      };

    case types.DEQUEUE_MESSAGE:
      if (messageQueue.length > 0) {
        messageQueue.shift();
      }

      return {
        ...state,
        messageQueue
      };
    
    default:
      return state;
  }
};

export default systemReducer;