import React, {Component} from "react";

class CustomerListPageRow extends Component {
  render() {
    let {customer} = this.props;
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          cursor: "pointer"
        }}
        onClick={()=>{this.props.onSelect(customer);}}
      >
        
  <div className="card">
  <h3 style={{color:'#267dbf',marginTop:10}}> {
          customer.name
          }</h3>
            <p style={{marginBottom:0,marginTop:6}}>{
            `${customer.street} ${(customer.street2 || "")}`
          }</p>
            <p>{
            `${customer.city}, ${customer.state} ${customer.zip}`
            }</p>
  </div>
      </div>
    );
  }
}

export default CustomerListPageRow;