import React, { Component } from "react";
import { Label, Input, FormGroup, Button, Form, Toast, ToastHeader, ToastBody } from "reactstrap";
import Alert from "../Components/Alert";
import { updatePassword, clearForgotPasswordEmailStatus } from "../actions/index";
import { connect } from "react-redux";
import queryString from "query-string";
import { EventStatuses } from "../system";

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    if(!queryString.parse(this.props.location.search).reset || !queryString.parse(this.props.location.search).email)
      this.props.history.push('/login');

    this.state = {
      password: "",
      confirmPassword: "",
      resetGuid: queryString.parse(this.props.location.search).reset,
      email: queryString.parse(this.props.location.search).email,
      modalMessage: "",
      shouldDisplayModal: false,
      oldEmailStatus: false,
    };
  }

  confirmPassword() {
    let { password, confirmPassword } = this.state;

    // REGEX FOR PASSWORD MATCH
    // ^              The password string will start this way
    // (?=.*[a-z])	  The string must contain at least 1 lowercase alphabetical character
    // (?=.*[A-Z])	  The string must contain at least 1 uppercase alphabetical character
    // (?=.*[0-9])	  The string must contain at least 1 numeric character
    // (?=.{6,})	    The string must be six characters or longer
    var strongPasswordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");

    if (!password.match(strongPasswordRegex)) {
      return new Error("Password does not meet specifications.");
    }

    if (!confirmPassword.match(strongPasswordRegex)) {
      return new Error("Confirm password does not meet specifications.");
    }

    if (confirmPassword !== password) {
      return new Error("Passwords do not match.");
    }

    this.props.updatePassword(this.state.password, this.state.email, this.state.resetGuid);
  }

  didSubmit(e) {
    if (e) e.preventDefault();

    let error = this.confirmPassword();
    if (error) {
      this.setState({ modalMessage: error.toString(), shouldDisplayModal: true });
      setTimeout(() => {
        this.setState({ shouldDisplayModal: false });
      }, 3000);
    }
  }

  componentDidUpdate() {
    if(this.props.updatePasswordStatus === EventStatuses.COMPLETED && !this.state.shouldDisplayModal) {
      this.setState({ modalMessage: "Successfully reset password. You will be navigated to the home page.", shouldDisplayModal: true });
      setTimeout(() => {
        this.props.clearForgotPasswordEmailStatus();
        this.props.history.push('/login');
      }, 2000);
    }

    if(this.props.updatePasswordStatus === EventStatuses.FAILED && !this.state.shouldDisplayModal) {
      this.setState({ modalMessage: "Error: Unable to send forgot password email. Please try again later.", shouldDisplayModal: true });
      setTimeout(() => {
        this.props.clearForgotPasswordEmailStatus();
        this.setState({ shouldDisplayModal: false })
      }, 2000);
    }
  }

  didPressCancel() {
    this.props.history.push('/login');
  }

  render() {
    return (
      <div style={styles.mainContainer}>
        <div style={styles.topContentContainer}>
          <Toast
            style={{ position: "absolute", top: 25, right: 25, minWidth: 500 }}
            isOpen={this.state.shouldDisplayModal}
          >
            <ToastHeader>{this.state.modalMessage.includes("Error") ? "Error!" : "Success!"}</ToastHeader>
            <ToastBody>{this.state.modalMessage}</ToastBody>
          </Toast>
          <img alt="" style={styles.logo} src={require("../Assets/neighborlyBaseLogo_Large.png")} />
          <div style={styles.cardContainer}>
            <div style={styles.cardTitle}>Enter New Password</div>
            <Form onSubmit={this.didSubmit.bind(this)}>
              <FormGroup>
                <Label style={styles.montserrat}>Password</Label>
                <Input
                  type="password"
                  onChange={({ target: { value } }) => {
                    this.setState({ password: value });
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label style={styles.montserrat}>Confirm Password</Label>
                <Input
                  type="password"
                  onChange={({ target: { value } }) => {
                    this.setState({ confirmPassword: value });
                  }}
                />
              </FormGroup>
              <FormGroup>
                <div style={styles.montserrat}>Your new password must:</div>
                <li style={styles.montserratRegular}>Contain at least one capital letter.</li>
                <li style={styles.montserratRegular}>Contain at least one lowercase letter.</li>
                <li style={styles.montserratRegular}>Contain at least one number.</li>
                <li style={styles.montserratRegular}>Be at least 6 characters long.</li>
              </FormGroup>
              <FormGroup>
                <Button type="submit" style={styles.floatRightPrimary}>
                  Submit
                </Button>
                <Button onClick={this.didPressCancel.bind(this)} style={styles.floatRight}>
                  Cancel
                </Button>
              </FormGroup>
            </Form>
          </div>
        </div>

        <Alert ref={re => (this.alertModal = re)} />
      </div>
    );
  }
}

const styles = {
  cardContainer: {
    backgroundColor: "white",
    maxWidth: 800,
    width: "95%",
    margin: "0 auto",
    padding: 20,
    paddingBottom: 10,
    borderRadius: 10,
    marginTop: 40,
    display: "flex",
    flexDirection: "column"
  },
  floatRight: {
    float: "right",
    marginLeft: 10,
    marginTop: 10
  },
  floatRightPrimary: {
    float: "right",
    marginLeft: 10,
    marginTop: 10,
    backgroundColor: "#267DBF"
  },
  cardTitle: {
    fontFamily: "Montserrat ExtraBold",
    overflow: "hidden",
    fontSize: 20,
    margin: "0 auto"
  },
  montserrat: {
    fontFamily: "Montserrat SemiBold"
  },
  montserratRegular: {
    fontFamily: "Montserrat Regular"
  },
  logo: {
    maxWidth: 800
  },
  topContentContainer: {
    flex: 3,
    width: "100%",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center"
  },
  mainContainer: {
    marginTop: "-20vh",
    flex: 1,
    backgroundImage: "linear-gradient(rgb(38, 125, 191), rgb(38, 125, 191), rgb(255, 255, 255))",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
};

function mapStateToProps(state) {
  const { eventStatuses } = state.systemReducer;
  const updatePasswordStatus = eventStatuses['updatePassword']
  return {
    ...state,
    eventStatuses,
    updatePasswordStatus
  };
}

export default connect(
  mapStateToProps,
  {
    updatePassword,
    clearForgotPasswordEmailStatus
  }
)(ResetPassword);
