class SmartwareBookingValidation {
  constructor(selectedCompany, serviceQuestionAnswers, customerInfo, scheduledDateTime, additionalInfo, userId) {
    this.selectedCompany = selectedCompany;
    this.serviceQuestionAnswers = serviceQuestionAnswers;
    this.customerInfo = customerInfo;
    this.scheduledDateTime = scheduledDateTime;
    this.additionalInfo = additionalInfo;
    this.userId = userId;
  }

  buildBookingPayload = () => {
    let bookingPayload = {
      licenseNumber: this.selectedCompany.licenseNumber,
      webLocationId: this.selectedCompany.franchiseWebLocationId,
      serviceSkuId: this.serviceQuestionAnswers.serviceSelection,
      firstName: this.customerInfo.FirstName,
      lastName: this.customerInfo.LastName,
      email: this.customerInfo.Email,
      phone: this.customerInfo.PhoneNumber || this.customerInfo.Phone,
      phoneType: "Mobile", //un-hardcode later
      //phone2: "5128675309", //add value for later
      //phoneType2: "Work", //un-hardcode later
      preferredCommunicationType: this.customerInfo.ContactMethod || "Email",
      emailOptOut: false, //un-hardcode later
      address: this.customerInfo.Street,
      city: this.customerInfo.City,
      state: this.customerInfo.State,
      postalCode: this.customerInfo.Zip,
      jobPromisedDate: this.scheduledDateTime ? this.scheduledDateTime.date : null,
      note: this.additionalInfo,
      warranty: this.serviceQuestionAnswers.applianceUnderWarranty ? this.serviceQuestionAnswers.applianceUnderWarranty.toLowerCase() == "yes" : false,
      makeId: (this.serviceQuestionAnswers.brandId || 0),
      leadSourceId: "2",
      //secondaryLeadSourceId: this.serviceQuestionAnswers.secondaryLeadSource,
      serviceTypeProblem: (this.serviceQuestionAnswers.serviceTypeProblem || 0),
      leadSource: "Referral",
      leadOrigin: "Referral",
      campaignName: "Test Campaign", //un-hardcode later?
      disposition: "Residential Appointment",
      callType: "Inbound",
      isTest: false,
      scheduleId: this.scheduledDateTime ? this.scheduledDateTime.scheduleId : null,
      isLeadOnly: !(this.scheduledDateTime ? this.scheduledDateTime.scheduleId : null),
      conceptCalledId: this.selectedCompany.conceptId,
      isEmergencyCall: false,
      RANLoginTypeID: "1",
      RANUserID: this.userId,
      ServiceSkuId:this.serviceQuestionAnswers.serviceSelection
    };

    if (!bookingPayload.secondaryLeadSourceId) { delete bookingPayload.secondaryLeadSourceId;}

    return bookingPayload;
  }
}

export default SmartwareBookingValidation;