import React, { Component } from "react";
import { connect } from "react-redux";
import { EventStatuses } from "../system";
import LandingPageHeader from "../Components/Landing/LandingPageHeader";
import LandingPageServiceSearch from "../Components/Landing/LandingPageServiceSearch";
import LandingPageServiceRow from "../Components/Landing/LandingPageServiceRow";
import PasswordModal from "../Components/passwordModal";
import {
  getCompanies,
  getServiceCategories,
  selectCompanyForAppointment,
  acknowledgeEvent,
  getNearbyFranchises,
  getFranchiseRoundRobinInfo,
  getFranchiseServicesInfo,
  getNearbyFranchisesFilteredByServiceId,
  getServiceSearchTermResults,
  clearNearbyFranchises,
  getNearbyFranchisesByCoordinates,
  getFranchiseServicesInfoNew,
  getNearbyFranchisesFilteredByServiceIdWithCoordinates,
  getFranchiseDetailInfo,
  selectPostalCode,
  landingScreenLoaded,
  selectCompanyForAppointmentSuccess,
  logout,
} from "../actions";
import Loader from "react-loader-spinner";
import Alert from "../Components/Alert";

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownVisible: false,
      activeServiceCategoryId: null,
      refreshing: false,
      zipCode: null,
      redirect: null,
      modalstatus: false,
      isZipcodeEntered: false,
    };
    this.zipCodeChange = false;
    this.getCurrentLocationCordinates =
      this.getCurrentLocationCordinates.bind(this);
  }

  getCurrentLocationCordinates() {
    this.setState({ isZipcodeEntered: false });
    navigator.geolocation.getCurrentPosition(
      (locationData) => {
        let { latitude, longitude } = locationData.coords;
        this.setState({
          latitude,
          longitude,
        });
        this.props.getNearbyFranchisesByCoordinates(latitude, longitude);
        this.props.history.push("/landing");
      },
      (error) => {
        this.props.passwordExpiryDate == null
          ? this.props.history.push("/location")
          : this.props.history.push("/landing");
      },
      { enableHighAccuracy: true, timeout: 20000, maximumAge: 0 }
    );
  }

  componentDidMount() {
    this.props.getCompanies();
    this.props.clearNearbyFranchises();
    this.props.getFranchiseServicesInfoNew();
    if (this.props.locationMethod.toLowerCase() == "gps") {
      this.getCurrentLocationCordinates();
    } else if (
      this.props.selectedAddress &&
      this.props.selectedAddress.zipCode
    ) {
      this.setState({
        zipCode: this.props.selectedAddress.zipCode,
      });
      this.props.getNearbyFranchises(this.props.selectedAddress.zipCode);
    }
    //this.props.landingScreenLoaded();
  }

  componentDidUpdate() {
    if (
      this.props.eventStatuses["getCompanies"] === EventStatuses.COMPLETED ||
      this.props.eventStatuses["getCompanies"] === EventStatuses.FAILED
    ) {
      this.props.acknowledgeEvent("getCompanies");
    }
    if (
      this.props.eventStatuses["getFranchiseServicesInfoNew"] ===
        EventStatuses.COMPLETED ||
      this.props.eventStatuses["getFranchiseServicesInfoNew"] ===
        EventStatuses.FAILED
    ) {
      this.props.acknowledgeEvent("getFranchiseServicesInfoNew");
    }
    if (
      this.props.eventStatuses["getNearbyFranchises"] ===
        EventStatuses.COMPLETED ||
      this.props.eventStatuses["getNearbyFranchises"] === EventStatuses.FAILED
    ) {
      this.props.acknowledgeEvent("getNearbyFranchises");
      this.setState({ refreshing: false });
    }
  }

  onServiceSelect = (serviceCategory) => {
    let { zipCode, latitude, longitude } = this.state;
    if (serviceCategory) {
      latitude && longitude && !zipCode
        ? this.props.getNearbyFranchisesFilteredByServiceIdWithCoordinates(
            latitude,
            longitude,
            serviceCategory.serviceWebCategoryId
          )
        : this.props.getNearbyFranchisesFilteredByServiceId(
            this.state.zipCode || "76710",
            serviceCategory.serviceWebCategoryId
          );
    } else {
      latitude && longitude
        ? this.props.getNearbyFranchisesByCoordinates(latitude, longitude)
        : this.props.getNearbyFranchises(this.state.zipCode || "76710"); //maybe rewrite this to not pull everytime the searchbox gets cleared
    }
  };

  onCompanySelect = (company) => {
    this.redirectEndpoint = "/details";
    //this.props.getFranchiseDetailInfo(company.FranchiseId);
    this.props.selectPostalCode(this.state.zipCode);
    this.props.selectCompanyForAppointmentSuccess(company);
    // this.props.selectCompanyForAppointment(company);
    this.props.history.push(this.redirectEndpoint);
  };

  onCompanySelectAndGo = (company) => {
    this.redirectEndpoint = "/customerlist";
    //this.props.getFranchiseDetailInfo(company.FranchiseId);
    this.props.selectPostalCode(this.state.zipCode);
    //this.props.selectCompanyForAppointment(company);
    this.props.selectCompanyForAppointmentSuccess(company);
    this.props.history.push(this.redirectEndpoint);
  };

  alterDropdownState = (dropdownVisible) => {
    this.setState({ dropdownVisible });
  };

  setActiveZipCode = (zipCode) => {
    this.props.clearNearbyFranchises();
    this.setState({ zipCode });
    this.zipCodeChange = true;
    this.props.getNearbyFranchises(zipCode);
  };

  logoutMethod = () => {
    // setTimeout(() => {
    this.props.logout();
    localStorage.clear();
    //this.props.history.push('/login');
    //   });
  };

  handleZipcodeToggle = (val) => {
    this.setState({
      isZipcodeEntered: val,
    });
  };

  render() {
    let { dropdownVisible } = this.state;
    let expiryDay = Math.ceil(
      (Date.parse(this.props.passwordExpiryDate) - Date.parse(new Date())) /
        86400000
    );
    let expiryObj = {
      email: this.props.loginReducer.email,
      expirationDay: expiryDay,
    };
    const getmodalStatus = localStorage.getItem("showModalStatus");

		


    return (
      <>
        <div style={{ flex: 1, flexDirection: "column", maxHeight: "100%" }}>
          {this.props.passwordExpiryDate &&
            expiryDay <= 30 &&
            getmodalStatus == null && (
              <PasswordModal {...this.props} numOfExpirationDays={expiryObj} />
            )}
          {this.state.redirect}
          <Alert
            ref={(r) => {
              this.companySelectErrorModal = r;
            }}
          />
          <div
            style={{
              position: "fixed",
              top: 0,
              width: "100%",
              minHeight: 300,
              maxHeight: 300,
              zIndex: 2,
              backgroundColor: "#fff",
            }}
          >
            <div
              style={{ position: "relative", width: "100%", height: "100%" }}
            >
              <LandingPageHeader
                firstName={this.props.firstName}
                userName={`${this.props.firstName || ""} ${
                  this.props.lastName || ""
                }`}
                setActiveZipCode={this.setActiveZipCode}
                getLocation={this.getCurrentLocationCordinates}
                userLogout={this.logoutMethod}
                locationMethod={this.props.locationMethod}
                zipCode={this.state.zipCode}
                alterDropdownState={this.alterDropdownState}
                isZipcodeEntered={this.state.isZipcodeEntered}
                handleZipcodeToggle={this.handleZipcodeToggle}
              />
              <LandingPageServiceSearch
                searchIsLoading={
                  this.props.eventStatuses["getServiceSearchTermResults"] ===
                  EventStatuses.PENDING
                }
                alterDropdownState={this.alterDropdownState}
                dropdownVisible={dropdownVisible}
                zipCode={this.state.zipCode}
                serviceCategories={this.props.serviceCategories}
                onServiceSelectFn={this.onServiceSelect}
                searchTermSearch={this.props.getServiceSearchTermResults}
                getNearbyFranchises={this.props.getNearbyFranchises}
              />
            </div>
          </div>
          <div
            className="landing-header-margin"
            style={{
              position: "relative",
              justifyContent: "center",
              backgroundColor: "white",
            }}
          >
            <div
              onClick={() => {
                this.alterDropdownState(false);
              }}
              style={{ width: "100%", maxWidth: 900, flexDirection: "column" }}
            >
              <div
                className="neighborHeaderTextBig"
                style={{
                  width: "100%",
                  height: 50,
                  alignItems: "center",
                  marginTop: 30,
                }}
              >
                <div
                  style={{ marginLeft: 30, fontFamily: "Montserrat SemiBold" }}
                >
                  Nearby Services
                </div>
              </div>
              {this.props.eventStatuses["getFranchiseDetailInfo"] !==
                EventStatuses.PENDING &&
              this.props.eventStatuses["getNearbyFranchises"] !==
                EventStatuses.PENDING &&
              this.props.eventStatuses["getFranchiseServicesInfoNew"] !==
                EventStatuses.PENDING ? (
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    marginBottom: 50,
                  }}
                >
                  {this.props.nearbyFranchises &&
                  this.props.nearbyFranchises.length !== 0 ? (
                    this.props.nearbyFranchises.map((c, k) => {
                      return (
                        <LandingPageServiceRow
                          activeZipCode={this.state.zipCode || "76710"}
                          onSelect={this.onCompanySelect}
                          onSelectAndGo={this.onCompanySelectAndGo}
                          franchiseInfo={c}
                          services={
                            this.props.franchiseServicesNew
                              ? this.props.franchiseServicesNew.filter(
                                  (fs) => fs.conceptId == c.conceptId
                                )
                              : []
                          }
                          key={k}
                        />
                      );
                    })
                  ) : (
                    <div style={{ width: "100%", justifyContent: "center" }}>
                      <h6>
                        {(this.props.nearbyFranchises == null ||
                          this.props.nearbyFranchises !== null) &&
                          `There are no services found in the area you searched. Please check the
												address or zip code.`}
                      </h6>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: 200,
                  }}
                >
                  <Loader type="Oval" color="#a5a9af" height="50" width="50" />
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const {
    selectedCompany,
    companies,
    serviceCategories,
    loading,
    nearbyFranchises,
    companiesHashMap,
    searchTermResults,
    franchiseServices,
    franchiseServicesNew,
    locationMethod,
    selectedAddress,
    landingPageLoaded,
  } = state.schedulingReducer;
  const { firstName, lastName, passwordExpiryDate } = state.loginReducer;
  const { eventStatuses } = state.systemReducer;
  return {
    ...state,
    selectedCompany,
    companies,
    serviceCategories,
    loading,
    eventStatuses,
    firstName,
    lastName,
    passwordExpiryDate,
    nearbyFranchises,
    companiesHashMap,
    searchTermResults,
    franchiseServices,
    franchiseServicesNew,
    locationMethod,
    selectedAddress,
    landingPageLoaded,
  };
}

export default connect(mapStateToProps, {
  getCompanies,
  getServiceCategories,
  selectCompanyForAppointment,
  acknowledgeEvent,
  getNearbyFranchises,
  getFranchiseRoundRobinInfo,
  getFranchiseServicesInfo,
  getNearbyFranchisesFilteredByServiceId,
  getServiceSearchTermResults,
  clearNearbyFranchises,
  getNearbyFranchisesByCoordinates,
  getFranchiseServicesInfoNew,
  getNearbyFranchisesFilteredByServiceIdWithCoordinates,
  getFranchiseDetailInfo,
  selectPostalCode,
  landingScreenLoaded,
  selectCompanyForAppointmentSuccess,
  logout,
})(Landing);
