import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Input} from "reactstrap";
import {
  getCustomersByBrand,
  setCustomerInfo,
  clearSelectedCustomerInfo
} from "../../actions/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomerListPageRow from "./CustomerListPageRow";
import {EventStatuses} from "../../system";
import Loader from "react-loader-spinner";

class MyJobSearchTab extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.clearSelectedCustomerInfo();
    this.props.getCustomersByBrand(this.props.associatedFranchiseIds, null, null);
  }

  onCustomerSelect = customer => {
    this.props.setCustomerInfo(customer);
    this.props.history.push("/customerinfo");
  }

  filterBySearch = e => {
    this.setState({filterText: e.target.value});
  }

  render() {
    return (
      <div style={{width: "100%", flexDirection: "column", alignItems: "center"}}>
        <div style={{width: "100%", height: 40, maxWidth: 800, position: "relative", top: 25}}>
          <div style={{width: "100%", height: "100%", flexDirection: "row", justifyContent: "flex-start"}}>
            <Input
              style={{width: "100%",paddingLeft:40}}
              placeholder="Search By Name"
              onChange={this.filterBySearch}
            />
            <FontAwesomeIcon
              style={{position: "absolute", left: 10, top: 5, width: 18, height: 26,color:"#497cc2"}}
              icon="search"
            />
          </div>
        </div>
        {
          this.props.eventStatuses["getCustomersByBrand"] !== EventStatuses.PENDING ?
          <div style={{width: "100%", maxWidth: 800, display: "block", marginTop: 50,marginBottom:30}}>          
          {
              this.state &&
              this.props.customersByBrand &&
              this.props.customersByBrand.length > 0 ?
              <>
              <div className="cards">
              {this.props.customersByBrand
                .filter(c => !this.state.filterText || c.name.toLowerCase().includes(this.state.filterText.toLowerCase()))
                .map((c, k) => {
                  return (
                    <>
                      <CustomerListPageRow
                        onSelect={this.onCustomerSelect}
                        key={k}
                        customer={c}
                      />
                    </>
                  );
                })}
              </div>
              </>
               :
                <div style={{width: "100%", height: (window.innerHeight - 165), alignItems: "center", justifyContent: "center", color: "lightgrey"}}>
                  There are no customers available
                </div>
            }
          </div> :
          <div style={{width: "100%", height: "100%", alignItems: "center", justifyContent: "center", marginTop: 165}}>
            <Loader
              type="Oval"
              color="#a5a9af"
              height="50"
              width="50"
            />
          </div>
        }

      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    customersByBrand,
    activePostalCode,
    selectedCompany
  } = state.schedulingReducer;
  const {
    associatedFranchiseIds
  } = state.loginReducer;
  const {
    eventStatuses
  } = state.systemReducer;
  return {
    ...state,
    customersByBrand,
    activePostalCode,
    selectedCompany,
    associatedFranchiseIds,
    eventStatuses
  };
}

export default connect(mapStateToProps, {
  getCustomersByBrand,
  setCustomerInfo,
  clearSelectedCustomerInfo
})(MyJobSearchTab);

