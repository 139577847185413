import React, {Component} from "react";
import { connect } from "react-redux";
import LoggedInContainerHeader from "../Components/LoggedInContainer/LoggedInContainerHeader";
import {AvForm,AvRadio, AvRadioGroup} from "availity-reactstrap-validation";
import {FormGroup} from "reactstrap";
import SquareButton from "../Components/SquareButton";
import {Redirect} from "react-router-dom";
import {
  resetData,
} from "../actions/index";

class HasScheduleSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.leadOnlyIneligibleBrands = [
      "PTP",
      "FSP"
    ];
  }

  submitScheduleSelectAnswer = (event, values) => {
    let {scheduledAppointmentTime} = values;
    if (scheduledAppointmentTime == "yes") {
      this.props.history.push("/calendar");
    } else {
      this.props.history.push("/additionalinfo");
    }
  }

  render() {
    if(this.props.selectedCompany){
      let { conceptId } = this.props.selectedCompany;
      var { conceptCode } = this.props.companiesHashMap[conceptId];
    }

    return (      
      <div style={{width: "100%", flexDirection: "column", alignItems: "center"}}>
        {
          !this.props.selectedCompany &&
          <Redirect to="/landing"/>
        }
        <LoggedInContainerHeader
          history={this.props.history}
          resetData={this.props.resetData}
          showCancelButton={true}
        />
        <div style={{alignItems: "center", justifyContent: "center", maxWidth: 800, width: "100%", display: "block", paddingLeft: 20, paddingRight: 20, marginTop: 75}}>
        <AvForm onValidSubmit={this.submitScheduleSelectAnswer} id="schedule-appointment-time">
          <div style={{width: "100%", marginTop: 30, marginBottom: 30, fontSize: 28, flexDirection: "row", fontFamily: "Montserrat SemiBold"}}>
            Schedule Appointment Time?            
          </div>                    
          <FormGroup row
            style={{marginLeft: 0, marginRight: 0}}
          >
            <AvRadioGroup
              name="scheduledAppointmentTime"
              type="select"
              required
            >
              <AvRadio label="Yes" value="yes"/>
              {
                !this.leadOnlyIneligibleBrands.includes(conceptCode) &&
                <AvRadio label="No" value="no"/>
              }              
            </AvRadioGroup>
          </FormGroup>                
        </AvForm>
        <SquareButton
          className="boxShadow" 
          form={"schedule-appointment-time"}
          text="NEXT"
          buttonstyle={{height: 50, width: "100%", backgroundColor: "rgb(38, 125, 191)", marginTop: 10}}
          textstyle={{fontFamily: "Montserrat SemiBold", color: "white", fontSize: 16}}
        />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    selectedCompany,
    customServiceQuestions,
    serviceQuestionAnswers,
    companiesHashMap
  } = state.schedulingReducer
  const {eventStatuses} = state.systemReducer;
  return {
    ...state,
    selectedCompany,
    customServiceQuestions,
    serviceQuestionAnswers,
    companiesHashMap,
    eventStatuses
  };
}

export default connect(mapStateToProps, {resetData})(HasScheduleSelect);