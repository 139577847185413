import React, { Component } from "react";
import { getLoggedInUserInformation } from "../actions/index";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import PasswordModal from '../Components/passwordModal';

class PasswordExpiry extends Component {
    constructor(props) {
        super(props);
        this.state = {
          modal: false
        };
      }
      componentDidMount() {    
        if (this.props.userLoggedIn) {
          this.props.getLoggedInUserInformation();
        }
      }
  render() {
    let expiryObj = {
        email : this.props.loginReducer.email,
        expirationDay : null,
    }
    return (
        <div>
            {this.props.userLoggedIn || this.props.passwordExpiryDate == null ? <Redirect to='/landing'/> : <PasswordModal {...this.props} numOfExpirationDays={expiryObj}/>}
        </div>
    );
  }
}

function mapStateToProps(state) {
  const {userLoggedIn, passwordExpiryDate } = state.loginReducer;
  return {
    ...state,
    passwordExpiryDate,
    userLoggedIn
  };
}

export default connect(
  mapStateToProps,
  {
    getLoggedInUserInformation,
  }
)(PasswordExpiry);
