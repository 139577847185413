import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';
import rootReducer from "../src/reducers/root";
import thunk from 'redux-thunk';

const persistConfig = {
    key: 'root',
    storage
};

const configureStore = () => {
    const enhancers = [];
    let middleware=[]
    if (process.env.NODE_ENV === 'development') {
        middleware = [logger];
    }
    enhancers.push(applyMiddleware(...middleware,thunk));

    // Redux persist
    const persistedReducer = persistReducer(persistConfig, rootReducer);
    const store = createStore(persistedReducer, compose(...enhancers));
    const persistor = persistStore(store);
    return { store, persistor };
}
export default configureStore;