import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Input} from "reactstrap";
import {
  getCustomersBasedOnLocation,
  setCustomerInfo,
  clearSelectedCustomerInfo
} from "../../actions/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomerListPageRow from "./CustomerListPageRow";
import {EventStatuses} from "../../system";
import Loader from "react-loader-spinner";

class NearbyJobSearchTab extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.clearSelectedCustomerInfo();
    navigator.geolocation.getCurrentPosition(
      locationData => {
        let {latitude, longitude} = locationData.coords;
        this.setState({
          latitude,
          longitude
        });
        this.props.getCustomersBasedOnLocation(this.props.associatedFranchiseIds, latitude, longitude);
      },
      error => {        
      },
      { enableHighAccuracy: true, timeout: 20000, maximumAge: 0 }
    );
  }

  onCustomerSelect = customer => {
    this.props.setCustomerInfo(customer);
    this.props.history.push("/customerinfo");
  }

  render() {
    return (
      <div style={{width: "100%", flexDirection: "column", alignItems: "center"}}>
        <div style={{width: "100%", height: 40, maxWidth: 800,position: "relative", top: 25}}>
          <div style={{width: "100%", height: "100%", flexDirection: "row", justifyContent: "flex-start"}}>
            <Input
              style={{width: "100%",paddingLeft:40}}
              placeholder="Filter"
              onChange={
                e => {
                  this.setState({filterText: e.target.value});
                }
              }
            />
            <FontAwesomeIcon
              style={{position: "absolute", left: 10, top: 5, width: 18, height: 26,color:"#497cc2"}}
              icon="search"
            />
          </div>
        </div>
        {
          this.props.eventStatuses["getCustomersBasedOnLocation"] !== EventStatuses.PENDING ?
          <div style={{width: "100%", maxWidth: 800, display: "block", marginTop: 50}}> 
            {
              this.state &&
              this.props.customersByLocation &&
              this.props.customersByLocation.length > 0 ?
              <>
              <div className="cards">
              {this.props.customersByLocation
                .filter(c => !this.state.filterText || c.name.toLowerCase().includes(this.state.filterText.toLowerCase()))
                .map((c, k) => {
                  return (
                    <CustomerListPageRow
                      onSelect={this.onCustomerSelect}
                      key={k}
                      customer={c}
                    />
                  );
                })}
              </div>
              </>
               :
                <div style={{width: "100%", marginTop:100, alignItems: "center", justifyContent: "center", color: "lightgrey"}}>
                  There are no customers currently nearby
                </div>
            }
          </div> :
            <div style={{width: "100%", height: "100%", alignItems: "center", justifyContent: "center", marginTop: 165}}>
              <Loader
                type="Oval"
                color="#a5a9af"
                height="50"
                width="50"
              />
            </div>
          }

      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    customersByLocation,
    activePostalCode
  } = state.schedulingReducer;
  const {
    associatedFranchiseIds
  } = state.loginReducer;
  const {
    eventStatuses
  } = state.systemReducer;
  return {
    ...state,
    customersByLocation,
    activePostalCode,
    associatedFranchiseIds,
    eventStatuses
  };
}

export default connect(mapStateToProps, {
  getCustomersBasedOnLocation,
  setCustomerInfo,
  clearSelectedCustomerInfo
})(NearbyJobSearchTab);

