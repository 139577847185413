import * as types from "../actions/index";

let logoutState = {
    loggedoutState : true
};
const logoutReducer = (state = logoutState, action) => {
    switch (action.type) {
        case types.USER_LOGOUT:
          return {
              state : {},
              loggedoutState : false,
          };
          default:
            return state;
    }
}

export default logoutReducer;