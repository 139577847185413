import React, {Component} from "react";
import {connect} from "react-redux";
import {Button,Col, Row} from "reactstrap";
import {
  setCustomerInfo,
  clearSelectedCustomerInfo,
  getCustomersBySearchCriteria
} from "../../actions/index";
import CustomerListPageRow from "./CustomerListPageRow";
import {AvField, AvForm,AvGroup} from "availity-reactstrap-validation";
import {EventStatuses} from "../../system";
import Loader from "react-loader-spinner";

class CustomerListTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFieldsVisible: true
    };
    this.handleValidSubmission  = this.handleValidSubmission.bind(this);
  }

  componentDidMount() {
    this.props.clearSelectedCustomerInfo();
  }

  onCustomerSelect = customer => {
    this.props.setCustomerInfo(customer);
    this.props.history.push("/customerinfo");
  }

  onCreateNewCustomer = ()=> {
    this.props.history.push("/customerinfo");
  }

  toggleSearchButton = () => {
    this.setState({
      searchFieldsVisible: !this.state.searchFieldsVisible
    });
  }

  handleValidSubmission = (event, values) => {
    let criteriaSearch = {
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      email: values.email,
      address: values.addressLine1,
      city: values.city,
      state: values.state,
      zip: values.zipCode
    };
    this.props.getCustomersBySearchCriteria(criteriaSearch);
    this.form && this.form.reset();
    this.toggleSearchButton();
  }

  render() {
    return (
      <div style={{width: "100%", flexDirection: "column", alignItems: "center"}}>
        {
          this.state.searchFieldsVisible &&
          <div style={{width: "100%", height: 40, maxWidth: 800, position: "fixed", top: 125,zIndex:1,flexDirection:"column"}}>
          <div style={{width: "100%", flexDirection: "column", justifyContent: "flex-start"}}>        
            <div>
            <p>
              To enable the search button, one of the following conditions must be met:
            </p>
            </div>
            <div>
            <ul>
              <li>
              Email or Phone is provided.
              </li>
              <li>
              First and/or last name, as well as any part of the address. (Example: First Name and Postal Code)
              </li>
            </ul>
            </div>
          </div>
          
          <div style={{ display: "block", width: "82%", top: 8, position: "relative", backgroundColor: "white"}}>
            <AvForm onValidSubmit={this.handleValidSubmission } ref={c => (this.form = c)} id="customer-search-form">
              <Row>
              <Col xs="12" sm="6">
                  <AvGroup style={{ width: "100%" }}>
                    <AvField 
                      // label="First Name"
                      name="firstName"
                      type="text"
                      placeholder="First Name"
                      value={this.state.firstName}
                      onChange={e => {
                        this.setState({firstName: e.target.value})
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col xs="12" sm="6">
                  <AvGroup style={{ width: "100%" }}>
                  <AvField
                    // label="Last Name"
                    name="lastName"
                    type="text"
                    placeholder="Last Name"
                    value={this.state.lastName}
                    onChange={e => {
                      this.setState({lastName: e.target.value})
                    }}
                  />
                  </AvGroup>
                </Col>
              </Row>
              <Row>
              <Col xs="12" sm="6">
                  <AvGroup style={{ width: "100%" }}>
                  <AvField
                    // label="Phone"
                    name="phone"
                    type="text"
                    placeholder="Phone"
                    value={this.state.phone}
                    onChange={e => {
                      this.setState({phone: e.target.value})
                    }}
                  />
                  </AvGroup>
                </Col>
                <Col xs="12" sm="6">
                  <AvGroup style={{ width: "100%" }}>
                  <AvField
                    // label="Email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={e => {
                      this.setState({email: e.target.value})
                    }}
                  />
                  </AvGroup>
                </Col>
              </Row>
              <Row>
              <Col xs="12" sm="6">
                  <AvGroup style={{ width: "100%" }}>
                  <AvField
                    // label="Address Line 1"
                    name="addressLine1"
                    type="text"
                    placeholder="Address Line 1"
                    value={this.state.street}
                    onChange={e => {
                      this.setState({street: e.target.value})
                    }}
                  />
                  </AvGroup>
                </Col>
                <Col xs="12" sm="6">
                  <AvGroup style={{ width: "100%" }}>
                  <AvField
                    // label="City"
                    name="city"
                    type="text"
                    placeholder="City"
                    value={this.state.city}
                    onChange={e => {
                      this.setState({city: e.target.value})
                    }}
                  />
                  </AvGroup>
                </Col>
              </Row>
              <Row>
              <Col xs="12" sm="6">
                  <AvGroup style={{ width: "100%" }}>
                  <AvField
                    // label="State"
                    name="state"
                    type="text"
                    placeholder="State"
                    value={this.state.state}
                    onChange={e => {
                      this.setState({state: e.target.value})
                    }}
                  />
                  </AvGroup>
                </Col>
                <Col xs="12" sm="6">
                  <AvGroup style={{ width: "100%" }}>
                  <AvField
                    // label="ZIP Code"
                    name="zipCode"
                    type="text"
                    placeholder="ZIP Code"
                    value={this.state.zip}
                    onChange={e => {
                      this.setState({zip: e.target.value})
                    }}
                  />
                  </AvGroup>
                </Col>
              </Row>
              <Row style={{justifyContent:'center',alignItems:'center'}}>
              <Button form="customer-search-form" type="submit" style={{backgroundColor:"#267dbf"}}>Search For Master Customers</Button>
              </Row>
            </AvForm>
          </div>
        
        </div>
          }
        {
              (!this.state.searchFieldsVisible &&
              this.props.eventStatuses["getCustomersBySearchCriteria"] !== EventStatuses.PENDING) ?
              <div style={{width: "100%", maxWidth: 800, display: "block", marginTop: 15}}> 
              <div style={{width:'100%',justifyContent:'space-between'}}>
              <p style={{fontSize:25,fontWeight:600,color:'#267dbf'}}>Customer Search Result</p>
              <h4
              onClick={this.toggleSearchButton}
              style={{color: 'darkgray',fontSize:15,cursor:'pointer',alignItems:'center',display:'inline-flex'}}
              >
              Back to search
              </h4>
              </div>  
              {this.state &&
              this.props.customersBySearchCriteria &&
              this.props.customersBySearchCriteria.length > 0 ?
              <>            
              <div className="cards">                         
              {this.props.customersBySearchCriteria
                .filter(c => !this.state.filterText || c.name.toLowerCase().includes(this.state.filterText.toLowerCase()))
                .map((c, k) => {
                  return (
                    <>
                      <CustomerListPageRow
                      onSelect={this.onCustomerSelect}
                      key={k}
                      customer={c}
                    />
                    </>
                  );
                })}
                </div>
                </> : 
                <>                  
                  <div style={{width: "100%",marginTop:100, alignItems: "center", justifyContent: "center", color: "lightgrey"}}>
                  There are no customers matching these search terms
                </div>
                </>
            }
          </div> :
          <div style={{width: "100%", height: "100%", alignItems: "center", justifyContent: "center", marginTop: 165}}>
            <Loader
              type="Oval"
              color="#a5a9af"
              height="50"
              width="50"
            />
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    customersBySearchCriteria,
    activePostalCode
  } = state.schedulingReducer;
  const {eventStatuses} = state.systemReducer;
  return {
    ...state,
    customersBySearchCriteria,
    activePostalCode,
    eventStatuses
  };
}

export default connect(mapStateToProps, {
  getCustomersBySearchCriteria,
  setCustomerInfo,
  clearSelectedCustomerInfo
})(CustomerListTab);

